import { React, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import api from "../../api/axiosConfig";
import { ProjectContext } from "../../App";
import ProjectPieChart from "../Dashboard/ProjectPieChart.js";
import TimesheetPieChart from "../Dashboard/TimesheetPieChart.js";
import TimesheetCalendar from "../Dashboard/TimesheetCalendar.js";
import ProjectPhasePieChart from "../Dashboard/ProjectPhasePieChart.js";
import TimesheetDurationBillableNonBillable from "../Dashboard/TimesheetDurationBillableNonBillable.js";
import "../../styles/projects/Dashboard.css";

const fetchData = async (url, setData, setError) => {
  try {
    // Use api.get, which includes the base URL and interceptors
    const response = await api.get(url);
    setData(response.data); // Set the fetched data from response
  } catch (error) {
    setError(error); // Handle error
  }
};

const DashBoard = () => {
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const { projectId } = useParams();
  const [project, setProject] = useState({
    id: null,
    name: "",
    projectno: "",
    customer_name: "",
    phases: [],
  });
  const [claimPercent, setClaimPercent] = useState(0);
  const [totalPlannedHours, setTotalPlannedHours] = useState("");
  const [bookedHours, setBookedHours] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timesheetData, setTimesheetData] = useState([]);
  const [claimedPhases, setClaimedPhases] = useState(0);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchData(
        `/api/project/${projectId}/phases`,
        (data) => {
          setProject(data);
          if (data.phases) {
            // Calculate total planned hours
            const totalPlannedHours = data.phases.reduce((total, phase) => {
              return total + (phase.plannedhours || 0);
            }, 0);

            // Calculate total claimed phases
            const totalClaimedPhases = data.phases.reduce((claimed, phase) => {
              return phase.invoice_status === "Claimed" ? claimed + 1 : claimed;
            }, 0);

            const percentage = (totalClaimedPhases / data.phases.length) * 100;

            // Set states
            setTotalPlannedHours(totalPlannedHours);
            setClaimPercent(percentage);
            setClaimedPhases(totalClaimedPhases); // Set claimed phases
          }
        },
        setError
      ),
      fetchData(
        `/api/timesheettable/projects/${projectId}`,
        (data) => {
          setTimesheetData(data);
        },
        setError
      ),
      fetchData(
        `/api/projects/${projectId}/users`,
        (data) => {
          setUsers(data);
        },
        setError
      ),
    ]).finally(() => setLoading(false));
  }, [projectId]);

  useEffect(() => {
    if (Array.isArray(timesheetData)) {
      const bookedHours = timesheetData.reduce((total, entry) => {
        const [hours, minutes] = entry.duration.split(":").map(Number);
        return total + hours + minutes / 60; // Only hours and minutes
      }, 0);
      setBookedHours(bookedHours);
    }
  }, [timesheetData]);

  return (
    <>
      <Helmet>
        <title>Dashboard - MSES Timesheet</title>
      </Helmet>
      {!dialBoxopen && (
        <div
          className={
            isNavBarOpen
              ? "dashboard-main-container"
              : "dashboard-main-container-close"
          }
        >
          <div
            className={
              isNavBarOpen ? "dashboard-container" : "dashboard-container-close"
            }
          >
            <div className="container-one">
              <ProjectPieChart
                projectName={project.name}
                phases={project.phases}
                plannedHours={totalPlannedHours}
                bookedHours={bookedHours}
              />
            </div>
            <div className="container-two">
              <TimesheetPieChart
                projectName={project.name}
                plannedHours={totalPlannedHours}
                timesheetData={timesheetData}
              />
            </div>
          </div>
          <div
            className={
              isNavBarOpen ? "dashboard-container" : "dashboard-container-close"
            }
          >
            <div className="container-one">
              <ProjectPhasePieChart
                totalPhases={project.phases.length}
                claimedPhases={claimedPhases}
                projectName={project.name}
              />
            </div>
            <div className="container-two">
              <TimesheetDurationBillableNonBillable
                projectName={project.name}
                timesheetData={timesheetData}
              />
            </div>
          </div>
          <div
            className={
              isNavBarOpen ? "dashboard-container" : "dashboard-container-close"
            }
          >
            <div className="container-one">
              <TimesheetCalendar projectId={projectId} />
            </div>
            <div className="container-two">
              <h3 style={{ textWrap: "nowrap" }}>Project members : </h3>
              {users.length === 0 && (
                <div className="duration-image-container">
                  <img
                    className="members-image"
                    style={{ top: "50px" }}
                    src="/AppLayout/Project-member.png"
                  />
                  <p style={{ marginLeft: "-87px" }}>
                    Project members not available for this project
                  </p>
                </div>
              )}
              <ul className="ul-dashboard">
                {users.map((user) => (
                  <li key={user.name} className="user-details-list">
                    <p style={{ display: "flex" }} className="user-list">
                      <span className="initial">
                        {user.name.charAt(user.name.length - 1).toUpperCase() +
                          user.name.charAt(0).toUpperCase()}
                      </span>
                      <span>{user.name}</span>
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashBoard;
