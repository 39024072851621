// ProjectPhasePieChart.js
import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const ProjectPhasePieChart = ({ totalPhases, claimedPhases, projectName }) => {
  const data = useMemo(() => {
    return {
      labels: ["Claimed Phases", "Unclaimed Phases"],
      datasets: [
        {
          label: "Phases",
          data: [claimedPhases, totalPhases - claimedPhases],
          backgroundColor: ["#4caf50", "#ff9800"], // Customize colors
        },
      ],
    };
  }, [totalPhases, claimedPhases]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        enabled: true,
      },
    },
    hover: {
      mode: "nearest",
      animationDuration: 200,
      shadowBlur: 10, // Adds a shadow effect for a lifted appearance
      shadowColor: "rgba(0, 0, 0, 0.3)", // Dark shadow color for a soft glow effect
    },
    animation: {
      duration: 500,
      easing: "easeOutCirc", // Circular easing for a smooth deceleration
      stagger: 500, // Staggers elements to animate one after another
    },
  };
  return (
    <div>
      <h3>Phase Summary - {projectName} </h3>
      <p>Phases Count : {totalPhases}</p>
      {totalPhases === 0 && (
        <div className="duration-image-container">
          <img className="duration-image" src="/AppLayout/Phase.png" />
          <p>Phase not available for this project</p>
        </div>
      )}
      <Pie data={data} options={options} className="piechart" />
    </div>
  );
};

export default ProjectPhasePieChart;
