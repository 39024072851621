// src/api/axiosConfig.js
import axios from "axios";

const api = axios.create({
  baseURL: "https://195.35.6.183:3000",
});

// Request interceptor to add the token to headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
export default api;
