import { useCallback, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../Authentication/AuthContext";
import { GoEye } from "react-icons/go";
import { GoEyeClosed } from "react-icons/go";
import api from "../../api/axiosConfig";
import "./Profile.css";

const Profile = ({ className = "", onClose }) => {
  const navigate = useNavigate();
  const profileRef = useRef(null);
  const [changePasswordDial, setChangePasswordDial] = useState(false);
  const { logout, userDetails } = useAuth();
  const userID = userDetails.id;
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [lastLogoutTime, setLastLogoutTime] = useState(() => {
    return (
      localStorage.getItem(`lastLogoutTime_${userDetails.id}`) ||
      "This is your first login"
    );
  });
  const formatDate = (date) => {
    const d = new Date(date);
    if (isNaN(d.getTime())) {
      return "This is your first login"; // Return the default message for first-time login
    }
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = d.getFullYear();
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  //update password
  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setMessage("New passwords do not match.");
      return;
    }
    const specialChars = /[!@#$%&*]/;
    const hasUppercase = /[A-Z]/;
    const hasNumber = /[0-9]/;
    if (
      !newPassword ||
      newPassword.length < 6 ||
      newPassword.length > 15 ||
      !hasUppercase.test(newPassword) ||
      !hasNumber.test(newPassword) ||
      !specialChars.test(newPassword)
    ) {
      setMessage(
        "(Password must be 6-15 characters long, with at least one uppercase letter, one number, and one special symbol (!,@,#,$,%,&,*))"
      );
      return;
    }
    try {
      const response = await api.put(`/api/users/${userID}/password`, {
        oldPassword,
        newPassword,
      });

      alert(
        "Your password has been updated successfully. You can now log in with your new credentials."
      );
      setChangePasswordDial((prev) => !prev);
      logout(); //logouts after password updated
      if (!response.ok) {
        throw new Error(response.data.message || "Error updating password");
      }
    } catch (error) {
      setMessage(error.message);
    }
  };

  const onCloseRingLightIconClick = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const onClickLogout = useCallback(() => {
    if (userDetails && userDetails.id) {
      const logoutTime = new Date().toISOString();
      localStorage.setItem(`lastLogoutTime_${userDetails.id}`, logoutTime);
      localStorage.removeItem("activeLink");
      localStorage.removeItem("activecustomer");
      localStorage.removeItem("activeproject");
      localStorage.removeItem("activegroups");
      logout();
      navigate("/");
      if (onClose) {
        onClose();
      }
    } else {
      console.error("User details are not available");
    }
  }, [logout, navigate, onClose, userDetails]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (userDetails && userDetails.id) {
        const lastAccessedTime = new Date().toISOString();
        localStorage.setItem(
          `lastAccessedTime_${userDetails.id}`,
          lastAccessedTime
        );
        // Optionally, remove the authentication token
        localStorage.removeItem("authToken");
        localStorage.removeItem("activeLink");
        console.log(
          `Last accessed time for user ${userDetails.id}: `,
          lastAccessedTime
        );
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [userDetails]);

  // Function to get the avatar style based on the first letter of the username
  const getAvatarStyle = () => {
    if (userDetails && userDetails.name) {
      const color = "#6ca0dc";
      return {
        backgroundColor: color,
        color: "white",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "40px",
        height: "40px",
        fontSize: "17px",
        textAlign: "center",
        marginTop: "0px",
        fontWeight: "800",
      };
    }
    return {};
  };
  const handleCopy = (e) => {
    e.preventDefault();
    alert("Copying passwords is not allowed!");
  };
  const handlePaste = (e) => {
    e.preventDefault();
    alert("Pasting passwords is not allowed!");
  };
  const firstLetter = userDetails
    ? userDetails.name.charAt(userDetails.name.length - 1).toUpperCase() +
      userDetails.name.charAt(0).toUpperCase()
    : "?";

  return (
    <>
      <div className={`profile ${className}`} ref={profileRef}>
        <div className="avatar-label-group">
          <a className="avatar-icon2" style={getAvatarStyle()} alt="profile">
            {firstLetter}
          </a>
          <div className="text-and-supporting-text1">
            {userDetails ? (
              <>
                <div className="text24">
                  {userDetails.name}({userDetails.designation})
                </div>
                <div className="supporting-text1">{userDetails.email}</div>
                <p></p>
              </>
            ) : (
              <div className="text24">Loading...</div>
            )}
          </div>
        </div>
        <p className="user-designation">
          Last accessed on: {formatDate(lastLogoutTime)}
        </p>
        <div className="sign-out-squre-light-parent">
          <img
            className="sign-out-squre-light-icon"
            alt=""
            src="/sign-out-squre-light.svg"
            onClick={onClickLogout}
          />
          <div className="sign-out" onClick={onClickLogout}>
            Sign out
          </div>
        </div>
        <div className="mobile-parent">
          <p
            className="change-password"
            onClick={() => setChangePasswordDial((prev) => !prev)}
          >
            Change Password
          </p>
        </div>
        <img
          className="close-ring-light-icon"
          alt=""
          src="/close-ring-light.svg"
          onClick={onCloseRingLightIconClick}
        />
      </div>
      {changePasswordDial && (
        <div className="change-password-dial">
          <div className="change-password-header">
            <h3 className="password-header">Change Password</h3>
          </div>
          <form onSubmit={handleUpdatePassword} className="form-input">
            <h3 className="password-element">Enter Old Password</h3>
            <input
              type={showOldPassword ? "text" : "password"}
              className="password-element-input"
              placeholder="Enter Old Password"
              style={{ height: "25px" }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              onCopy={handleCopy} // Disable copy
              onPaste={handlePaste} // Disable paste
              required
            />
            {showOldPassword ? (
              <GoEyeClosed
                className="password-icon"
                onClick={handleClickShowOldPassword}
              />
            ) : (
              <GoEye
                className="password-icon"
                onClick={handleClickShowOldPassword}
              />
            )}
            <h3 className="password-element">Enter New Password</h3>
            <input
              type={showPassword ? "text" : "password"}
              className="password-element-input"
              placeholder="Enter New Password"
              style={{ height: "25px" }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onCopy={handleCopy} // Disable copy
              onPaste={handlePaste} // Disable paste
              required
            />
            {showPassword ? (
              <GoEyeClosed
                className="password-icon"
                onClick={handleClickShowPassword}
              />
            ) : (
              <GoEye
                className="password-icon"
                onClick={handleClickShowPassword}
              />
            )}
            <h3 className="password-element">Confirm New Password</h3>
            <input
              type={showPassword ? "text" : "password"}
              className="password-element-input"
              placeholder="Confirm New Password"
              style={{ height: "25px" }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onCopy={handleCopy} // Disable copy
              onPaste={handlePaste} // Disable paste
              required
            />
            {showPassword ? (
              <GoEyeClosed
                className="password-icon"
                onClick={handleClickShowPassword}
              />
            ) : (
              <GoEye
                className="password-icon"
                onClick={handleClickShowPassword}
              />
            )}
            {message && <p className="password-message">{message}</p>}
            <div className="password-btn-container">
              <button
                className="add-btn"
                style={{ height: "27px", marginLeft: "14px" }}
                type="submit"
              >
                Update
              </button>
              <button
                className="cancel-btn"
                style={{ height: "27px" }}
                onClick={() => {
                  setOldPassword("");
                  setNewPassword("");
                  setConfirmPassword("");
                  setMessage("");
                  setChangePasswordDial((prev) => !prev);
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default Profile;
