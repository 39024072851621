import React, { useEffect, useState, useContext } from "react";
import { useRolePermissions } from "../Settings/RoleContext";
import { useParams } from "react-router-dom";
import "../../styles/projects/PhaseDetails.css";
import api from "../../api/axiosConfig";
import { CircularProgress, Typography, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaChevronDown } from "react-icons/fa";
import { ProjectContext } from "../../App";
import { Helmet } from "react-helmet";

const fetchData = async (url, setData, setError) => {
  try {
    const response = await fetch(url);
    if (!response.ok)
      throw new Error(`Network response was not ok: ${response.statusText}`);
    const data = await response.json();
    setData(data);
  } catch (error) {
    if (setError) {
      setError(error.message);
    } else {
      console.error("Error fetching data:", error);
    }
  }
};

const addTaskToPhase = async (phaseId, taskTemplateId, setError) => {
  try {
    // Ensure phaseId and taskTemplateId are valid
    if (!phaseId || !taskTemplateId) {
      throw new Error("Invalid phaseId or taskTemplateId");
    }

    // Send a POST request to add the task template to the phase
    const response = await api.post(`/api/addPhaseTaskTemplate/${phaseId}`, {
      phase_id: phaseId,
      tasktemplate_id: taskTemplateId,
    });

    // Return the response data
    return response.data;
  } catch (error) {
    // Set error state or handle error as needed
    setError(error.message || "An error occurred while adding the task.");
    // Optionally, you might want to rethrow the error or handle it differently
    throw error;
  }
};

const addVariationToPhase = async (phaseId, variationData, setError) => {
  try {
    const response = await api.post(
      `/api/phase/${phaseId}/addVariation`,
      variationData
    );
    return response.data; // Axios automatically parses JSON
  } catch (error) {
    setError(error);
  }
};
const PhaseDetails = () => {
  const { phaseId } = useParams();
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const { hasPermission } = useRolePermissions();
  const [phase, setPhase] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [taskTemplateId, setTaskTemplateId] = useState("");
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [tasksByPhase, setTasksByPhase] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false); // To track if it's add or edit mode
  const [openVariationDialog, setOpenVariationDialog] = useState(false);
  const [variationNo, setVariationNo] = useState("");
  const [variationName, setVariationName] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [variationHours, setVariationHours] = useState(0);
  const [variations, setVariations] = useState([]); // Initialize as an empty array
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] = useState(false);
  const [variationToDelete, setVariationToDelete] = useState([]);
  const [taskToDelete, setTaskToDelete] = useState(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  //issues props
  const [issues, setIssues] = useState([]); // To store the list of issues
  const [openIssueDialog, setOpenIssueDialog] = useState(false); // To control the dialog for adding/editing issues
  const [issueNo, setIssueNo] = useState(""); // To track the issue number
  const [issueName, setIssueName] = useState(""); // To track the issue name
  const [issueHours, setIssueHours] = useState(0); // To track the planned hours for the issue
  const [selectedIssue, setSelectedIssue] = useState(null); // To track the issue being edited
  const [isIssueEditMode, setIsIssueEditMode] = useState(false); // To differentiate between add and edit mode for issues
  const [issueToDelete, setIssueToDelete] = useState([]); // To track the issue to be deleted
  const [deleteIssueDialogOpen, setDeleteIssueDialogOpen] = useState(false); // To control the delete confirmation dialog
  const [isIssueCheckboxChecked, setIsIssueCheckboxChecked] = useState(false); // To track the checkbox state in the delete dialog
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  // fetch all details based on variation issue
  useEffect(() => {
    const fetchData = async (endpoint, setter) => {
      try {
        const response = await api.get(endpoint);
        setter(response.data);
      } catch (error) {
        setError(error);
      }
    };

    const fetchAllData = async () => {
      try {
        // Fetch all data concurrently
        await Promise.all([
          fetchData(`/api/phase/${phaseId}`, setPhase),
          fetchData(`/api/phase/${phaseId}/taskTemplates`, setTasksByPhase),
          fetchData(`/api/taskTemplates`, setTaskTemplates),
          fetchData(`/api/phase/${phaseId}/variations`, (data) =>
            setVariations(Array.isArray(data) ? data : [])
          ),
          fetchData(`/api/phase/${phaseId}/issues`, (data) =>
            setIssues(Array.isArray(data) ? data : [])
          ),
        ]);
      } finally {
        setLoading(false); // Ensure loading is set to false once all requests are done
      }
    };

    fetchAllData();
  }, [phaseId]);

  //handleAddTask
  const handleAddTask = async () => {
    if (!taskTemplateId) {
      alert("Please select a task to add.");
      return;
    }

    try {
      const newTask = await addTaskToPhase(
        phaseId,
        Number(taskTemplateId),
        setError
      );
      if (newTask) {
        // Update tasks for the phase
        setTasksByPhase((prevTasks) => {
          const updatedTasks = [...prevTasks, newTask];
          return updatedTasks;
        });

        // Instead of removing the taskTemplateId, mark it as "used"
        setTaskTemplates((prevTemplates) =>
          prevTemplates.map((template) =>
            template.id === Number(taskTemplateId)
              ? { ...template, used: true } // Mark template as used instead of removing it
              : template
          )
        );

        // Clear the task template selection
        setTaskTemplateId("");
        // Clear any existing errors
        setError(null);
      }
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };

  const handleDeleteClick = (taskTemplateId) => {
    setTaskToDelete(Number(taskTemplateId));
    setDeleteTaskDialogOpen(true);
  };

  const handleDeleteTaskTemplate = async () => {
    const taskTemplateId = Number(taskToDelete); // Get the task ID to delete from state

    if (taskTemplateId) {
      try {
        const taskTemplateToAddBack = taskTemplates.find(
          (template) => template.id === taskTemplateId
        );

        if (!taskTemplateToAddBack) {
          console.error("Task template not found in state before deletion.");
          return;
        }
        // Perform the deletion via API
        const response = await api.delete(
          `/api/phase/${phaseId}/taskTemplates/${taskTemplateId}`
        );
        // Remove the task from tasksByPhase
        setTasksByPhase((prevTasks) =>
          prevTasks.filter((task) => task.id !== taskTemplateId)
        );

        // Re-add the task template back and set `used` to false
        setTaskTemplates((prevTemplates) =>
          prevTemplates.map((template) =>
            template.id === taskTemplateId
              ? { ...template, used: false }
              : template
          )
        );

        // Close the delete dialog and reset any other states
        setDeleteTaskDialogOpen(false);
        setIsCheckboxChecked(false);
      } catch (error) {
        if (error.response) {
          console.error("Delete failed:", error.response.data);
        } else {
          console.error("Error:", error.message);
        }
      }
    } else {
      console.error("No task template ID set for deletion.");
    }
  };

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  const handleOpenVariationDialog = () => {
    const suggestedVariationNo = `Vo-${String(
      (variations?.length || 0) + 1
    ).padStart(2, "0")}`;
    setVariationNo(suggestedVariationNo);
    setVariationName(`${phase.phaseno}-${suggestedVariationNo}`);
    setSelectedTaskId("");
    setVariationHours(0);
    setIsEditMode(false);
    setOpenVariationDialog(true);
  };
  const handleOpenEditVariationDialog = (variation) => {
    setIsEditMode(true);
    setSelectedVariation(variation);
    setVariationNo(variation.variationno);
    setVariationName(variation.name);
    setSelectedTaskId(variation.taskId);
    setVariationHours(variation.plannedhours);
    setOpenVariationDialog(true);
  };
  const handleAddVariation = async () => {
    const newVariation = {
      variationNo,
      variationName,
      taskId: selectedTaskId,
      variationHours,
      projectId: phase.project_id, // Assuming phase has a project_id field
    };
    const addedVariation = await addVariationToPhase(
      phaseId,
      newVariation,
      setError
    );
    if (addedVariation) {
      setVariations((prev) => [...prev, addedVariation]);
      setOpenVariationDialog(false);
      setSelectedTaskId(""); // Reset selected task
      setVariationHours(0); // Reset variation hours
    }
  };

  const handleDeleteVariation = (e, variationId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      // Add phaseId to the selectedPhaseId array
      setVariationToDelete((prev) => [...prev, variationId]);
    } else {
      // Remove phaseId from the selectedPhaseId array
      setVariationToDelete((prev) => prev.filter((id) => id !== variationId));
    }
  };

  //delete variation
  const handleDeleteConfirm = async () => {
    if (variationToDelete.length > 0) {
      try {
        // Send the array of variation IDs to the backend
        await api.delete(`/api/phase/${phaseId}/variations`, {
          data: { variationIds: variationToDelete },
        });

        // Update the state to remove the deleted variations
        setVariations((prevVariations) =>
          prevVariations.filter(
            (variation) => !variationToDelete.includes(variation.id)
          )
        );
      } catch (error) {
        console.error("Error deleting variations:", error);
      } finally {
        setDeleteDialogOpen(false);
        setIsCheckboxChecked(false);
        setVariationToDelete([]); // Reset the variations to delete
      }
    } else {
      alert("Please select at least one variation to delete.");
    }
  };

  // Handle save or add variation
  const handleSaveOrAddVariation = () => {
    if (isEditMode) {
      handleSaveVariation();
    } else {
      handleAddVariation();
    }
  };

  const handleSaveVariation = async () => {
    try {
      const updatedVariation = {
        ...selectedVariation,
        name: variationName,
        plannedhours: variationHours,
        task_id: selectedTaskId,
      };
      const response = await api.put(
        `/api/phase/${phaseId}/variation/${selectedVariation.id}`,
        updatedVariation
      );
      if (response.status === 200) {
        // Axios uses status codes for response validation
        setVariations(
          variations.map((v) =>
            v.id === selectedVariation.id ? updatedVariation : v
          )
        );
        setOpenVariationDialog(false);
      } else {
        console.error("Error updating variation:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving variation:", error);
    }
  };

  //issues

  const addIssueToPhase = async (phaseId, issueData, setError) => {
    try {
      const response = await api.post(
        `/api/phase/${phaseId}/addIssue`,
        issueData
      );
      // No need to manually parse JSON; Axios does that automatically
      return response.data;
    } catch (error) {
      setError(error);
    }
  };

  const handleAddIssue = async () => {
    const newIssue = {
      issueNo,
      issueName,
      taskId: selectedTaskId,
      issueHours,
      projectId: phase.project_id, // Assuming phase has a project_id field
    };
    const addedIssue = await addIssueToPhase(phaseId, newIssue, setError);
    if (addedIssue) {
      setIssues((prev) => [...prev, addedIssue]);
      setOpenIssueDialog(false);
      setSelectedTaskId(""); // Reset selected task
      setIssueHours(0); // Reset issue hours
    }
  };

  const handleOpenIssueDialog = () => {
    const suggestedIssueNo = `Is-${String((issues?.length || 0) + 1).padStart(
      2,
      "0"
    )}`;
    setIssueNo(suggestedIssueNo);
    setIssueName(`${phase.phaseno}-${suggestedIssueNo}`);
    setSelectedTaskId("");
    setIssueHours(0);
    setIsIssueEditMode(false);
    setOpenIssueDialog(true);
  };

  const handleOpenEditIssueDialog = (issue) => {
    setIsIssueEditMode(true);
    setSelectedIssue(issue);
    setIssueNo(issue.issueno);
    setIssueName(issue.name);
    setSelectedTaskId(issue.taskId);
    setIssueHours(issue.issuehours);
    setOpenIssueDialog(true);
  };

  const handleSaveIssue = async () => {
    try {
      const updatedIssue = {
        ...selectedIssue,
        name: issueName,
        issuehours: issueHours,
        task_id: selectedTaskId,
      };

      const response = await api.put(
        `/api/phase/${phaseId}/issue/${selectedIssue.id}`,
        updatedIssue
      );

      if (response.status === 200) {
        // Axios does not throw on non-2xx responses, check status manually
        setIssues(
          issues.map((issue) =>
            issue.id === selectedIssue.id ? updatedIssue : issue
          )
        );

        setOpenIssueDialog(false);
      } else {
        console.error("Error updating issue:", response.statusText);
      }
    } catch (error) {
      console.error("Error saving issue:", error);
    }
  };

  const handleDeleteIssue = (e, issueId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add issueId to the state array
      setIssueToDelete((prev) => [...prev, issueId]);
    } else {
      // Remove issueId from the state array if unchecked
      setIssueToDelete((prev) => prev.filter((id) => id !== issueId));
    }
  };

  // Delete issue
  const handleDeleteIssueConfirm = async () => {
    if (issueToDelete.length > 0) {
      try {
        // Send the array of issue IDs to the backend for deletion
        await api.delete(`/api/phase/${phaseId}/issues`, {
          data: { issueIds: issueToDelete },
        });

        // Update the state to remove the deleted issues
        setIssues((prevIssues) =>
          prevIssues.filter((issue) => !issueToDelete.includes(issue.id))
        );
      } catch (error) {
        console.error("Error deleting issues:", error);
      } finally {
        setDeleteIssueDialogOpen(false);
        setIsIssueCheckboxChecked(false); // Reset the checkbox state
        setIssueToDelete([]); // Clear the selected issues after deletion
      }
    } else {
      alert("Please select at least one issue to delete.");
    }
  };

  if (loading) return <CircularProgress />;
  if (error)
    return (
      <p color="error" className="error-message">
        Error: {error.message}
      </p>
    );
  if (!phase) return <Typography>No phase found</Typography>;

  const totalVariationHours = variations.reduce(
    (total, variation) => total + (variation.plannedhours || 0),
    0
  );
  const totalIssueHours = issues.reduce(
    (total, issue) => total + (issue.issuehours || 0),
    0
  );

  return (
    <>
      <Helmet>
        <title>Phases - MSES Timesheet</title>
      </Helmet>
      {!dialBoxopen && (
        <div className={isNavBarOpen ? "phase-details" : "phase-details-close"}>
          <div
            style={{
              marginTop: isNavBarOpen ? "0px" : "126px",
              marginLeft: isNavBarOpen ? "45px" : "0px",
              backgroundColor: "transparent",
            }}
          >
            <div style={{ display: "flex" }}>
              <h3 className="phase-title">Phase Title : {phase.title}</h3>
              <h3 className="phase-title">Phase No : {phase.phaseno}</h3>
              <h3 className="phase-title">
                Planned Hours : {phase.plannedhours}
              </h3>
            </div>
            <div className="tabs">
              <button
                onClick={() => handleTabChange(0)}
                className={`tab-button ${tabIndex === 0 ? "active" : ""}`}
              >
                General
              </button>
              <button
                onClick={() => handleTabChange(1)}
                className={`tab-button ${tabIndex === 1 ? "active" : ""}`}
              >
                Variations
              </button>
              <button
                onClick={() => handleTabChange(2)}
                className={`tab-button ${tabIndex === 2 ? "active" : ""}`}
              >
                Issues
              </button>
            </div>
            {tabIndex === 0 && (
              <div>
                {hasPermission("projects", "update") && (
                  <>
                    {taskTemplates.some(
                      (template) =>
                        !tasksByPhase.some((task) => task.id === template.id)
                    ) ? (
                      <>
                        <select
                          label="Task Template"
                          value={taskTemplateId}
                          style={{ backgroundColor: "white", padding: "10px" }}
                          onChange={(e) => setTaskTemplateId(e.target.value)}
                          displayEmpty
                          fullWidth
                        >
                          <option value="" disabled>
                            Select a task template
                          </option>
                          {taskTemplates
                            .filter(
                              (template) =>
                                !tasksByPhase.some(
                                  (task) => task.id === template.id
                                )
                            ) // Filtering unassociated templates
                            .map((template) => (
                              <option key={template.id} value={template.id}>
                                {template.name}
                              </option>
                            ))}
                        </select>
                        <button
                          onClick={handleAddTask}
                          variant="contained"
                          color="primary"
                          fullWidth
                          style={{
                            marginTop: "16px",
                            textWrap: "nowrap",
                            width: "auto",
                          }}
                          className="add-btn"
                          title="Add Task"
                        >
                          Add Task
                        </button>
                      </>
                    ) : (
                      <p>All tasks are selected</p>
                    )}
                  </>
                )}
                {/* Display associated tasks */}
                <h3 className="task-name">Associated Tasks:</h3>
                <div>
                  {tasksByPhase.length > 0 ? (
                    tasksByPhase.map((taskTemplate, index) => (
                      <div key={taskTemplate.id} className="accordion">
                        <div
                          className="accordion-summary"
                          style={{ display: "flex", fontWeight: "normal" }}
                          onClick={() => handleToggle(index)}
                        >
                          <h6 className="phase-title">{taskTemplate.name}</h6>
                          {hasPermission("projects", "delete") && (
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent toggle from firing
                                handleDeleteClick(taskTemplate.id);
                              }}
                              style={{
                                marginLeft: "auto",
                                marginRight: "10px",
                                border: "none",
                                cursor: "pointer",
                              }}
                              title="Delete Task"
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </button>
                          )}
                        </div>
                        {expandedIndex === index && (
                          <div className="accordion-details">
                            <table className="accordion-table">
                              <thead>
                                <tr>
                                  <th className="task-name">Task Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                {taskTemplate.tasks &&
                                  taskTemplate.tasks.map((task) => (
                                    <tr key={task.id}>
                                      <td>{task.name}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No tasks associated with this phase.</p>
                  )}
                </div>
              </div>
            )}
            {deleteTaskDialogOpen && (
              <div className="delete-var-issue-dial">
                <div className="delete-var-header">
                  <h3 className="delete-variation">Delete Task</h3>
                </div>
                <div style={{ backgroundColor: "white" }}>
                  <p style={{ backgroundColor: "white", marginLeft: "10px" }}>
                    Are you sure you want to delete this Task? This action
                    cannot be undone.
                  </p>
                  <input
                    type="checkbox"
                    checked={isCheckboxChecked}
                    id="tick"
                    onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                  />
                  <label
                    htmlFor="tick"
                    style={{ backgroundColor: "white", userSelect: "none" }}
                  >
                    I understand this action cannot be undone.
                  </label>
                </div>
                <div className="btn-var-del-con">
                  <button
                    onClick={handleDeleteTaskTemplate}
                    color="secondary"
                    variant="contained"
                    disabled={!isCheckboxChecked}
                    style={{
                      backgroundColor: isCheckboxChecked ? "red" : "grey",
                    }}
                    className="add-btn"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => {
                      setDeleteTaskDialogOpen(false);
                      setIsCheckboxChecked(false);
                    }}
                    className="cancel-btn"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {/*Variation tab*/}
            {tabIndex === 1 && (
              <Box>
                <p>Total Variation Hours: {totalVariationHours}</p>
                <p>Total Number of Variations: {variations.length}</p>
                {hasPermission("projects", "delete") && (
                  <>
                    {variationToDelete.length > 0 && (
                      <button
                        className="add-btn variation-btn delete-var-issue-btn"
                        onClick={() => setDeleteDialogOpen(true)}
                        title="Delete Variation"
                      >
                        Delete
                      </button>
                    )}
                    <button
                      onClick={handleOpenVariationDialog}
                      className="add-btn variation-btn"
                      title="New Variation"
                    >
                      New Variation
                    </button>
                  </>
                )}
                {variations.length === 0 && (
                  <div className="phase-image-container">
                    <img
                      className="phase-image"
                      src="/AppLayout/variation.png"
                    />
                    <p className="phase-message">
                      No variation available for this project
                    </p>
                  </div>
                )}

                {variations.length !== 0 && (
                  <div
                    className={
                      isNavBarOpen
                        ? "variation-table-container"
                        : "variation-table-container-close"
                    }
                  >
                    <table className="variation-table">
                      <thead>
                        <tr>
                          {hasPermission("projects", "delete") && (
                            <th style={{ width: "2px" }}>
                              <FaChevronDown />
                            </th>
                          )}

                          <th>Variation Number</th>
                          <th>Variation Name</th>
                          <th>Planned Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {variations.map((variation, index) => (
                          <tr key={index}>
                            {hasPermission("projects", "delete") && (
                              <td>
                                <input
                                  type="checkbox"
                                  name={variation.name}
                                  checked={variationToDelete.includes(
                                    variation.id
                                  )}
                                  onChange={(e) =>
                                    handleDeleteVariation(e, variation.id)
                                  }
                                />
                              </td>
                            )}
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ marginTop: "6px" }}>
                                  {variation.variationno}
                                </span>
                                {hasPermission("projects", "update") && (
                                  <button
                                    style={{
                                      width: "50px",
                                      border: "none",
                                      paddingRight: "50px",
                                      color: "#0000ff",
                                      backgroundColor: "transparent",
                                    }}
                                    onClick={() =>
                                      handleOpenEditVariationDialog(variation)
                                    }
                                    className="edit-icon-wrapper"
                                    title="Edit Variation"
                                  >
                                    <EditIcon />
                                  </button>
                                )}
                              </div>
                            </td>{" "}
                            <td>{variation.name}</td>
                            <td>{variation.plannedhours}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

                {/*add variation dial box*/}
                {openVariationDialog && (
                  <div className="add-variation-dial">
                    <div className="dial-v-header">
                      <h3 className="add-edit-variation">
                        {isEditMode ? "Edit Variation" : "Add Variation"}
                      </h3>
                    </div>
                    <div className="add-variation-content">
                      <h3 className="variation-label">Variation Number</h3>
                      <input
                        label="Variation No"
                        value={variationNo || ""}
                        fullWidth
                        onChange={(e) => setVariationNo(e.target.value)}
                        margin="dense"
                        disabled={isEditMode} // Disable editing of variation number
                        className="variation-number"
                      />
                      <h3 className="variation-label">Variation Name</h3>
                      <input
                        label="Variation Name"
                        value={variationName || ""}
                        fullWidth
                        onChange={(e) => setVariationName(e.target.value)}
                        margin="dense"
                        className="variation-name"
                      />
                      {/* <FormControl fullWidth margin="dense"> */}
                      <h3 className="variation-label">Variation Hours</h3>
                      <input
                        placeholder="Enter Variation Hours"
                        type="number"
                        value={variationHours || ""}
                        onChange={(e) =>
                          setVariationHours(Number(e.target.value))
                        }
                        fullWidth
                        margin="dense"
                        className="variation-hours"
                      />
                      <h3 className="variation-label">Invoice Status</h3>
                      <select className="variation-hours">
                        <option disabled>Select Invoice Status</option>
                        <option>Claimed</option>
                        <option>Unclaimed</option>
                      </select>
                    </div>

                    <div className="btn-var-con">
                      <button
                        onClick={handleSaveOrAddVariation}
                        style={{ marginLeft: "21px" }}
                        color="primary"
                        variant="contained"
                        className="add-edit-issue-btn"
                      >
                        {isEditMode ? "Save" : "Add"}
                      </button>
                      <button
                        onClick={() => setOpenVariationDialog(false)}
                        color="secondary"
                        className="cancel-btn"
                        style={{ marginRight: "140px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {/* Delete Confirmation Dialog */}
                {deleteDialogOpen && (
                  <div className="delete-var-issue-dial">
                    <div className="delete-var-header">
                      <h3 className="delete-variation">Delete Variation</h3>
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                      <p
                        style={{
                          backgroundColor: "white",
                          marginTop: "0px",
                          marginLeft: "10px",
                        }}
                      >
                        Are you sure you want to delete this variation? This
                        action cannot be undone.
                      </p>
                      <input
                        type="checkbox"
                        checked={isCheckboxChecked}
                        id="tick"
                        onChange={() =>
                          setIsCheckboxChecked(!isCheckboxChecked)
                        }
                      />
                      <label
                        htmlFor="tick"
                        style={{ backgroundColor: "white", userSelect: "none" }}
                      >
                        I understand this action cannot be undone.
                      </label>
                    </div>
                    <div className="btn-var-del-con">
                      <button
                        onClick={handleDeleteConfirm}
                        color="secondary"
                        variant="contained"
                        disabled={!isCheckboxChecked}
                        style={{
                          backgroundColor: isCheckboxChecked ? "red" : "grey",
                        }}
                        className="add-btn"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => {
                          setDeleteDialogOpen(false);
                          setIsCheckboxChecked(false);
                          setVariationToDelete([]);
                        }}
                        className="cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
                {/*delete task dial*/}
              </Box>
            )}
            {/*Issue tab*/}
            {tabIndex === 2 && (
              <Box>
                <p>Total Issue Hours: {totalIssueHours}</p>
                <p>Total Number of Issues: {issues.length}</p>
                {hasPermission("projects", "delete") && (
                  <>
                    {issueToDelete.length > 0 && (
                      <button
                        className="add-btn add-issue-btn delete-var-issue-btn"
                        onClick={() => setDeleteIssueDialogOpen(true)}
                        title="Delete Issue"
                      >
                        Delete
                      </button>
                    )}
                    <button
                      onClick={handleOpenIssueDialog}
                      style={{ textWrap: "nowrap", width: "auto" }}
                      className="add-btn add-issue-btn"
                      title="New Issue"
                    >
                      New Issue
                    </button>
                  </>
                )}
                <div
                  className={
                    isNavBarOpen
                      ? "variation-table-container"
                      : "variation-table-container-close"
                  }
                >
                  {issues.length === 0 && (
                    <div className="phase-image-container">
                      <img className="phase-image" src="/AppLayout/Issue.png" />
                      <p className="phase-message">
                        No issue available for this project
                      </p>
                    </div>
                  )}
                  {issues.length !== 0 && (
                    <table className="variation-table">
                      <thead>
                        <tr>
                          {hasPermission("projects", "delete") && (
                            <th style={{ width: "2px" }}>
                              <FaChevronDown />
                            </th>
                          )}

                          <th>Issue Number</th>
                          <th>Issue Name</th>
                          <th>Issue Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {issues.map((issue, index) => (
                          <tr key={index}>
                            {hasPermission("projects", "delete") && (
                              <td>
                                <input
                                  type="checkbox"
                                  name={issue.name}
                                  checked={issueToDelete.includes(issue.id)} // Make sure this works properly
                                  onChange={(e) =>
                                    handleDeleteIssue(e, issue.id)
                                  }
                                />
                              </td>
                            )}
                            <td>
                              {" "}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ marginTop: "6px" }}>
                                  {issue.issueno}
                                </span>
                                {hasPermission("projects", "update") && (
                                  <button
                                    style={{
                                      width: "50px",
                                      border: "none",
                                      paddingRight: "50px",
                                      color: "#0000ff",
                                      backgroundColor: "transparent",
                                    }}
                                    onClick={() =>
                                      handleOpenEditIssueDialog(issue)
                                    }
                                    className="edit-icon-wrapper"
                                    title="Edit Issue"
                                  >
                                    <EditIcon />
                                  </button>
                                )}{" "}
                              </div>
                            </td>{" "}
                            <td>{issue.name}</td>
                            <td>{issue.issuehours}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>

                {/* Add/Edit Issue Dialog */}
                {openIssueDialog && (
                  <div className="add-variation-dial">
                    <div className="dial-v-header">
                      <h3 className="add-edit-variation">
                        {isIssueEditMode ? "Edit Issue" : "Add Issue"}
                      </h3>
                    </div>
                    <div className="add-variation-content">
                      <h3 className="issue-label">Issue Number</h3>
                      <input
                        label="Issue No"
                        className="issue-number"
                        value={issueNo || ""}
                        fullWidth
                        onChange={(e) => setIssueNo(e.target.value)}
                        margin="dense"
                        disabled={isIssueEditMode} // Disable editing of issue number
                      />
                      <h3 className="issue-label">Issue Name</h3>
                      <input
                        label="Issue Name"
                        className="issue-name"
                        value={issueName || "Not Available"}
                        fullWidth
                        onChange={(e) => setIssueName(e.target.value)}
                        margin="dense"
                      />
                      <h3 className="issue-label">Issue Hours</h3>
                      <input
                        label="Issue Hours"
                        className="issue-hour"
                        placeholder="Enter Issue Hours"
                        type="number"
                        value={issueHours || ""}
                        onChange={(e) => {
                          const newValue = Number(e.target.value);
                          setIssueHours(newValue);
                        }}
                        fullWidth
                        margin="dense"
                      />
                      <h3 className="variation-label">Invoice Status</h3>
                      <select className="variation-hours" value={""}>
                        <option disabled>Select Invoice Status</option>
                        <option>Claimed</option>
                        <option>Unclaimed</option>
                      </select>
                    </div>

                    <div className="btn-issue-con">
                      <button
                        onClick={
                          isIssueEditMode ? handleSaveIssue : handleAddIssue
                        }
                        color="primary"
                        variant="contained"
                        className="add-edit-issue-btn"
                      >
                        {isIssueEditMode ? "Save" : "Add"}
                      </button>
                      <button
                        onClick={() => setOpenIssueDialog(false)}
                        color="secondary"
                        className="cancel-btn"
                        style={{ marginRight: "140px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {/* Delete Confirmation Dialog */}
                {deleteIssueDialogOpen && (
                  <div className="delete-issue-dial">
                    <div className="delete-var-header">
                      <h3 className="delete-variation">Delete Issue</h3>
                    </div>
                    <div style={{ backgroundColor: "white" }}>
                      <p
                        style={{
                          backgroundColor: "white",
                          marginTop: "0px",
                          marginLeft: "10px",
                        }}
                      >
                        Are you sure you want to delete this issue? This action
                        cannot be undone.
                      </p>
                      <input
                        style={{ backgroundColor: "white" }}
                        type="checkbox"
                        id="tick-issue"
                        checked={isIssueCheckboxChecked}
                        onChange={() =>
                          setIsIssueCheckboxChecked(!isIssueCheckboxChecked)
                        }
                      />
                      <label
                        htmlFor="tick-issue"
                        style={{ backgroundColor: "white", userSelect: "none" }}
                      >
                        I understand this action cannot be undone.
                      </label>
                    </div>
                    <div className="btn-issue-del-con">
                      <button
                        onClick={handleDeleteIssueConfirm}
                        color="primary"
                        variant="contained"
                        disabled={!isIssueCheckboxChecked}
                        style={{
                          backgroundColor: isIssueCheckboxChecked
                            ? "red"
                            : "grey",
                        }}
                        className="add-btn"
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => {
                          setDeleteIssueDialogOpen(false);
                          setIsIssueCheckboxChecked(false);
                        }}
                        color="secondary"
                        style={{ marginRight: "140px" }}
                        className="cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </Box>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PhaseDetails;
