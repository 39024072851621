import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import api from "../../api/axiosConfig";
import { useAuth } from "../Authentication/AuthContext";

const RoleContext = createContext();

export const RoleProvider = ({ children }) => {
  const [rolePermissions, setRolePermissions] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userDetails } = useAuth();
  const roleName = userDetails?.role_name;

  const createPermissionsMap = (permissionsData) => {
    const rolePermissions = {};
    permissionsData.forEach((item) => {
      const { role_name, module_name, action_name } = item;
      if (!rolePermissions[role_name]) {
        rolePermissions[role_name] = {};
      }
      if (!rolePermissions[role_name][module_name]) {
        rolePermissions[role_name][module_name] = [];
      }
      rolePermissions[role_name][module_name].push(action_name);
    });
    return rolePermissions;
  };

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await api.get("/api/rolePermissions");
        const permissionsMap = createPermissionsMap(response.data);
        setRolePermissions(permissionsMap);
        setLoading(false); // Finished loading
      } catch (error) {
        console.error("Failed to fetch permissions", error);
        setError(error); // Set error
        setLoading(false); // Finished loading even if error
      }
    };

    fetchPermissions();
  }, []);

  // Use useCallback to memoize the hasPermission function
  const hasPermission = useCallback(
    (module, action) => {
      const roleNamePermissions = rolePermissions[roleName]; // Get permissions for the specific user role
      return roleNamePermissions?.[module]?.includes(action);
    },
    [rolePermissions, roleName] // Dependencies
  );

  const value = useMemo(
    () => ({ rolePermissions, loading, error, hasPermission }),
    [rolePermissions, loading, error, hasPermission]
  );

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

export const useRolePermissions = () => useContext(RoleContext);
