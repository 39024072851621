import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import api from "../../api/axiosConfig";
import { IoMdClose } from "react-icons/io";
import "../../styles/Settings/Settings.css";

const fetchData = async (url, setData, setError, setLoading) => {
  setLoading(true);
  try {
    const response = await api.get(url);
    setData(response.data);
    setLoading(false);
  } catch (error) {
    console.error("Error fetching data:", error);
    setError(error);
  } finally {
    setLoading(false);
  }
};

const RoleSettings = () => {
  const [roleSettingOpen, setRoleSettingOpen] = useState(false);
  const [rolePermissions, setRolePermissions] = useState({});
  const [roles, setRoles] = useState([]);
  const [roleDialboxOpen, setRoleDialboxOpen] = useState(false);
  const [newRole, setNewRole] = useState({ name: "" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [removePermissions, setRemovePermissions] = useState(new Set());
  const [userRole, setUserRole] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState();

  const [updatedPermissions, setUpdatedPermissions] = useState({
    users: [],
    customers: [],
    groups: [],
    projects: [],
    reports: [],
    timesheets: [],
  });

  const createPermissionsMap = (permissionsData) => {
    const rolePermissions = {};
    permissionsData.forEach((item) => {
      const { role_name, module_name, action_name } = item;
      if (!rolePermissions[role_name]) rolePermissions[role_name] = {};
      if (!rolePermissions[role_name][module_name])
        rolePermissions[role_name][module_name] = [];
      rolePermissions[role_name][module_name].push(action_name);
    });
    return rolePermissions;
  };

  useEffect(() => {
    fetchData("/api/roles", setRoles, setError, setLoading);
  }, []);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await api.get("/api/rolePermissions");
        const permissionsMap = createPermissionsMap(response.data);
        setRolePermissions(permissionsMap);
      } catch (error) {
        console.error("Failed to fetch permissions", error);
      }
    };
    fetchPermissions();
  }, []);

  const updatePermissions = async () => {
    const permissionsToUpdate = rolePermissions[userRole];
    if (!permissionsToUpdate) {
      alert("No permissions to update.");
      return;
    }
    try {
      await api.put(`/api/rolepermissions/${userRole}`, {
        permissions: permissionsToUpdate,
        removePermissions: Array.from(removePermissions),
      });
      alert("Permissions updated successfully!");
      setRoleSettingOpen(false);
      window.location.reload();
    } catch (error) {
      console.error("Failed to update permissions", error);
      alert("Failed to update permissions.");
    }
  };

  const handleCheckboxChange = (role, category, action) => {
    setRolePermissions((prevPermissions) => {
      const currentPermissions = prevPermissions[role]?.[category] || [];
      const updatedPermissions = currentPermissions.includes(action)
        ? currentPermissions.filter((perm) => perm !== action)
        : [...currentPermissions, action];

      setRemovePermissions((prev) => {
        const updatedSet = new Set(prev);
        if (currentPermissions.includes(action)) {
          updatedSet.add({ module: category, action });
        } else {
          updatedSet.delete({ module: category, action });
        }
        return updatedSet;
      });

      return {
        ...prevPermissions,
        [role]: {
          ...prevPermissions[role],
          [category]: updatedPermissions,
        },
      };
    });
  };

  const handleEditRole = (roleName) => {
    setUserRole(roleName);
    setRoleSettingOpen(true);
    setRemovePermissions(new Set());
  };

  const handleAddNewRole = async () => {
    try {
      // Check if the role name is not empty
      if (!newRole.name.trim()) {
        alert("Role name cannot be empty");
        return;
      }

      // Send POST request to create a new role
      const response = await api.post("/api/roles", { name: newRole.name });
      console.log("role name", newRole.name);
      // Handle the response
      if (response.status === 201) {
        setRoles((prevRoles) => [...prevRoles, response.data]);
        alert("Role added successfully!");
        setRoleDialboxOpen(false);
        setNewRole({ name: "" }); // Clear the input field
      }
    } catch (error) {
      console.error("Failed to add new role:", error);
      alert("Failed to add role. Please try again.");
    }
  };

  const handleDeleteRole = async (roleId) => {
    try {
      // Send DELETE request to the backend
      const response = await api.delete(`/api/roles/${roleId}`);
      if (response.data.message === "Role deleted successfully") {
        // Optimistically remove the deleted role from the list
        setRoles((prevRoles) => prevRoles.filter((role) => role.id !== roleId));
        setDeleteDialogOpen(false);
        setIsCheckboxChecked(false);
        alert("Role deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting role:", error);
      alert("Failed to delete the role.");
    }
  };

  return (
    <div>
      <button
        className="add-btn role-btn"
        onClick={() => setRoleDialboxOpen(true)}
      >
        Add Role
      </button>
      {loading ? (
        <p>Loading roles...</p>
      ) : error ? (
        <p>Error loading roles: {error.message}</p>
      ) : (
        !roleSettingOpen && (
          <ul>
            {roles?.length ? (
              roles.map((role) => (
                <li key={role.id}>
                  <div className="role-list">
                    <span
                      className="initial"
                      style={{
                        marginRight: "25px",
                        marginTop: "5px",
                        height: "40px",
                        width: "40px",
                        fontSize: "22px",
                      }}
                    >
                      {role.name ? role.name.slice(0, 2).toUpperCase() : ""}
                    </span>
                    <div className="role-right-container">
                      <div>
                        <h3 className="role-name">
                          {role.name ? role.name.replace("_", " ") : role.name}
                        </h3>
                        <p>User Profile Last Modified By Admin on 07-10-2024</p>
                      </div>
                      <div className="actions-container">
                        <span>
                          <EditIcon
                            style={{ color: "blue" }}
                            onClick={() => handleEditRole(role.name)}
                          />
                        </span>
                        <span>
                          <DeleteIcon
                            style={{ color: "red" }}
                            onClick={() => {
                              setDeleteDialogOpen(true);
                              setRoleToDelete(role.id);
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <p>Loading roles...</p>
            )}
          </ul>
        )
      )}
      {roleSettingOpen && (
        <div>
          <span className="close-icon">
            <IoMdClose onClick={() => setRoleSettingOpen(false)} />
          </span>
          <div className="action-div">
            <p className="actions">Create</p>
            <p className="actions">Read</p>
            <p className="actions">Update</p>
            <p className="actions">Delete</p>
          </div>
          {[
            "users",
            "projects",
            "groups",
            "customers",
            "timesheets",
            "reports",
          ].map((category) => (
            <div key={category}>
              <h3 style={{ marginRight: "40px" }}>
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </h3>
              <div className="checkbox-container">
                {["create", "read", "update", "delete"].map((action) => (
                  <div key={action} style={{ marginRight: "60px" }}>
                    <input
                      type="checkbox"
                      checked={rolePermissions[userRole]?.[category]?.includes(
                        action
                      )}
                      className="action-label"
                      onChange={() =>
                        handleCheckboxChange(userRole, category, action)
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
          <button
            onClick={updatePermissions}
            className="add-btn"
            style={{ position: "relative", left: "330px" }}
          >
            Save Permissions
          </button>
        </div>
      )}
      {roleDialboxOpen && (
        <div className="add-layout-new-user-container add-task-dial">
          <div className="add-header">
            <h3 className="add-new-user-heading">Add Role</h3>
          </div>
          <h3 className="label-header-element">Role Name</h3>
          <input
            className="label-element"
            type="text"
            value={newRole.name}
            onChange={(e) => setNewRole({ name: e.target.value })}
          />
          <div>
            <button className="add-btn" onClick={handleAddNewRole}>
              Add
            </button>
            <button
              className="cancel-btn"
              onClick={() => {
                setRoleDialboxOpen(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {deleteDialogOpen && (
        <div className="delete-phase-dial">
          {" "}
          <div className="delete-header">
            <h3 style={{ backgroundColor: "transparent", marginLeft: "15px" }}>
              Delete Role
            </h3>
          </div>
          <div style={{ backgroundColor: "white" }}>
            <p style={{ backgroundColor: "white" }}>
              Are you sure you want to delete this Role. This action cannot be
              undone.
            </p>
            <input
              type="checkbox"
              id="delete-phase"
              checked={isCheckboxChecked}
              onChange={() => setIsCheckboxChecked((prev) => !prev)}
            />
            <label htmlFor="delete-phase" style={{ backgroundColor: "white" }}>
              I understand this action cannot be undone.
            </label>
          </div>
          <div className="delete-dial-btn-con">
            <button
              onClick={() => handleDeleteRole(roleToDelete)}
              color="secondary"
              variant="contained"
              disabled={!isCheckboxChecked}
              className="add-btn"
              style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
            >
              Delete
            </button>

            <button
              onClick={() => {
                setDeleteDialogOpen(false);
              }}
              className="cancel-btn"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoleSettings;
