import React, { useState, useEffect, useContext } from "react";
import { CircularProgress } from "@mui/material";
import DatePicker from "react-datepicker";
import "../../styles/projects/Projects.css";
import { MultiSelect } from "react-multi-select-component";
import api from "../../api/axiosConfig";
import { ProjectContext } from "../../App";

const fetchData = async (url, setData, setError) => {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error("Network response was not ok");
    const data = await response.json();
    setData(data);
  } catch (error) {
    setError(error);
  }
};
const AddProjectDial = () => {
  const [projects, setProjects] = useState([]);
  const { isNavBarOpen, setAddProjectOpen } = useContext(ProjectContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customersDropdowm, setCustomersDropdown] = useState([]);
  const [newProject, setNewProject] = useState({
    name: "",
    projectno: "",
    start_date: "",
    end_date: "",
    actual_hours: "",
    budget_hours: "",
    manager_id: "",
    description: "",
    customer_id: "",
    team_id: "",
    tags: "",
  });
  const [customers, setCustomers] = useState([]);
  const [managers, setManagers] = useState([]);
  const [users, setUsers] = useState([]);
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [nonAssociatedUsers, setNonAssociatedUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [projectGroup, setProjectGroup] = useState([]);

  //new project dial
  const closeDialog = () => {
    setAddProjectOpen(false);
    document.body.classList.remove("no-scroll");
  };
  //add new project
  const handleAddNewProject = async () => {
    try {
      const response = await api.post(`/api/projects`, { ...newProject });

      if (response.status === 201) {
        const newProject = response.data; // Newly created project
        const currentProjectId = newProject.id; // Get project ID
        setProjects([...projects, newProject]);
        alert("Project added successfully!");

        // After project is added, associate selected members with the new project
        if (selectedMembers.length > 0) {
          const userIds = selectedMembers.map((member) => member.value); // Extract 'value' instead of 'id'
          await handleAssociateUsersToProject(currentProjectId, userIds);
        }

        // Send an email to the project manager
        await sendEmailToManager(newProject.manager_id, newProject.name);

        setNewProject({
          name: "",
          projectno: "",
          start_date: "",
          end_date: "",
          actual_hours: "",
          budget_hours: "",
          manager_id: "",
          description: "",
          customer_id: "",
          team_id: "",
          tags: "",
        });

        closeDialog();
      }
    } catch (error) {
      console.error("Error adding project:", error);
      alert("Failed to add project");
    }
  };

  const sendEmailToManager = async (managerId, projectName) => {
    try {
      // Fetch the manager's email based on managerId (you need to implement this logic)
      const managerResponse = await api.get(`/api/managers/${managerId}`);
      const managerEmail = managerResponse.data.email;

      // Send an email using the API
      await api.post("/api/send-email", {
        to: managerEmail,
        subject: `New Project Assigned: ${projectName}`,
        text: `You have been assigned as the project manager for the project: ${projectName}. Please review the details and start managing it.`,
      });
    } catch (error) {
      console.error("Error sending email to project manager:", error);
      alert("Error sending email to project manager");
    }
  };

  //add users to an project
  const handleAssociateUsersToProject = async (currentProjectId, userIds) => {
    if (userIds.length === 0 || userIds.includes(undefined)) {
      console.error("Error: Invalid user IDs", userIds);
      alert("Error: Invalid user selection");
      return;
    }

    try {
      const response = await api.post(
        `/api/projects/${currentProjectId}/users`,
        {
          userIds: userIds,
        }
      );

      const data = response.data;
      alert("Users associated with the project successfully.");
      setSelectedMembers((prev) => "");
      // Update associated and non-associated users state
      setAssociatedUsers((prev) => [
        ...prev,
        ...users.filter((user) => userIds.includes(user.id)),
      ]);

      setNonAssociatedUsers((prev) =>
        prev.filter((user) => !userIds.includes(user.id))
      );
    } catch (error) {
      console.error(
        "Error associating users with project:",
        error.response?.data || error.message
      );
      alert("Error associating users with project. Please try again.");
    }
  };

  //fetching admin users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/users/admin`);
        setUsers(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //fetching all users and customers
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/api/users`);
        const cusres = await api.get(`/api/customers`);
        const groupres = await api.get(`/api/groups`);
        const formattedMembers = res.data.map((member) => ({
          value: member.id, // Assuming `id` is a unique identifier
          label: member.name, // Display the name as the label
        }));
        setMembers(formattedMembers);
        setCustomers(cusres.data);
        setProjectGroup(groupres.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
        setLoading(false); // Always stop loading after attempting to fetch
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchData("/api/users", setManagers, setError);
    fetchData("/api/customers", setCustomersDropdown, setError);
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ position: "relative", left: isNavBarOpen ? "0px" : "-80px" }}>
      <>
        <div className="add-project-dial slide-in-right">
          <div className="add-project-content">
            <div className="add-project-user-header">
              <h3
                className="add-project-user-heading"
                style={{ marginLeft: "14px" }}
              >
                New Project
              </h3>
            </div>
            <div className="project-name-number-container">
              <div className="name-container">
                <h3 className="box-heading">Project Number</h3>
                <input
                  className="box-input"
                  placeholder="Enter Project Number"
                  value={newProject.projectno}
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      projectno: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="name-container" style={{ marginLeft: "14px" }}>
                <h3 className="box-heading">Project Name</h3>
                <input
                  className="box-input"
                  placeholder="Enter Project Name"
                  value={newProject.name}
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="start-date-due-date-container">
              <div className="start-dat-container">
                <h3 className="box-heading">Start date</h3>
                <DatePicker
                  placeholderText="dd-mm-yyyy"
                  selected={newProject.start_date}
                  onChange={(date) =>
                    setNewProject((prev) => ({
                      ...prev,
                      start_date: date,
                    }))
                  }
                  dateFormat="dd-MM-yyyy"
                  className="box-input"
                />
              </div>
              <div className="due-date-container">
                <h3 className="box-heading">Due date</h3>
                <DatePicker
                  placeholderText="dd-mm-yyyy"
                  selected={newProject.end_date}
                  onChange={(date) =>
                    setNewProject((prev) => ({
                      ...prev,
                      end_date: date,
                    }))
                  }
                  dateFormat="dd-MM-yyyy"
                  className="box-input"
                />
              </div>
            </div>
            <div className="project-owner-budget-container">
              <div className="owner-container">
                <h3 className="box-heading">Owner</h3>
                <select
                  className="select-d-input"
                  value={newProject.manager_id}
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      manager_id: e.target.value,
                    }))
                  }
                >
                  <option value="" disabled>
                    Select Owner
                  </option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="name-container" style={{ marginLeft: "14px" }}>
                <h3 className="box-heading">Tags</h3>
                <input
                  className="box-input"
                  value={newProject.tags}
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      tags: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="add-description-container ">
              <div style={{ backgroundColor: "transparent" }}>
                <h3 className="box-heading">Add Description</h3>
                <textarea
                  className="box-des-input"
                  placeholder="Enter Description"
                  value={newProject.description}
                  onChange={(e) =>
                    setNewProject((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                />
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ backgroundColor: "transparent" }}>
                  <h3 className="box-heading">Select Customer</h3>
                  <select
                    className="select-d-input"
                    value={newProject.customer_id}
                    onChange={(e) => {
                      const selectedCustomerId = e.target.value;
                      setNewProject((prev) => ({
                        ...prev,
                        customer_id: selectedCustomerId,
                      }));
                    }}
                  >
                    <option value="" disabled>
                      Select Customer
                    </option>
                    {customers.map((customer) => (
                      <option key={customer.id} value={customer.id}>
                        {customer.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="tags-container">
                  <h3 className="box-heading">Project Group</h3>
                  <select
                    className="select-d-input"
                    style={{ marginTop: "0px", backgroundColor: "white" }}
                    value={newProject.team_id}
                    onChange={(e) => {
                      const selectedGroupId = e.target.value;
                      setNewProject((prev) => ({
                        ...prev,
                        team_id: selectedGroupId,
                      }));
                    }}
                  >
                    <option value="" disabled>
                      Select Group
                    </option>
                    {projectGroup.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="project-select-container">
              <div className="project-new-project-container "></div>
            </div>
            <div className="project-details-container">
              <h3 className="box-heading">Project Members</h3>
              <MultiSelect
                options={members}
                value={selectedMembers}
                onChange={setSelectedMembers}
                className="member-select-option"
              />
            </div>
          </div>
          <div className="add-new-project-button-container">
            <button className="add-btn" onClick={handleAddNewProject}>
              Add
            </button>
            <button onClick={closeDialog} className="cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddProjectDial;
