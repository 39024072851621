import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const TimesheetPieChart = ({ projectName, plannedHours, timesheetData }) => {
  // Calculate booked hours per user
  const userBookedHours = useMemo(() => {
    const hoursByUser = {};
    timesheetData.forEach((entry) => {
      const { user_name, duration } = entry;
      const [hours, minutes] = duration.split(":").map(Number);
      const totalHours = hours + minutes / 60;

      // Accumulate hours by user name
      hoursByUser[user_name] = (hoursByUser[user_name] || 0) + totalHours;
    });

    return hoursByUser;
  }, [timesheetData]);

  // Prepare data for the Pie chart
  const data = useMemo(() => {
    const labels = Object.keys(userBookedHours);
    const bookedHoursData = Object.values(userBookedHours);

    return {
      labels: [...labels, "Planned Hours"],
      datasets: [
        {
          label: projectName,
          data: [...bookedHoursData, plannedHours],
          backgroundColor: [
            ...labels.map(
              () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
            ),
            "#6200ee", // Fixed color for planned hours
          ],
          hoverBackgroundColor: [
            ...labels.map(
              () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
            ),
            "#3700b3", // Different color for planned hours on hover
          ],
          hoverOffset: 10, // Increases slice size on hover
        },
      ],
    };
  }, [userBookedHours, plannedHours, projectName]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        enabled: true,
      },
    },
    hover: {
      mode: "nearest",
      animationDuration: 200,
      shadowBlur: 10, // Adds a shadow effect for a lifted appearance
      shadowColor: "rgba(0, 0, 0, 0.3)", // Dark shadow color for a soft glow effect
    },
    animation: {
      duration: 500,
      easing: "easeOutCirc", // Circular easing for a smooth deceleration
      stagger: 500, // Staggers elements to animate one after another
    },
  };

  return (
    <div>
      <h3>User's Summary - {projectName}</h3>
      <p>User's Count: {Object.keys(userBookedHours).length}</p>
      {plannedHours === 0 && (
        <div className="duration-image-container">
          <img className="duration-image" src="/AppLayout/User-booked.png" />
          <p>Planned hours not available for this project</p>
        </div>
      )}
      <Pie data={data} options={options} className="piechart" />
    </div>
  );
};

export default TimesheetPieChart;
