import React, { useEffect, useState } from "react";
import api from "../../api/axiosConfig";
import format from "date-fns/format";
import parse from "date-fns/parse"; // Import parse to handle date strings
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useAuth } from "../Authentication/AuthContext";

const CalendarChart = ({ projectId }) => {
  const [calendarData, setCalendarData] = useState([]);
  const [error, setError] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { userDetails } = useAuth();
  const roleName = userDetails.role_name;
  const userId = userDetails.id;
  const dayNames = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const fetchCalendarData = async (month, year) => {
    try {
      const res = await api.get(`/api/timesheet/calendar/${projectId}`, {
        params: { month: month + 1, year },
      });

      // Format each entry's day field and add the day name
      const formattedData = res.data.map((entry) => {
        const dayDate = new Date(entry.day); // Create a date object
        const dayName = format(dayDate, "EEEE"); // Get the day name (e.g., 'Monday')
        return {
          ...entry,
          day: format(dayDate, "dd-MM-yyyy"), // Format the day as 'dd-MM-yyyy'
          dayName, // Add the day name
        };
      });

      setCalendarData(formattedData);
    } catch (error) {
      console.error(
        "Error fetching calendar data:",
        error.response?.data || error.message
      );
      setError("Failed to load calendar data.");
    }
  };

  useEffect(() => {
    fetchCalendarData(currentMonth, currentYear);
  }, [currentMonth, currentYear, projectId]);

  const getTotalBookedHoursForDay = (day) => {
    const currentDate = new Date(currentYear, currentMonth, day + 1); // Correct day indexing

    const totalSeconds = calendarData.reduce((acc, entry) => {
      // Parse the entry day
      const entryDate = parse(entry.day, "dd-MM-yyyy", new Date());

      // Check if the date matches
      if (entryDate.getTime() === currentDate.getTime()) {
        // For non-Administrators, filter by user ID
        if (roleName !== "Administrator" && entry.user_id !== userId) {
          return acc; // Skip entries not matching the user's ID
        }

        const durationParts = entry.booked_hours.split(":");
        const hours = Number(durationParts[0]);
        const minutes = Number(durationParts[1]);
        return acc + hours * 3600 + minutes * 60; // Convert to total seconds
      }
      return acc;
    }, 0);

    return convertSecondsToHM(totalSeconds); // Convert back to HH:MM format
  };

  const convertSecondsToHM = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };
  const firstDayIndex = new Date(currentYear, currentMonth, 1).getDay();
  const currentMonthDays = getDaysInMonth(currentMonth, currentYear);

  return (
    <div>
      <div className="calendar-controls">
        <button onClick={handlePrevMonth} style={{ border: "none" }}>
          <MdKeyboardDoubleArrowLeft />
        </button>
        <h2>{`${monthNames[currentMonth]} ${currentYear}`}</h2>
        <button onClick={handleNextMonth} style={{ border: "none" }}>
          <MdKeyboardDoubleArrowRight />
        </button>
      </div>
      <div className="calendar-grid">
        {dayNames.map((day) => (
          <div className="calendar-day-label" key={day}>
            {day}
          </div>
        ))}
        {Array.from({ length: firstDayIndex }).map((_, index) => (
          <div className="calendar-empty-day" key={index}></div>
        ))}
        {[...Array(currentMonthDays).keys()].map((day) => {
          const totalBookedHours = getTotalBookedHoursForDay(day);
          const dayDate = new Date(currentYear, currentMonth, day + 1);
          const isToday =
            dayDate.getDate() === new Date().getDate() &&
            dayDate.getMonth() === new Date().getMonth() &&
            dayDate.getFullYear() === new Date().getFullYear();
          const dayName = format(dayDate, "EEEE");

          return (
            <div
              className={`calendar-day ${isToday ? "calendar-today" : ""}`}
              key={day}
              style={{
                backgroundColor: isToday ? "yellow" : "transparent",
                fontWeight: isToday ? "600" : "400",
                color:
                  dayDate.getDay() === 0 ||
                  (dayDate.getDay() === 6 && Math.ceil((day + 1) / 7) % 2 === 1)
                    ? "red"
                    : "black",
              }}
            >
              <div style={{ backgroundColor: "transparent" }}>{day + 1}</div>
              <div
                style={{
                  color: "#6200ee",
                  display: totalBookedHours === "00:00" ? "none" : "block",
                  backgroundColor: "transparent",
                }}
              >
                {totalBookedHours}
              </div>
            </div>
          );
        })}
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default CalendarChart;
