import React, { useState, useEffect, useContext, useRef } from "react";
import { useRolePermissions } from "../Settings/RoleContext";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import api from "../../api/axiosConfig";
import DatePicker from "react-datepicker";
import { MultiSelect } from "react-multi-select-component";
import "../../styles/layouts/ProjectLayout.css";
import { ProjectContext } from "../../App";
// import { newDate } from "react-datepicker/dist/date_utils";

const ProjectLayout = () => {
  const [openSection, setOpenSection] = useState("projectInformation");
  const { hasPermission } = useRolePermissions();
  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };
  const { projectId } = useParams();
  const { onFrameButtonClick, isNavBarOpen } = useContext(ProjectContext);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [members, setMembers] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("Planning");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedCompletedDate, setSelectedCompletedDate] = useState("");
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [billingMethod, setBillingMethod] = useState("");
  const [budgetType, setBudgetType] = useState("");
  const [currencyType, setCurrencyType] = useState("");
  const [statusOptions, setStatusOption] = useState([]);
  const [projectStatus, setProjectStatus] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [tags, setTags] = useState("");
  const [projectNo, setProjectNo] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customers, setCustomers] = useState([]);

  //fetching status options, group , project members
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get("/api/status");
        const groupres = await api.get(`/api/groups`);
        const cusres = await api.get(`/api/customers`);
        const associateUsersres = await api.get(
          `/api/projects/${projectId}/users`
        );
        setCustomers(cusres.data);
        if (Array.isArray(res.data)) {
          const statusData = res.data.map((status) => ({
            id: status.id,
            name: status.name,
            color: status.color,
          }));
          setStatusOption(statusData);
        } else {
          console.error("Expected an array of statuses, but got:", res.data);
          setStatusOption([]); // Fallback to an empty array
        }

        // Ensure that groupres.data is an array
        if (Array.isArray(groupres.data)) {
          setSelectedGroup(groupres.data);
        } else {
          console.error("Expected an array of groups, but got:", groupres.data);
          setSelectedGroup([]); // Fallback to an empty array
        }

        const transformedAssociatedUsers = associateUsersres.data.map(
          (user) => ({
            value: user.id,
            label: user.name,
          })
        );

        setAssociatedUsers(transformedAssociatedUsers);
        // setSelectedMembers(transformedAssociatedUsers);

        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (projectId) {
      const fetchProjectDetails = async () => {
        try {
          const res = await api.get(`/api/projects/${projectId}`);
          const projectData = res.data; // Assuming this is a single project object
          console.log("project data", projectData);

          // Check for '0000-00-00' and handle it
          const formattedProject = {
            ...projectData,
            start_date:
              projectData.start_date && projectData.start_date !== "0000-00-00"
                ? new Date(projectData.start_date)
                : null, // Set to null for invalid/missing dates
            end_date:
              projectData.end_date && projectData.end_date !== "0000-00-00"
                ? new Date(projectData.end_date)
                : null, // Set to null for invalid/missing dates
          };
          console.log("formatted project", formattedProject);
          // Find the status data corresponding to the project's status_id
          const status = statusOptions.find(
            (s) => s.id === formattedProject.status_id
          );

          // Set states including project status and status color
          setProject(formattedProject);
          setSelectedOwner(formattedProject.manager_id || "");
          setSelectedStatus(formattedProject.status_id || "");
          setSelectedDate(formattedProject.start_date || new Date());
          setSelectedEndDate(formattedProject.end_date || new Date());
          setSelectedCompletedDate(formattedProject.completed_date || "");
          setSelectedGroup(formattedProject.team_id || "");
          setBillingMethod(formattedProject.billingMethod || "");
          setBudgetType(formattedProject.budgetType || "");
          setCurrencyType(formattedProject.currencyType || "");

          // Set the project status and color based on the found status
          setProjectStatus(status ? status.name : "Unknown Status");
          setStatusColor(status ? status.color : "#000000"); // Default to black if not found

          setTags(formattedProject.tags || "");
          setProjectNo(formattedProject.projectno || "");
          setCustomerName(formattedProject.customer_id || "");
          setLoading(false);
        } catch (error) {
          console.error("Error:", error);
          setError(error.message);
          setLoading(false);
        }
      };

      fetchProjectDetails();
    } else {
      setError("Project ID is undefined");
      setLoading(false);
    }
  }, [projectId, statusOptions]);

  //fetching admin users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get("/api/users/admin");
        setUsers(res.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  //fetching all users
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get("/api/users");
        const formattedMembers = res.data.map((member) => ({
          value: member.id, // Assuming `id` is a unique identifier
          label: member.name, // Display the name as the label
        }));
        setMembers(formattedMembers);
      } catch (error) {
        if (error.response) {
          // Server responded with a status other than 200 range
          console.log("Error response:", error.response.data);
        } else if (error.request) {
          // Request was made but no response received
          console.log("Error request:", error.request);
        } else {
          // Something else caused the error
          console.log("Error message:", error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // project status heading and background color
  const handleStatusChange = (e) => {
    setProjectStatus(e.target.value);
    const selectedId = parseInt(e.target.value, 10);
    const selectedOption = statusOptions.find(
      (status) => status.id === selectedId
    );
    if (selectedOption) {
      setProjectStatus(selectedOption.name);
      setStatusColor(selectedOption.color);
      setSelectedStatus(selectedId);
    }
  };

  //update project details
  const handleSave = async () => {
    const formatDateForMySQL = (date) => {
      if (!date) return null; // If no date is provided, return null

      // Check if the date is already a string (in case the date is not a Date object)
      if (typeof date === "string") {
        return date;
      }

      // Check if the date is a valid Date object
      const validDate = new Date(date);
      if (isNaN(validDate)) {
        return null; // Return null if invalid date
      }

      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );

      return localDate.toISOString().split("T")[0]; // Convert to 'YYYY-MM-DD' or null if date is not selected
    };

    const updatedProject = {
      ...project,
      manager_id: selectedOwner,
      members: associatedUsers.map((user) => user.value), // Use user IDs here for the project
      status_id: selectedStatus,
      start_date: selectedDate
        ? formatDateForMySQL(selectedDate)
        : formatDateForMySQL(project.start_date),
      end_date: selectedEndDate
        ? formatDateForMySQL(selectedEndDate)
        : formatDateForMySQL(project.end_date),
      completed_date: selectedCompletedDate,
      group: selectedGroup,
      billingMethod: billingMethod,
      budgetType: budgetType,
      currencyType: currencyType,
      status: projectStatus,
      statusColor: statusColor,
      tags: tags,
      customer_name: customerName,
      customer_id: customerName,
      projectno: projectNo,
    };

    try {
      const res = await api.put(`/api/projects/${projectId}`, updatedProject);
      if (res.status === 200) {
        alert("Project updated successfully!");

        // Only add users if associatedUsers is a valid array
        if (Array.isArray(associatedUsers) && associatedUsers.length > 0) {
          // Updated userIds key here
          const userAddResponse = await api.post(
            `/api/projects/${projectId}/users`,
            { userIds: associatedUsers.map((user) => user.value) } // Corrected here
          );

          if (userAddResponse.status === 200) {
            alert("Users added to the project successfully!");
          } else {
            console.error("Failed to add users:", userAddResponse);
            alert("Failed to add users to the project");
          }
        }
      } else {
        console.error("Failed to update project:", res);
        alert("Failed to update project");
      }
    } catch (error) {
      console.error(
        "Error updating project:",
        error.response ? error.response.data : error.message
      );
    }
  };

  if (loading)
    return (
      <div className="error-message">
        <CircularProgress />
      </div>
    );
  if (error)
    return (
      <div className="error-message">
        <p>Error loading project details</p>
        <CircularProgress />
      </div>
    );

  // const projectName = project ? project.name : "Project";

  return (
    <>
      <div
        className={
          isNavBarOpen
            ? "project-individual-details"
            : "project-individual-details-close"
        }
      >
        <div className="left-right-container">
          <div className="left-container">
            <h1 className="proj-name">
              {project.name}({project.projectno})
            </h1>
            <div className="information-container">
              <h6
                className="completed"
                style={{ backgroundColor: statusColor }}
              >
                {projectStatus}
              </h6>
              <IoMdClose className="close-btn" onClick={onFrameButtonClick} />
            </div>
          </div>
          {/* <div className="right-container">
            <IoMdClose className="close-icon" onClick={onFrameButtonClick} />
          </div> */}
        </div>
        <div
          className="left-right-con"
          style={{ marginTop: "98px", marginLeft: "2px" }}
        >
          <div className="left-Navbar">
            <h3
              className="settings-label"
              style={{
                color:
                  openSection === "projectInformation" ? "#6200ee" : "black",
              }}
              onClick={() => toggleSection("projectInformation")}
            >
              Project Information
            </h3>
            <h3
              className="settings-label"
              onClick={() => toggleSection("projectDetails")}
              style={{
                color: openSection === "projectDetails" ? "#6200ee" : "black",
              }}
            >
              Project Details
            </h3>
            <h3
              className="settings-label"
              onClick={() => toggleSection("budget")}
              style={{
                color: openSection === "budget" ? "#6200ee" : "black",
              }}
            >
              Budget
            </h3>
            <h3
              className="settings-label"
              onClick={() => toggleSection("description")}
              style={{
                color: openSection === "description" ? "#6200ee" : "black",
              }}
            >
              Description
            </h3>
            <h3
              className="settings-label"
              onClick={() => toggleSection("comments")}
              style={{
                color: openSection === "comments" ? "#6200ee" : "black",
              }}
            >
              Comments
            </h3>
          </div>
          <div className="right-container"></div>
        </div>
        {openSection === "projectInformation" && (
          <div className={isNavBarOpen ? "project-content" : "project-content"}>
            <div className="settings-details">
              <div className="project-owner-status-container">
                <div className="project-owner-container">
                  <h1 className="owner-heading">Owner</h1>
                  <select
                    className="select-owner-dropdown"
                    value={selectedOwner}
                    onChange={(e) => setSelectedOwner(e.target.value)}
                    disabled={!hasPermission("projects", "update")}
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                    }}
                  >
                    <option value="" disabled>
                      Select Owner
                    </option>
                    {users.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="project-owner-container">
                  <h1 className="status-heading">Status</h1>
                  <select
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    className="status-options-dropdown"
                    disabled={!hasPermission("projects", "update")}
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                    }}
                  >
                    <option value="" disabled>
                      Select a status
                    </option>
                    {Array.isArray(statusOptions) &&
                      statusOptions.map((status) => (
                        <option key={status.id} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="project-owner-status-container">
                <div className="project-owner-container">
                  <h1 className="start-date-heading">Start Date</h1>
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select a date"
                    className={`start-date-input ${
                      !hasPermission("projects", "update")
                        ? "non-editable-textarea"
                        : ""
                    }`}
                    disabled={!hasPermission("projects", "update")}
                  />
                </div>
                <div className="project-owner-container">
                  <h1 className="end-date-heading">End Date</h1>
                  <DatePicker
                    selected={selectedEndDate}
                    onChange={(date) => setSelectedEndDate(date)}
                    dateFormat="dd-MM-yyyy"
                    placeholderText="Select a date"
                    className={`date-input ${
                      !hasPermission("projects", "update")
                        ? "non-editable-textarea"
                        : ""
                    }`}
                    disabled={!hasPermission("projects", "update")}
                  />
                </div>
              </div>
              <div className="project-owner-status-container">
                <div className="project-owner-container">
                  <h1 className="tags-heading">Tags</h1>
                  <input
                    className="tags-input"
                    value={project.tags}
                    onChange={(e) =>
                      setProject({ ...project, tags: e.target.value })
                    }
                    disabled={!hasPermission("projects", "update")}
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                      marginLeft: "77px",
                    }}
                  />
                </div>
                <div className="project-owner-container">
                  <h1 className="completed-date-heading">Completed Date</h1>
                  <DatePicker
                    selected={selectedCompletedDate}
                    onChange={(date) => setSelectedCompletedDate(date)}
                    dateFormat="yyyy/MM/dd"
                    placeholderText="Select a date"
                    className={`completed-date-input ${
                      !hasPermission("projects", "update")
                        ? "non-editable-textarea"
                        : ""
                    }`}
                    disabled={!hasPermission("projects", "update")}
                  />
                </div>
              </div>
              <div className="project-owner-container">
                <h1 className="project-group-heading">Project Group</h1>
                <select
                  className="group-dropdown"
                  value={selectedGroup}
                  onChange={(e) => {
                    setSelectedGroup(e.target.value);
                  }}
                  disabled={!hasPermission("projects", "update")}
                  style={{
                    cursor: !hasPermission("projects", "update")
                      ? "not-allowed"
                      : "auto",
                    marginLeft: "76px",
                  }}
                >
                  <option value="" disabled>
                    Select group
                  </option>
                  {Array.isArray(selectedGroup) &&
                    selectedGroup.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        )}
        {openSection === "projectDetails" && (
          <div className={isNavBarOpen ? "project-content" : "project-content"}>
            <div className="settings-details">
              <div className="project-owner-status-container">
                <div className="project-owner-container">
                  <h1 className="proj-no-heading">Project Number</h1>
                  <input
                    type="text"
                    className="tags-input"
                    placeholder="Enter Project Number"
                    value={project.projectno}
                    onChange={(e) =>
                      setProject({ ...project, projectno: e.target.value })
                    }
                    disabled={!hasPermission("projects", "update")}
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                    }}
                  />
                </div>
                <div className="project-owner-container">
                  {" "}
                  <h1 className="phase-no-heading">Client Ref Number</h1>
                  <input
                    type="text"
                    placeholder="Enter Client Ref Number"
                    className="tags-input"
                    disabled={!hasPermission("projects", "update")}
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                    }}
                  />
                </div>
              </div>
              <div className="project-owner-status-container">
                <div className="project-owner-container">
                  <h1 className="cus-name-heading">Customer Name</h1>
                  <select
                    className="select-owner-dropdown"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    disabled={!hasPermission("projects", "update")}
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                    }}
                  >
                    <option disabled>Select Customer</option>
                    {customers.map((customer) => (
                      <option key={customer.id} value={customer.id}>
                        {customer.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="project-owner-status-container">
                {hasPermission("projects", "update") && (
                  <div className="project-owner-container">
                    <h1 className="members-heading">Project Members</h1>
                    <MultiSelect
                      options={members}
                      value={associatedUsers}
                      onChange={setAssociatedUsers}
                      className={`multi-p-select-option ${
                        !hasPermission("projects", "update")
                          ? "non-editable-textarea"
                          : ""
                      }`}
                      disabled={!hasPermission("projects", "update")}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {openSection === "budget" && (
          <div className={isNavBarOpen ? "project-content" : "project-content"}>
            <div className="settings-details">
              {" "}
              <div className="project-owner-status-container">
                <div className="project-owner-container">
                  <h1 className="currency-heading">Currency</h1>
                  <select
                    value={currencyType}
                    className="group-dropdown"
                    onChange={(e) => {
                      setCurrencyType(e.target.value);
                    }}
                    disabled={!hasPermission("projects", "update")}
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                    }}
                  >
                    <option value="" disabled>
                      Select Currency type
                    </option>
                    <option>IND-Indian Rupee</option>
                    <option>GDP-British pound sterling</option>
                  </select>
                </div>
                <div className="project-owner-container">
                  {" "}
                  <h1 className="budget-type-heading">Budget type</h1>
                  <select
                    className="group-dropdown"
                    value={budgetType}
                    onChange={(e) => {
                      setBudgetType(e.target.value);
                    }}
                    disabled={!hasPermission("projects", "update")}
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                    }}
                  >
                    <option value="" disabled>
                      Select Budget type
                    </option>
                    <option>Based on Project Amount</option>
                    <option>Based on Project Hours</option>
                    <option>Based on Task Amount</option>
                    <option>Based on Task Hours</option>
                    <option>Based on User Amount</option>
                    <option>Based on User Hours</option>
                  </select>
                </div>
              </div>
              <div className="project-owner-status-container">
                <div className="project-owner-container">
                  <h1 className="billing-heading">Billing Method</h1>
                  <select
                    value={billingMethod}
                    onChange={(e) => {
                      setBillingMethod(e.target.value);
                    }}
                    className="group-dropdown"
                    disabled={!hasPermission("projects", "update")}
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                      marginLeft: "78px",
                    }}
                  >
                    <option value="" disabled>
                      Select Billing type
                    </option>
                    <option>Based on Project Hours</option>
                    <option>Based on Staff Hours</option>
                    <option>Based on Task/Issue Hours</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
        {openSection === "description" && (
          <div className={isNavBarOpen ? "project-content" : "project-content"}>
            <div className="settings-details">
              <div className="project-owner-status-container">
                <div
                  className="project-owner-container"
                  style={{ flexDirection: "column" }}
                >
                  <h1 className="description-heading">Description</h1>
                  <textarea
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                    }}
                    type="text-area"
                    placeholder="No Description available"
                    className="description-para"
                    value={project.description}
                    onChange={(e) =>
                      setProject({ ...project, description: e.target.value })
                    }
                    disabled={!hasPermission("projects", "update")}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {openSection === "comments" && (
          <div className={isNavBarOpen ? "project-content" : "project-content"}>
            <div className="settings-details">
              <div className="project-owner-status-container">
                <div
                  className="project-owner-container"
                  style={{ flexDirection: "column" }}
                >
                  <h1 className="description-heading">Comments</h1>
                  <textarea
                    type="text-area"
                    placeholder="No Comments"
                    className="description-para"
                    height={200}
                    width={300}
                    disabled={!hasPermission("projects", "update")}
                    style={{
                      cursor: !hasPermission("projects", "update")
                        ? "not-allowed"
                        : "auto",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className={
          isNavBarOpen
            ? "individual-btn-container"
            : "individual-btn-container-close"
        }
      >
        <button
          className="save-project-btn"
          onClick={handleSave}
          style={{
            cursor: !hasPermission("projects", "update")
              ? "not-allowed"
              : "auto",
          }}
          disabled={!hasPermission("projects", "update")}
        >
          Save
        </button>
        <button className="cancel-project-btn" onClick={onFrameButtonClick}>
          Cancel
        </button>
      </div>
    </>
  );
};

export default ProjectLayout;
