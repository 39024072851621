import React, { createContext, useState, useContext, useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Retrieve the authentication state from local storage
    const savedAuthState = localStorage.getItem("isAuthenticated");
    return savedAuthState === "true"; //savedAuthState ? JSON.parse(savedAuthState) : false;
  });
  const [userDetails, setUserDetails] = useState(() => {
    const savedUserDetails = localStorage.getItem("userDetails");
    return savedUserDetails ? JSON.parse(savedUserDetails) : null;
  });

  const login = (details) => {
    setIsAuthenticated(true);
    localStorage.setItem("isAuthenticated", "true");
    setUserDetails(details);
    console.log("user details from contexct", details);
    localStorage.setItem("userDetails", JSON.stringify(details));
    // Clear tabClosed flag on successful login
    sessionStorage.removeItem("tabClosed");
  };

  const logout = () => {
    if (userDetails && userDetails.id) {
      const logoutTime = new Date().toISOString();
      localStorage.setItem(`lastLogoutTime_${userDetails.id}`, logoutTime);
    }
    setUserDetails(null);
    localStorage.removeItem("userDetails");
    sessionStorage.removeItem("tabClosed");
    setIsAuthenticated(false);
    localStorage.setItem("isAuthenticated", "false");
  };

  useEffect(() => {
    // Set the authentication state in local storage whenever it changes
    localStorage.setItem("isAuthenticated", isAuthenticated ? "true" : "false"); //JSON.stringify(isAuthenticated)
  }, [isAuthenticated]);

  useEffect(() => {
    if (userDetails) {
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    } else {
      localStorage.removeItem("userDetails");
    }
  }, [userDetails]);

  // set authentication false when user closes the browser without logout

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (userDetails && userDetails.id) {
        sessionStorage.setItem("tabClosed", "true"); // Mark session as ended
        // event.preventDefault(); // Required for dialog to show
        event.returnValue = ""; // Standard for some browsers
      }
    };

    const handleUnload = () => {
      // Reset the tabClosed flag on page unload (refresh)
      sessionStorage.removeItem("tabClosed");
      setIsAuthenticated(false);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [userDetails]);

  // Check if session was ended by tab close
  useEffect(() => {
    const wasTabClosed = sessionStorage.getItem("tabClosed") === "true";

    if (wasTabClosed) {
      logout();
      sessionStorage.removeItem("tabClosed"); // Clean up
    }
  }, [logout]);

  const firstLetter = userDetails
    ? userDetails.name.charAt(0).toUpperCase()
    : "?";

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, userDetails, firstLetter, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
