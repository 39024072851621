import React from "react";
import { useState, useCallback, useContext } from "react";
import { useAuth } from "./Authentication/AuthContext";
import "../styles/Home.css";
import { ProjectContext } from "../App";
import { Helmet } from "react-helmet";

const Home = () => {
  const { isNavBarOpen } = useContext(ProjectContext);
  const { userDetails } = useAuth();
  const username = userDetails.name;

  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) {
      return "Good morning,";
    } else if (currentHour < 16) {
      return "Good afternoon";
    } else {
      return "Good evening";
    }
  };

  const greeting = getGreeting();

  return (
    <div>
      <Helmet>
        <title>Home - MSES Timesheet</title>
      </Helmet>
      <div className={isNavBarOpen ? "home-container" : ""}>
        <h1 className="greeting-content">
          Welcome {username},<br /> <span className="greeting">{greeting}</span>
        </h1>
        <h1 className="temp-text">
          Big Things Are Coming! Our Timesheet is getting a makeover. Stay
          tuned! 👍
        </h1>
      </div>
    </div>
  );
};

export default Home;
