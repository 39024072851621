import React, { useState, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppLayout from "./components/Layouts/AppLayout.js";
import Home from "./components/Home.js";
import CustomerList from "./components/CustomerList";
import ContactList from "./components/ContactList.js";
import Overview from "./components/TimeSheet/Overview.js";
import SignIn from "./components/SignIn/SignIn.js";
import ForgotPassword from "./components/SignIn/ForgotPassword.js";
import ResetPassword from "./components/SignIn/ResetPassword.js";
import ProjectList from "./components/Projects/ProjectList";
import ProjectLayout from "./components/Layouts/ProjectLayout";
import ProjectUsers from "./components/ProfileUser/ProjectUsers";
import { AuthProvider, useAuth } from "./components/Authentication/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import TimeSheetTable from "./components/TimeSheet/TimesheetTable";
import TableTimeSheet from "./components/TimeSheet/TableTimesheet";
import ProjectDetails from "./components/Projects/ProjectDetails";
import PhaseDetails from "./components/Projects/PhaseDetails";
import VariationDetails from "./components/TimeSheet/VariationDetails";
import IssueDetails from "./components/TimeSheet/IssueDetails";
import General from "./components/TimeSheet/General";
import GroupList from "./components/GroupList.js";
import GroupDetails from "./components/GroupDetails.js";
import Settings from "./components/Settings/Settings.js";
import DashBoard from "./components/Projects/Dashboard.js";
import { RoleProvider } from "./components/Settings/RoleContext";

export const ProjectContext = createContext();

const AppContent = () => {
  const [refresh, setRefresh] = useState(false);
  const [username, setUsername] = useState("");
  const [dialBoxopen, setDialBoxopen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [isNavBarOpen, setIsNavBarOpen] = useState(true);
  const [addTimesheetOpen, setAddTimesheetOpen] = useState(false);
  const [addProjectOpen, setAddProjectOpen] = useState(false);

  const onFrameButtonClick = () => {
    setDialBoxopen((prev) => !prev);
    document.body.classList.remove("no-scroll");
  };

  const { isAuthenticated } = useAuth();

  // Helper function to create protected routes
  const createProtectedRoute = (path, element, requiredRole = null) => (
    <Route
      path={path}
      element={
        <ProtectedRoute requiredRole={requiredRole}>{element}</ProtectedRoute>
      }
    />
  );

  return (
    <ProjectContext.Provider
      value={{
        dialBoxopen,
        setDialBoxopen,
        onFrameButtonClick,
        openDialog,
        setOpenDialog,
        setIsNavBarOpen,
        isNavBarOpen,
        setAddTimesheetOpen,
        addTimesheetOpen,
        setAddProjectOpen,
        addProjectOpen,
      }}
    >
      <div>
        {isAuthenticated && <AppLayout username={username} />}
        <div className="content-container">
          <Routes>
            <Route path="/" element={<SignIn setUsername={setUsername} />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            {createProtectedRoute("/project/:projectId", <ProjectLayout />)}
            {createProtectedRoute("/home", <Home />)}
            {createProtectedRoute(
              "/projectlist",
              <ProjectList refresh={refresh} />
            )}
            {createProtectedRoute(
              "/customerlist",
              <CustomerList refresh={refresh} />,
              "Administrator"
            )}
            {createProtectedRoute(
              "/api/customers/:customerId/contacts",
              <ContactList refresh={refresh} />,
              "Administrator"
            )}
            {createProtectedRoute(
              "/grouplist",
              <GroupList refresh={refresh} />,
              "Administrator"
            )}
            {createProtectedRoute(
              "/group/:groupId",
              <GroupDetails refresh={refresh} />,
              "Administrator"
            )}
            {createProtectedRoute("/settings", <Settings />, "Administrator")}
            {createProtectedRoute("/timesheet/overview", <Overview />)}
            {createProtectedRoute(
              "/project/:projectId/users",
              <div>
                <ProjectLayout />
                <ProjectUsers />
              </div>
            )}
            {createProtectedRoute(
              "/project/:projectId/timesheets/general",
              <div>
                <ProjectLayout />
                <General />
              </div>
            )}
            {createProtectedRoute(
              "/project/:projectId/dashboard",
              <div>
                <ProjectLayout />
                <DashBoard />
              </div>
            )}
            {createProtectedRoute(
              "/project/:projectId/timesheetsold",
              <div>
                <ProjectLayout />
                <TableTimeSheet refresh={refresh} />
              </div>
            )}
            {createProtectedRoute(
              "/project/:projectId/timesheets",
              <div>
                <ProjectLayout />
                <TimeSheetTable refresh={refresh} />
              </div>
            )}
            {createProtectedRoute(
              "/phases/:projectId",
              <div>
                <ProjectLayout />
                <ProjectDetails />
              </div>
            )}
            {createProtectedRoute(
              "/project/:projectId/phase/:phaseId",
              <div>
                <ProjectLayout />
                <PhaseDetails />
              </div>
            )}
            {createProtectedRoute(
              "/project/:projectId/timesheets/variation",
              <div>
                <ProjectLayout />
                <VariationDetails />
              </div>
            )}
            {createProtectedRoute(
              "/project/:projectId/timesheets/issue",
              <div>
                <ProjectLayout />
                <IssueDetails />
              </div>
            )}
          </Routes>
        </div>
      </div>
    </ProjectContext.Provider>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <RoleProvider>
        <Router>
          <AppContent />
        </Router>
      </RoleProvider>
    </AuthProvider>
  );
};

export default App;
