import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./Authentication/AuthContext";

const ProtectedRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, userDetails } = useAuth();
  const userRole = userDetails?.role_name; // Ensure userDetails is available
  if (!isAuthenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/" />;
  }

  if (requiredRole && userRole !== requiredRole) {
    // Redirect to unauthorized page if the role does not match
    return <Navigate to="/home" />;
  }

  // If authenticated and role matches (or no role is required), render the children
  return children;
};

export default ProtectedRoute;
