import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./DateRangePicker.css";

const DateRangePickerComp = ({ onDateRangeChange }) => {
  const [range, setRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);

  const [dateValue, setDateValue] = useState(false); // Track whether dates are selected
  const [open, setOpen] = useState(false); // To control the date picker visibility
  const [inputValue, setInputValue] = useState("Project Span"); // To control the input display value
  const refOne = useRef(null);

  const handleSearch = () => {
    if (range[0].startDate && range[0].endDate) {
      // setDateValue(true); // Set the dateValue to true (this is async)
      setDateValue(true);

      const startDate = format(range[0].startDate, "MM-dd-yyyy");
      const endDate = format(range[0].endDate, "MM-dd-yyyy");
      // Use a local variable to hold the formatted date range
      const formattedDateRange = `${startDate} to ${endDate}`;
      setInputValue(formattedDateRange); // Update the input field value
      setOpen(false); // Close the date picker
      // Send the range to the parent component
      onDateRangeChange({ startDate, endDate });
    } else {
      setDateValue(false); // Reset dateValue to false
      setInputValue("Project Span"); // Reset the input field to default text
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
    return () => {
      document.removeEventListener("keydown", hideOnEscape, true);
      document.removeEventListener("click", hideOnClickOutside, true);
    };
  }, []);

  const hideOnEscape = (e) => {
    if (e.key === "Esc") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div className="calendarWrap">
      <input
        placeholder="Select a date range"
        value={inputValue}
        readOnly
        className="inputBox"
        onClick={() => setOpen((open) => !open)}
        title="Filter by date"
      />

      <div ref={refOne} style={{ backgroundColor: "transparent" }}>
        {open && (
          <div style={{ backgroundColor: "transparent" }}>
            <DateRangePicker
              onChange={(item) => {
                setRange([
                  {
                    startDate: item.selection.startDate || range[0].startDate,
                    endDate: item.selection.endDate || range[0].endDate,
                    key: "selection",
                  },
                ]);
              }}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={range}
              months={1}
              value={inputValue}
              direction="horizontal"
              className="date-range-picker"
              rangeColors={["#6200ea"]}
              weekdayDisplayFormat="EEE"
              monthDisplayFormat="MMM yyyy"
              inputRanges={[]}
              dayContentRenderer={(date) => (
                <div
                  className={`rdrDay ${date.selected ? "rdrDaySelected" : ""}`}
                >
                  {format(date, "d")}
                </div>
              )}
            />
            <div className="buttonContainer">
              <button className="add-btn span-btn">Project Span</button>
            </div>
            <button className="add-btn searchButton" onClick={handleSearch}>
              Search
            </button>
            <button
              onClick={() => {
                setOpen(false);
                setDateValue(false);
                setInputValue("Project Span"); // Reset the input field when closing
              }}
              className="cancel-btn closeButton"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangePickerComp;
