import React, { useState, useContext } from "react";
import { ProjectContext } from "../../App";
import "./AddUser.css";
import api from "../../api/axiosConfig";
import { GoEye } from "react-icons/go";
import { GoEyeClosed } from "react-icons/go";
const AddUser = () => {
  const { openDialog, setOpenDialog } = useContext(ProjectContext);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    designation: "",
    employeecode: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  // adding user details
  const handleAddUser = async () => {
    if (
      !newUser.name ||
      !newUser.email ||
      !newUser.role ||
      !newUser.designation
    ) {
      alert("All fields are required.");
      return;
    }

    try {
      const response = await api.post("/api/users", {
        ...newUser,
        gender: "male",
        phone: "",
      });

      setOpenDialog(false);
      document.body.classList.remove("no-scroll");

      const data = response.data;
      setUsers([...users, data]);
      setNewUser({
        name: "",
        email: "",
        role: "",
        designation: "",
        password: "",
      });
      alert("New User added");
    } catch (error) {
      console.error("Error adding user:", error);
      alert("Error adding user. Please try again.");
    }
  };
  return (
    <>
      {openDialog && (
        <>
          <div
            className="add-layout-new-user-container"
            style={{ zIndex: "1" }}
          >
            <div className="add-header">
              <h3 className="add-new-user-heading">Add new user</h3>
            </div>
            <div className="add-user-content">
              <label htmlFor="name" className="label-header-element">
                Name
              </label>
              <input
                id="name"
                placeholder="Name"
                value={newUser.name}
                className="label-element"
                onChange={(e) =>
                  setNewUser({ ...newUser, name: e.target.value })
                }
              />

              <label htmlFor="email" className="label-header-element">
                Email
              </label>
              <input
                id="email"
                value={newUser.email}
                placeholder="Email"
                className="label-element"
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
              />
              <label htmlFor="role" className="label-header-element">
                Role
              </label>
              <input
                id="role"
                value={newUser.role}
                className="label-element"
                placeholder="Role"
                onChange={(e) =>
                  setNewUser({ ...newUser, role: e.target.value })
                }
              />
              <label htmlFor="designation" className="label-header-element">
                Profile
              </label>
              <input
                id="designation"
                value={newUser.designation}
                className="label-element"
                placeholder="Profile"
                onChange={(e) =>
                  setNewUser({ ...newUser, designation: e.target.value })
                }
              />
              <label htmlFor="designation" className="label-header-element">
                Employee Code
              </label>
              <input
                id="designation"
                value={newUser.employeecode}
                className="label-element"
                placeholder=" Enter Employee Code"
                onChange={(e) =>
                  setNewUser({ ...newUser, employeecode: e.target.value })
                }
              />

              <div className="password-input">
                <label htmlFor="designation" className="label-header-element">
                  Password
                </label>
                <input
                  id="designation"
                  type={showPassword ? "text" : "password"}
                  value={newUser.password}
                  className="label-element-password"
                  placeholder=" Enter Password"
                  onChange={(e) =>
                    setNewUser({ ...newUser, password: e.target.value })
                  }
                />
                {showPassword ? (
                  <GoEyeClosed
                    className="password-icon"
                    onClick={handleClickShowPassword}
                  />
                ) : (
                  <GoEye
                    className="password-icon"
                    onClick={handleClickShowPassword}
                  />
                )}
              </div>
            </div>
            <div className="add-new-user-button-a-container">
              <button
                onClick={handleAddUser}
                style={{ marginLeft: "22px", marginRight: "20px" }}
                className="add-btn"
              >
                Add
              </button>
              <button
                onClick={() => setOpenDialog((prev) => !prev)}
                style={{ marginLeft: "-02px" }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddUser;
