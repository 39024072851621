import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import api from "../../api/axiosConfig";
import "../../styles/layouts/ProjectLayout.css";
import { ProjectContext } from "../../App";
import ProjectIndividualDetails from "./ProjectIndividualStatus";
import { Helmet } from "react-helmet";

const ProjectLayout = () => {
  const { projectId } = useParams();
  const { dialBoxopen, onFrameButtonClick, isNavBarOpen } =
    useContext(ProjectContext);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeLink, setActiveLink] = useState(() => {
    return localStorage.getItem("activeLink") || "dashboard";
  });

  const handleLinkClick = (link) => {
    setActiveLink(link); // Update the active link
    localStorage.setItem("activeLink", link);
  };
  // useEffect(() => {
  //   if (projectId) {
  //     setActiveLink("dashboard");
  //   }
  // }, [projectId, setActiveLink]);
  useEffect(() => {
    if (projectId) {
      const fetchProjectDetails = async () => {
        try {
          const res = await api.get(`/api/projects/${projectId}`);
          const projectData = res.data; // Assuming this is a single project object
          const formattedProject = {
            ...projectData,
            start_date:
              projectData.start_date && projectData.start_date !== "0000-00-00"
                ? new Date(projectData.start_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "Invalid Start Date", // Fallback for '0000-00-00' or missing start_date
            end_date:
              projectData.end_date && projectData.end_date !== "0000-00-00"
                ? new Date(projectData.end_date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : "Invalid End Date", // Fallback for '0000-00-00' or missing end_date
          };
          setProject(formattedProject);
          setLoading(false);
        } catch (error) {
          console.error("Error:", error);
          setError(error.message);
          setLoading(false);
        }
      };
      fetchProjectDetails();
    } else {
      setError("Project ID is undefined");
      setLoading(false);
    }
  }, [projectId]);

  if (loading)
    return (
      <div className="error-message">
        <CircularProgress />
      </div>
    );
  if (error)
    return (
      <div className="error-message">
        <p>Error loading project details</p>
        <CircularProgress />
      </div>
    );

  return (
    <>
      {" "}
      <Helmet>
        <title>Projects - MSES Timesheet</title>
      </Helmet>
      {dialBoxopen ? (
        <>
          <ProjectIndividualDetails />
        </>
      ) : (
        <div className="home-navbar">
          <div
            className={isNavBarOpen ? "project-navbar" : "project-navbar-close"}
          >
            <div
              className={
                isNavBarOpen
                  ? "project-name-container"
                  : "project-name-container-close"
              }
            >
              <h1 className="project-layout-name">
                {project.projectno}-{project.name}
              </h1>
              <button
                className="open-details-btn"
                onClick={onFrameButtonClick}
                title="Project Details"
              >
                <img
                  src="/view_btn.png"
                  style={{ height: "17px", marginRight: "7px" }}
                />
                View
              </button>
            </div>
            <div
              className={
                isNavBarOpen
                  ? "project-layout-parent"
                  : "project-layout-parent-close"
              }
            >
              <div>
                <Link
                  className={`layout-name ${
                    activeLink === "dashboard" ? "active" : ""
                  }`}
                  to={`/project/${projectId}/dashboard`}
                  onClick={() => handleLinkClick("dashboard")}
                >
                  Dashboard
                </Link>
              </div>
              <div>
                <Link
                  className={`layout-name ${
                    activeLink === "phase" ? "active" : ""
                  }`}
                  to={`/phases/${projectId}`}
                  onClick={() => handleLinkClick("phase")}
                >
                  Phase
                </Link>
              </div>
              <div>
                <Link
                  className={`layout-name ${
                    activeLink === "timesheet" ? "active" : ""
                  }`}
                  to={`/project/${projectId}/timesheets`}
                  onClick={() => handleLinkClick("timesheet")}
                >
                  Timesheet
                </Link>
              </div>
              <div>
                <Link
                  className={`layout-name ${
                    activeLink === "general" ? "active" : ""
                  }`}
                  to={`/project/${projectId}/timesheets/general`}
                  onClick={() => handleLinkClick("general")}
                >
                  General
                </Link>
              </div>
              <div>
                <Link
                  className={`layout-name ${
                    activeLink === "variations" ? "active" : ""
                  }`}
                  to={`/project/${projectId}/timesheets/variation`}
                  onClick={() => handleLinkClick("variations")}
                >
                  Variations
                </Link>
              </div>
              <div>
                <Link
                  className={`layout-name ${
                    activeLink === "issues" ? "active" : ""
                  }`}
                  to={`/project/${projectId}/timesheets/issue`}
                  onClick={() => handleLinkClick("issues")}
                >
                  Issues
                </Link>
              </div>
              <div>
                {/* <Link
                  className={`layout-name ${
                    activeLink === "report" ? "active" : ""
                  }`}
                  onClick={() => handleLinkClick("report")}
                >
                  Report
                </Link> */}
              </div>
              <div>
                <Link
                  className={`layout-name ${
                    activeLink === "users" ? "active" : ""
                  }`}
                  to={`/project/${projectId}/users`}
                  onClick={() => handleLinkClick("users")}
                >
                  Users
                </Link>
              </div>
              <div>
                <Link
                  className={`layout-name ${
                    activeLink === "pages" ? "active" : ""
                  }`}
                  onClick={() => handleLinkClick("pages")}
                >
                  Pages
                </Link>
              </div>
            </div>
            <hr className="horizontal-project-layout-line" />
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectLayout;
