import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/axiosConfig";
import { useAuth } from "../Authentication/AuthContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../TimeSheet/TimeSheetTable.css";
import { ProjectContext } from "../../App";
const AddTimeSheet = () => {
  const { dialBoxopen, addTimesheetOpen, setAddTimesheetOpen } =
    useContext(ProjectContext);
  const [timesheets, setTimesheets] = useState([]);
  const { userDetails } = useAuth();
  const userId = userDetails.id;
  const { projectId } = useParams();
  const [loading, setLoading] = useState(true);
  const [taskTemplate, setTaskTemplate] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [phases, setPhases] = useState([]);
  const [logCategory, setLogCategories] = useState([]);
  const [selectedTaskTemplate, setSelectedTaskTemplate] = useState("");
  const [user, setUser] = useState("");
  const [timeEntryMethod, setTimeEntryMethod] = useState("");
  const [durationHours, setDurationHours] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedFromTime, setSelectedFromTime] = useState(null);
  const [selectedToTime, setSelectedToTime] = useState(null);
  const [approvedHours, setApprovedHours] = useState(0);
  const [billableHours, setBillableHours] = useState(0);
  const [nonBillableHours, setNonBillableHours] = useState(0);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedPhase, setSelectedPhase] = useState("");
  const [selectedPhaseId, setSelectedPhaseId] = useState(null);
  const [selectedLogCategory, setSelectedLogCategory] = useState("");
  const [selectedTasks, setSelectedTasks] = useState("");
  const [isBillable, setIsBillable] = useState("");
  const [error, setError] = useState(null);
  const [dropdownVariationOptions, setDropdownVariationOptions] = useState([]);
  const [dropdownIssueOptions, setDropdownIssueOptions] = useState([]);
  const [selectedVariation, setSelectedVariation] = useState("");
  const [selectedIssue, setSelectedIssue] = useState("");
  const [usersId, setUsersId] = useState("");
  const userID = userDetails.id;

  const onClosePopUp = () => {
    setAddTimesheetOpen(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleFromTime = (timeFrom) => {
    setSelectedFromTime(timeFrom);
  };

  const handleToTime = (timeTo) => {
    setSelectedToTime(timeTo);
  };

  const handlFetchUserId = (e) => {
    setUsersId(e.target.value);
    setUser(e.target.value);
  };

  //update userId for user login
  useEffect(() => {
    if (userDetails.role !== "Administrator") {
      setUser(userDetails.id); // Set the current user's ID in state if not admin
    }
  }, [userDetails, setUser]);

  const handleTaskTemplateChange = (event) => {
    setSelectedTaskTemplate(event.target.value);
  };

  const handlelogcategoryChanger = (e) => {
    setSelectedLogCategory(e.target.value);
  };

  const handleRadioChange = (event) => {
    setIsBillable(event.target.value);
  };

  //fetch phase changes
  const handlePhaseChange = (event) => {
    const phaseId = event.target.value;
    setSelectedPhase(phaseId);
    const phase = selectedProject?.phases?.find(
      (p) => p.id === parseInt(phaseId)
    );
    if (phase) {
      setSelectedPhaseId(phase.id);
    } else {
      setSelectedPhaseId(null);
    }
    // Clear selected task template and tasks when phase changes
    setSelectedTaskTemplate("");
    setTasks([]);
  };
  //fetch users, category , tasktemplate
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [usersRes, logCategories] = await Promise.all([
          api.get(`/api/users`),
          api.get("/api/categories"),
        ]);
        setUsers(usersRes.data);

        setLogCategories(logCategories.data);

        if (selectedPhase) {
          const taskTemplatesRes = await api.get(
            `/api/phase/${selectedPhase}/taskTemplates`
          );
          setTaskTemplate(taskTemplatesRes.data);
        } else {
          setTaskTemplate([]); // Clear task templates if no phase is selected
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedPhase, projectId]); // Include selectedPhase as a dependency

  //fetch project details
  useEffect(() => {
    const fetchProject = async () => {
      const id = userDetails.role_name === "Administrator" ? usersId : userID;

      try {
        const endpoint = `/api/aprojects/${id}`;
        const res = await api.get(endpoint);
        setProjects(res.data);

        setLoading(false);
      } catch (error) {
        setError(error);
        console.log(error);
        setLoading(false);
      }
    };
    const id = userDetails.role_name === "Administrator" ? usersId : userID;
    if (id) {
      fetchProject();
    }
  }, [userDetails.role, userID, usersId]);

  //fetch timesheetData
  const fetchTimesheetData = async () => {
    try {
      const timestamp = new Date().getTime();
      const endpoint =
        userDetails.role === "Administrator"
          ? `/api/timesheets/projects/${projectId}`
          : `/api/timesheets/${projectId}/${userId}`;

      const timesheetRes = await api.get(endpoint);
      let timesheets = timesheetRes.data;

      // Step 3: Group timesheets by phase_id
      const groupedByPhase = timesheets.reduce((acc, timesheet) => {
        const phaseId = timesheet.phase_id;
        if (!acc[phaseId]) {
          acc[phaseId] = [];
        }
        acc[phaseId].push(timesheet);
        return acc;
      }, {});

      // Step 4: Fetch phase details for each phaseId
      const phaseIds = Object.keys(groupedByPhase);
      const phaseDetailsPromises = phaseIds.map((phaseId) =>
        api.get(`/api/phase/${phaseId}`)
      );
      const phaseDetailsResponses = await Promise.all(phaseDetailsPromises);
      const phaseDetailsMap = phaseDetailsResponses.reduce((acc, response) => {
        const phase = response.data;
        acc[phase.id] = phase;
        return acc;
      }, {});

      // Step 5: Create a set of unique user IDs for the approvers
      const approverIds = new Set(
        timesheets.map((timesheet) => timesheet.approved_by)
      );

      // Step 6: Fetch user details for all approvers
      const usersRes = await api.get(`/api/users`, {
        params: { ids: Array.from(approverIds) },
      });
      const users = usersRes.data.reduce((acc, user) => {
        acc[user.id] = user.name;
        return acc;
      }, {});

      let approvedMinutesTotal = 0;
      let billableMinutesTotal = 0;
      let nonBillableMinutesTotal = 0;

      // Step 7: Loop through filtered timesheets and calculate duration
      const adjustedDataByPhase = Object.entries(groupedByPhase).map(
        ([phaseId, phaseTimesheets]) => {
          const adjustedTimesheets = phaseTimesheets.map((timesheet) => {
            const date = new Date(timesheet.date);

            // Handle duration in HH:MM:SS format
            const [hours, minutes] = timesheet.duration.split(":").map(Number);
            const totalMinutes = (hours || 0) * 60 + (minutes || 0);

            if (timesheet.approvalstatus === "Approved") {
              approvedMinutesTotal += totalMinutes;
            }

            if (timesheet.billable) {
              billableMinutesTotal += totalMinutes;
            } else {
              nonBillableMinutesTotal += totalMinutes;
            }

            return {
              ...timesheet,
              date: date.toLocaleDateString(),
              approved_by:
                users[timesheet.approved_by] || "Waiting for approval",
              hours, // Updated field to display duration in "xx hours xx minutes" format
            };
          });

          return {
            phaseId,
            phaseDetails: phaseDetailsMap[phaseId] || {}, // Fallback to an empty object if phase details are missing
            timesheets: adjustedTimesheets,
          };
        }
      );

      // Convert total approved minutes to hours and minutes
      const approvedHoursFinal = Math.floor(approvedMinutesTotal / 60);
      const approvedMinutesFinal = approvedMinutesTotal % 60;

      // Convert total billable minutes to hours and minutes
      const billableHoursFinal = Math.floor(billableMinutesTotal / 60);
      const billableMinutesFinal = billableMinutesTotal % 60;

      // Convert total non-billable minutes to hours and minutes
      const nonBillableHoursFinal = Math.floor(nonBillableMinutesTotal / 60);
      const nonBillableMinutesFinal = nonBillableMinutesTotal % 60;

      // Set approved, billable, and non-billable hours and minutes
      setApprovedHours(`${approvedHoursFinal}h ${approvedMinutesFinal}m`);
      setBillableHours(`${billableHoursFinal}h ${billableMinutesFinal}m`);
      setNonBillableHours(
        `${nonBillableHoursFinal}h ${nonBillableMinutesFinal}m`
      );

      setTimesheets(adjustedDataByPhase);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching timesheets:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedTaskTemplate) {
      const fetchTasks = async () => {
        try {
          const res = await api.get(`/api/tasks/${selectedTaskTemplate}`);
          setTasks(res.data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchTasks();
    }
  }, [selectedTaskTemplate]);

  const handleProjectChange = (e) => {
    setSelectedProject(e.target.value); // Sets selected project
    setSelectedPhase(""); // Reset phase when project changes
  };

  //fetch phases based on project ID
  useEffect(() => {
    const fetchPhases = async () => {
      if (selectedProject) {
        try {
          const res = await api.get(`/api/project/${selectedProject}/phases`);
          setPhases(res.data); // Assuming res.data is the list of phases
        } catch (error) {
          console.error("Error fetching phases:", error);
        }
      }
    };
    fetchPhases();
  }, [selectedProject]);

  // general || variation || issue header
  let headingText;
  switch (selectedLogCategory) {
    case "1":
      headingText = "General";
      break;
    case "2":
      headingText = "Variations";
      break;
    case "4":
      headingText = "Issues";
      break;
    default:
      headingText = "Title";
  }

  //fetch variation and issue dropdown options
  useEffect(() => {
    let fetchData;

    switch (headingText) {
      case "Variations":
        fetchData = async () => {
          try {
            const res = await api.get(`/api/phase/${selectedPhase}/variations`);
            setDropdownVariationOptions(res.data);
          } catch (error) {
            console.error(error);
          }
        };
        break;
      case "Issues":
        fetchData = async () => {
          try {
            const res = await api.get(`/api/phase/${selectedPhase}/issues`);

            setDropdownIssueOptions(res.data);
          } catch (error) {
            console.error(error);
          }
        };
        break;
      default:
        setDropdownVariationOptions([]);
        setDropdownIssueOptions([]);
    }

    if (fetchData) {
      fetchData();
    }
  }, [headingText, selectedPhase]);
  //dropdown options for variation and issue
  const dropdownOptions =
    headingText === "Variations"
      ? dropdownVariationOptions
      : dropdownIssueOptions;
  const selectedOption =
    headingText === "Variations" ? selectedVariation : selectedIssue;

  //add new timesheet
  const handleAddTimesheet = async () => {
    if (
      !user ||
      !selectedDate ||
      (!selectedFromTime && timeEntryMethod === "from-to") ||
      (!selectedToTime && timeEntryMethod === "from-to") ||
      (!durationHours && timeEntryMethod === "duration") ||
      !selectedProject ||
      !selectedPhase ||
      !selectedTasks ||
      (selectedLogCategory !== "1" && !(selectedVariation || selectedIssue))
    ) {
      alert("Please fill in all required details.");
      return; // Stop the function execution if any details are missing
    }
    setAddTimesheetOpen(false);
    // Step 1: Fetch existing hours for the user on the selected date
    let existingHoursInMinutes = 0;
    try {
      const response = await api.get(
        `/api/timesheets/${user}/${selectedDate.toLocaleDateString("en-CA")}`
      );
      const userTimesheets = response.data;

      // Calculate the total duration in minutes from the user's existing timesheets on this date
      userTimesheets.forEach((timesheet) => {
        const [hours, minutes] = timesheet.duration.split(":").map(Number);
        existingHoursInMinutes += hours * 60 + minutes;
      });
      window.location.reload();
    } catch (error) {
      console.error("Error fetching existing timesheets:", error);
      return;
    }

    // Step 2: Calculate the duration of the new entry
    let currentEntryDurationInMinutes = 0;
    if (timeEntryMethod === "from-to") {
      const fromTimeParts = selectedFromTime
        .toLocaleTimeString("en-CA", { hour12: false })
        .split(":")
        .map(Number);
      const toTimeParts = selectedToTime
        .toLocaleTimeString("en-CA", { hour12: false })
        .split(":")
        .map(Number);

      const fromTimeInMinutes = fromTimeParts[0] * 60 + fromTimeParts[1];
      const toTimeInMinutes = toTimeParts[0] * 60 + toTimeParts[1];

      currentEntryDurationInMinutes = toTimeInMinutes - fromTimeInMinutes;
      if (currentEntryDurationInMinutes < 0) {
        currentEntryDurationInMinutes += 24 * 60; // Adjust for overnight shifts
      }
    } else if (timeEntryMethod === "duration") {
      const [hours, minutes] = durationHours.split(":").map(Number);
      currentEntryDurationInMinutes = hours * 60 + minutes;
    }

    // Step 3: Compare the total hours (existing + current entry) with the limit of 8 hours (480 minutes)
    const totalMinutesForDay =
      existingHoursInMinutes + currentEntryDurationInMinutes;
    const totalHoursForDay = totalMinutesForDay / 60;

    if (totalHoursForDay > 8) {
      alert(
        `You cannot log more than 8 hours in a day. Your current total would be ${totalHoursForDay.toFixed(
          2
        )} hours.`
      );
      return; // Stop function if the total exceeds 8 hours
    }

    // Step 4: Proceed to add the timesheet if the total hours are <= 8
    const title = tasks.find((task) => task.id === Number(selectedTasks));
    const titleName = title ? title.name : "";

    const adjustedTimesheet = {
      user_id: user,
      date: selectedDate.toLocaleDateString("en-CA"),
      project_id: selectedProject,
      phase_id: selectedPhase,
      log_category: selectedLogCategory,
      task_id: Number(selectedTasks),
      variation_id: selectedVariation || null,
      issue_id: selectedIssue || null,
      billable: isBillable === "Yes" ? "1" : "0",
      logtitle: titleName,
      duration: "", // Will be set below
      approvalstatus: "Pending",
    };

    if (timeEntryMethod === "from-to") {
      adjustedTimesheet.fromtime = selectedFromTime.toLocaleTimeString(
        "en-CA",
        { hour12: false }
      );
      adjustedTimesheet.totime = selectedToTime.toLocaleTimeString("en-CA", {
        hour12: false,
      });

      // Convert to hh:mm:ss format
      const hours = Math.floor(currentEntryDurationInMinutes / 60);
      const minutes = currentEntryDurationInMinutes % 60;
      const seconds = 0; // Seconds are set to 0 since we only have hours and minutes

      adjustedTimesheet.duration = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
    } else if (timeEntryMethod === "duration") {
      // Handle the duration input for duration method
      const [hours, minutes] = durationHours.split(":").map(Number);
      adjustedTimesheet.duration = `${String(hours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")}:00`; // Store in hh:mm:ss format
    }

    // Step 5: Send the timesheet to the backend
    try {
      const url = `/api/timesheets/${userId}`;

      const response = await api.post(url, adjustedTimesheet);
      const data = response.data;
      const newTimesheetWithNames = {
        ...data,
        duration: adjustedTimesheet.duration, // Store the formatted duration
      };

      // Update the local state with the new timesheet
      setTimesheets((prevTimesheets) => {
        const updatedTimesheets = prevTimesheets.map((phase) =>
          phase.phaseId === adjustedTimesheet.phase_id
            ? {
                ...phase,
                timesheets: [...phase.timesheets, newTimesheetWithNames],
              }
            : phase
        );
        return updatedTimesheets;
      });

      alert("New Timesheet added");
      setAddTimesheetOpen(false);
      document.body.classList.remove("no-scroll");
    } catch (error) {
      console.error("Error adding timesheet:", error);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  //set variation or issue details
  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;

    if (headingText === "Variations") {
      setSelectedVariation(selectedValue);
    } else if (headingText === "Issues") {
      setSelectedIssue(selectedValue);
    }
  };

  return (
    <div>
      {addTimesheetOpen && (
        <div
          className={`timesheet-overlay-add-time-log ${
            dialBoxopen ? "open" : "close"
          }`}
          style={{ zIndex: "10" }}
        >
          <div className="new-time-log">
            <h3 style={{ backgroundColor: "transparent", marginLeft: "19px" }}>
              Add Time Log
            </h3>
          </div>
          <div className="scrollable-t-content">
            <h3 className="user-name">User</h3>
            {userDetails.role_name === "Administrator" ? (
              <select
                onChange={handlFetchUserId}
                value={usersId}
                className="timesheet-input"
              >
                <option value="" disabled>
                  Select User
                </option>
                {users
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
              </select>
            ) : (
              <input
                type="text"
                value={userDetails.name} // Automatically select the logged user's name
                className="timesheet-input"
                readOnly
              />
            )}
            <h3 className="user-name">Date</h3>
            <DatePicker
              placeholderText="Select Date"
              className="timesheet-d-input"
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
            />
            <h3 className="user-name">Time Entry Method</h3>
            <select
              className="timesheet-input"
              value={timeEntryMethod}
              onChange={(e) => setTimeEntryMethod(e.target.value)}
            >
              <option value="" disabled>
                --Select Time Entry Method--
              </option>
              <option value="from-to">From-To</option>
              <option value="duration">Duration in Hours</option>
            </select>
            {timeEntryMethod === "from-to" && (
              <div className="from-to-container">
                {" "}
                <div className="from-time-container">
                  <h3 className="from">From Time</h3>
                  <DatePicker
                    className="timesheet-d-input"
                    selected={selectedFromTime}
                    onChange={handleFromTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Select From Time"
                  />{" "}
                </div>{" "}
                <div className="to-time-container">
                  <h3 className="to">To Time</h3>
                  <DatePicker
                    className="timesheet-d-input"
                    selected={selectedToTime}
                    onChange={handleToTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Select To Time"
                  />{" "}
                </div>{" "}
              </div>
            )}
            {timeEntryMethod === "duration" && (
              <div className="duration-container">
                <h3 className="user-name">Duration (hh:mm)</h3>
                <input
                  type="text"
                  className="timesheet-d-input"
                  value={durationHours}
                  onChange={(e) => setDurationHours(e.target.value)}
                  placeholder="Enter Duration in hh:mm"
                  pattern="\d{1,2}:\d{2}" // Regex pattern to validate hh:mm format
                  title="Enter duration in hh:mm format (e.g., 03:20 or 7:45)"
                />
              </div>
            )}
            <h3 className="user-name">Project</h3>
            <select
              className="timesheet-input"
              onChange={handleProjectChange}
              value={selectedProject}
            >
              <option>Select Project</option>
              {projects.length > 0 ? (
                projects
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))
              ) : (
                <option disabled>No projects available for this user</option>
              )}
            </select>
            {selectedProject && (
              <>
                <h3 className="user-name">Phase</h3>
                <select
                  className="timesheet-input"
                  onChange={handlePhaseChange}
                  value={selectedPhase}
                >
                  <option value="" disabled>
                    --Select Phase--
                  </option>
                  {phases?.phases?.length > 0 ? (
                    phases.phases.map((phase) => (
                      <option key={phase.id} value={phase.id}>
                        {phase.title}
                      </option>
                    ))
                  ) : (
                    <option disabled>No Phases Available</option>
                  )}
                </select>
              </>
            )}
            <h3 className="user-name">Log Category</h3>
            <select
              className="timesheet-input"
              onChange={handlelogcategoryChanger}
              value={selectedLogCategory}
            >
              <option value="" disabled>
                --Select Category--
              </option>
              {logCategory.map((log) => (
                <option key={log.id} value={log.id}>
                  {log.name}
                </option>
              ))}
            </select>
            {headingText !== "General" && dropdownOptions.length !== 0 && (
              <div className="Title-container">
                <h3 className="user-name">{headingText}</h3>
                <select
                  onChange={handleDropdownChange}
                  value={selectedOption}
                  className="timesheet-input"
                >
                  <option value="">--Select {headingText}--</option>
                  {dropdownOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <h3 className="user-name">Task Template</h3>
            <select
              className="timesheet-input"
              onChange={handleTaskTemplateChange}
              value={selectedTaskTemplate}
            >
              <option value="" disabled>
                --Select Task Template--
              </option>
              {taskTemplate.map((template) => (
                <option key={template.id} value={template.id}>
                  {template.name}
                </option>
              ))}
            </select>
            <h3 className="user-name">Task</h3>
            <select
              onChange={(e) => setSelectedTasks(e.target.value)}
              value={selectedTasks}
              className="timesheet-input"
            >
              <option value="" disabled>
                --Select Task--
              </option>
              {tasks.map((task) => (
                <option key={task.id} value={task.id}>
                  {task.name}
                </option>
              ))}
            </select>
            <h3 className="bill">Billable?</h3>
            <div className="radio-container">
              <label htmlFor="yes" className="radio-label">
                Yes
              </label>
              <input
                type="radio"
                id="yes"
                name="isBillable-input"
                value="Yes"
                checked={isBillable === "Yes"}
                onChange={handleRadioChange}
              />
              <label htmlFor="no" className="radio-label">
                No
              </label>
              <input
                type="radio"
                id="no"
                name="isBillable-input"
                value="No"
                checked={isBillable === "No"}
                onChange={handleRadioChange}
              />
            </div>
          </div>
          <div className="btn-container-add-timesheet">
            <button className="add-btn" onClick={handleAddTimesheet}>
              Add
            </button>
            <button onClick={onClosePopUp} className="cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddTimeSheet;
