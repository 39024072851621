import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRolePermissions } from "../components/Settings/RoleContext.js";
import api from "../api/axiosConfig";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import ".././styles/projects/Projects.css";
import { useAuth } from "./Authentication/AuthContext";
import { Helmet } from "react-helmet";

const GroupList = () => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const { userDetails } = useAuth();
  const { hasPermission } = useRolePermissions();
  const userRole = userDetails.role;
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [activeGroupLink, setGroupActiveLink] = useState(() => {
    return localStorage.getItem("activegroups") || "overallgroup";
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [newUserName, setNewUserName] = useState("");
  const [newGroup, setNewGroup] = useState({
    name: "",
    code: "",
    description: "",
    manager: "",
  });

  const handleGroupLinkClick = (link) => {
    setGroupActiveLink(link); // Update the active link
    localStorage.setItem("activegroups", link);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint =
          userDetails.role_name === "Administrator"
            ? `/api/groups`
            : `/api/groups/${userDetails.id}`;
        const adminRes = await api.get(`/api/users/admin`);
        setUsers(adminRes.data);
        const res = await api.get(endpoint);
        setGroups(res.data);
      } catch (error) {
        setError(error);
        console.error("Error fetching groups:", error.message || error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userRole, userDetails.id]);

  // Handle closing the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setNewUserName(""); // Reset input field
  };
  const onHandleAddGroup = () => {
    setOpenAddDialog(true);
    document.body.classList.add("no-scroll");
  };
  // Handle adding a user
  const handleAddUser = async () => {
    try {
      await api.post(`/api/groups/${selectedGroupId}/add-user`, {
        name: newUserName,
      });
      // Optionally refresh the group data or show success message
      handleCloseDialog();
    } catch (error) {
      console.error("Error adding user:", error.message || error);
    }
  };

  //add new group
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGroup((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddNewgroup = async () => {
    try {
      const response = await api.post("/api/newgroup", newGroup);
      if (response.status === 201) {
        const addedCustomer = response.data;
        setGroups((prevGroups) => [...prevGroups, addedCustomer]);
        alert("Group added successfully!");
        setRefreshTrigger(!refreshTrigger);
        setNewGroup({
          name: "",
          code: "",
          description: "",
          manager: "",
        });
        setOpenAddDialog(false);
      } else {
        alert("Failed to add group. Please try again.");
      }
    } catch (error) {
      console.error("Error adding group:", error); // Log the error
      alert(`Failed to add group: ${error.response?.data || error.message}`);
    }
  };
  if (loading) return <CircularProgress />;
  if (error) return <p>Error loading groups</p>;

  return (
    <div className="group-main-container">
      <Helmet>
        <title>Groups - MSES Timesheet</title>
      </Helmet>
      <div
        className="project-nav-bar-details"
        style={{ top: "0px", left: "-92px", position: "relative" }}
      >
        {/* <h3>Groups</h3> */}
        <div className="project-tab-details">
          <Link
            className={`proj-links ${
              activeGroupLink === "activegroup" ? "active" : ""
            }`}
            onClick={() => handleGroupLinkClick("activegroup")}
          >
            Active Group
          </Link>
          <Link
            className={`proj-links ${
              activeGroupLink === "overallgroup" ? "active" : ""
            }`}
            onClick={() => handleGroupLinkClick("overallgroup")}
          >
            Overall Group
          </Link>
        </div>
        <hr className="proj-line" />
      </div>
      {hasPermission("groups", "create") && (
        <button
          style={{ top: "-136px" }}
          className="add-proj-heading"
          onClick={onHandleAddGroup}
          title="New Group"
        >
          New Group
        </button>
      )}
      <div
        className="project-container"
        style={{
          top: "-120px",
          backgroundColor: "transparent",
          left: "0px",
          position: "relative",
        }}
      >
        <table
          className="project-content-table"
          style={{ width: "100%", left: "0px" }}
        >
          <thead>
            <tr>
              <th style={{ width: "2px" }}>S.No</th>
              <th>Group Code</th>
              <th>Group Name</th>
              <th>Group Manager</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group, index) => (
              <tr key={group.id}>
                <td style={{ textAlign: "center" }}>{index + 1}</td>
                <td>
                  {hasPermission("groups", "update") ? (
                    <Link to={`/group/${group.id}`} className="group-name">
                      {group.code}
                    </Link>
                  ) : (
                    <span>{group.code}</span>
                  )}
                </td>
                <td>{group.name}</td>
                <td>{group.manager_user_name}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Add User Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Add New User to Group</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="User Name"
              type="text"
              fullWidth
              value={newUserName}
              onChange={(e) => setNewUserName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={handleAddUser} color="primary">
              Add User
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {openAddDialog && (
        <div className="add-project-dialog add-group-dial">
          <div className="add-project-content" style={{ height: "96.61%" }}>
            <div
              className="add-project-user-header"
              style={{ marginLeft: "0px" }}
            >
              <h3
                className="add-project-user-heading"
                style={{ marginLeft: "8px", fontWeight: "550px" }}
              >
                Add New Group
              </h3>
            </div>
            <div className="customer-form">
              <label className="customer-label">Group Name:</label>
              <input
                type="text"
                name="name"
                className="customer-input"
                placeholder="Enter Group name"
                value={newGroup.name}
                onChange={handleInputChange}
              />
              <br />
              <label className="customer-label">Group Code:</label>
              <input
                type="text"
                name="code"
                placeholder="Enter Group Code"
                className="customer-input"
                value={newGroup.code}
                onChange={handleInputChange}
              />
              <br />
              <label className="customer-label">Description:</label>
              <input
                type="text"
                name="description"
                placeholder="Enter Description"
                className="customer-input"
                value={newGroup.description}
                onChange={handleInputChange}
              />
              <br />
              <label className="customer-label">Manager:</label>
              <select
                name="manager"
                className="customer-input"
                value={newGroup.manager}
                onChange={handleInputChange}
              >
                <option value="" disabled>
                  Select Manager
                </option>
                {users
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
              </select>
              <br />
            </div>
            <div className="customer-btn-container">
              <button
                style={{ marginLeft: "24px" }}
                className="add-btn"
                onClick={handleAddNewgroup}
              >
                Add
              </button>
              <button
                className="cancel-btn"
                onClick={() => {
                  setOpenAddDialog(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GroupList;
