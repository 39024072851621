import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import "../../styles/projects/ProjectDetails.css";
import { ProjectContext } from "../../App";
import { useRolePermissions } from "../Settings/RoleContext";
import api from "../../api/axiosConfig";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { FaChevronDown } from "react-icons/fa";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Helmet } from "react-helmet";

const fetchData = async (url, setData, setError) => {
  try {
    const response = await api.get(url); // Axios automatically adds the baseURL and headers
    setData(response.data); // Axios automatically parses JSON
  } catch (error) {
    console.error("Error fetching data:", error);
    setError(error); // Handle error
  }
};

const addPhase = async (projectId, phase, setError) => {
  try {
    const response = await api.post(`/api/project/${projectId}/phases`, phase);
    return response.data; // Axios automatically parses the JSON response
  } catch (error) {
    setError(error);
  }
};

const deletePhase = async (phaseIds, setError) => {
  try {
    const url = `/api/phases`; // No phaseId in the URL, we're using the request body
    await api.delete(url, { data: { phaseIds } }); // Send phaseIds in the request body
  } catch (error) {
    setError(error);
  }
};

const ProjectDetails = () => {
  const { hasPermission } = useRolePermissions();
  const { projectId } = useParams();
  const [project, setProject] = useState({
    id: null,
    name: "",
    projectno: "",
    customer_name: "",
    phases: [],
  });
  const [loading, setLoading] = useState(true);
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const [error, setError] = useState(null);
  const [claimPercent, setClaimPercent] = useState(0);
  const [phaseDialogOpen, setPhaseDialogOpen] = useState(false);
  const [phaseEditDialogOpen, setPhaseEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedPhaseId, setSelectedPhaseId] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [totalPlannedHours, setTotalPlannedHours] = useState("");
  const [editingPhase, setEditingPhase] = useState(null); // Holds the phase being edited
  const [formValues, setFormValues] = useState({
    phaseno: "",
    title: "",
    plannedhours: "",
  });
  const [newPhase, setNewPhase] = useState({
    title: "",
    plannedhours: 0,
    phaseno: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUserssPerPage] = useState(13);
  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setUserssPerPage(9); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setUserssPerPage(13); // Medium screens (e.g., tablet)
    } else {
      setUserssPerPage(10); // Large screens (e.g., desktop)
    }
  };
  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);

  // useEffect that calculates claimPercent
  useEffect(() => {
    // Fetch data and calculate claim percentage
    fetchData(
      `/api/project/${projectId}/phases`,
      (data) => {
        setProject(data);

        // Ensure phases data is available
        if (data.phases) {
          // Calculate total planned hours
          const totalPlannedHours = data.phases.reduce((total, phase) => {
            return total + (phase.plannedhours || 0);
          }, 0);

          // Calculate total claimed phases
          const totalClaimedPhases = data.phases.reduce((claimed, phase) => {
            return phase.invoice_status === "Claimed" ? claimed + 1 : claimed;
          }, 0);
          // Calculate claim percentage
          const percentage = (totalClaimedPhases / data.phases.length) * 100;
          // Set the state values
          setTotalPlannedHours(totalPlannedHours);
          setClaimPercent(percentage); // Set claimPercent state
        } else {
        }
      },
      setError
    ).finally(() => setLoading(false));
  }, [projectId]);

  const handleOpenPhaseDialog = () => {
    const defaultPhaseNo = `${project.projectno}-phase-${
      project.phases.length + 1
    }`;
    setNewPhase({
      project_id: project.id,
      title: defaultPhaseNo,
      plannedhours: 0,
      phaseno: defaultPhaseNo,
    });
    setPhaseDialogOpen(true);
    document.body.classList.add("no-scroll");
  };

  const handleClosePhaseDialog = () => {
    setPhaseDialogOpen(false);
    document.body.classList.remove("no-scroll");
  };

  const handleAddPhase = async () => {
    const newPhaseData = await addPhase(projectId, newPhase, setError);

    if (newPhaseData) {
      setProject((prev) => {
        // Add the new phase to the project's phases
        const updatedPhases = [...prev.phases, newPhaseData];

        // Calculate the updated total planned hours
        const updatedTotalPlannedHours = updatedPhases.reduce(
          (total, phase) => {
            return total + (phase.plannedhours || 0); // Add planned hours, default to 0 if undefined
          },
          0
        );

        // Update both the phases and the total planned hours in state
        setTotalPlannedHours(updatedTotalPlannedHours);

        return {
          ...prev,
          phases: updatedPhases,
        };
      });
    }

    document.body.classList.add("no-scroll");
    setPhaseDialogOpen(false);
  };

  const handleEditClick = (phase) => {
    setEditingPhase(phase.id);

    setFormValues({
      phaseno: phase.phaseno,
      title: phase.title,
      plannedhours: phase.plannedhours,
    });
    setPhaseEditDialogOpen(true);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSaveClick = async () => {
    const { phaseno, title, plannedhours } = formValues;
    const phaseId = editingPhase;
    try {
      const response = await api.put(`/api/phase/${phaseId}`, {
        phaseno,
        title,
        plannedhours,
      });
      // Axios responses don't have .ok, they throw errors automatically
      const updatedPhase = response.data;

      const updatedPhases = project.phases.map((phase) =>
        phase.id === phaseId ? updatedPhase : phase
      );
      setProject((prevProject) => ({
        ...prevProject,
        phases: updatedPhases,
      }));
      setPhaseEditDialogOpen(false);
    } catch (error) {
      // Axios errors are inside error.response
      let errorMessage = "Failed to update phase";
      if (error.response) {
        errorMessage = error.response.data.message || errorMessage;
      }
      console.error(errorMessage);
      console.log(errorMessage);
    }
  };

  const handleDeletePhaseClick = (e, phaseId) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add phaseId to the selectedPhaseId array
      setSelectedPhaseId((prev) => [...prev, phaseId]);
    } else {
      // Remove phaseId from the selectedPhaseId array
      setSelectedPhaseId((prev) => prev.filter((id) => id !== phaseId));
    }

    document.body.classList.add("no-scroll");
  };
  //delete phase
  const handleDeleteConfirm = async () => {
    if (isCheckboxChecked) {
      try {
        // Call the deletePhase function with the selected phase IDs
        await deletePhase(selectedPhaseId, setError);

        // Update the state after deletion
        setProject((prev) => {
          // Filter out the phases whose IDs are in the selectedPhaseId array
          const updatedPhases = prev.phases.filter(
            (phase) => !selectedPhaseId.includes(phase.id) // Remove phases whose IDs are in selectedPhaseId
          );

          // Recalculate the total planned hours
          const updatedTotalPlannedHours = updatedPhases.reduce(
            (total, phase) => total + (phase.plannedhours || 0), // Add planned hours, default to 0 if undefined
            0
          );

          // Update the project state with the new phases and total planned hours
          setTotalPlannedHours(updatedTotalPlannedHours);

          return {
            ...prev,
            phases: updatedPhases, // Update phases after deletion
          };
        });

        // Close the delete dialog and reset UI states
        setDeleteDialogOpen(false);
        document.body.classList.remove("no-scroll");
        setIsCheckboxChecked(false); // Reset the checkbox state
        setSelectedPhaseId([]); // Clear the selected phases after deletion
      } catch (error) {
        console.error("Failed to delete phase:", error); // Log the error for debugging
        setError(error); // Display the error message to the user
      }
    } else {
      alert("Please check the checkbox to confirm deletion."); // Alert if no checkbox is checked
    }
  };

  //close dial and remove no-scroll
  const handleCloseDial = () => {
    setIsCheckboxChecked(false);
    setDeleteDialogOpen(false);
    setSelectedPhaseId([]);
    document.body.classList.remove("no-scroll");
  };

  const updateClaimStatus = async (phaseId, newStatus, userId) => {
    try {
      await api.put(`/api/phase/${phaseId}/${userId}/approval`, {
        status: newStatus,
      });
      // Call fetchData to refresh the data
      fetchData(
        `/api/project/${projectId}/phases`,
        (data) => {
          setProject(data);

          // Calculate total planned hours
          const totalPlannedHours = data.phases.reduce((total, phase) => {
            return total + (phase.plannedhours || 0);
          }, 0);

          setTotalPlannedHours(totalPlannedHours);
        },
        setError
      ).finally(() => setLoading(false));
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };
  const totalPages = Math.ceil(project.phases.length / usersPerPage);

  // Get current projects to display
  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  // const currentUsers = customers.slice(indexOfFirstUsers, indexOfLastUsers);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };
  if (loading) return <CircularProgress />;
  if (error)
    return (
      <p color="error" className="error-message">
        Error: {error.message}
      </p>
    );

  return (
    <>
      <Helmet>
        <title>Phases - MSES Timesheet</title>
      </Helmet>
      {!dialBoxopen && (
        <div
          className={
            isNavBarOpen
              ? "project-phase-details"
              : "project-phase-details-close"
          }
        >
          <div style={{ marginLeft: "103px" }}>
            <div>
              {hasPermission("projects", "delete") &&
                selectedPhaseId.length > 0 && (
                  <button
                    className={
                      isNavBarOpen
                        ? "add-phase-btn delete-phase-btn"
                        : "add-phase-btn-close delete-phase-btn"
                    }
                    onClick={() => setDeleteDialogOpen(true)}
                    title="Delete Phase"
                  >
                    Delete
                  </button>
                )}
              {hasPermission("projects", "update") && (
                <button
                  onClick={handleOpenPhaseDialog}
                  className={
                    isNavBarOpen ? "add-phase-btn" : "add-phase-btn-close"
                  }
                  title="Add Phase"
                >
                  New Phase
                </button>
              )}
              {project.phases.length === 0 ? (
                <div className="phase-image-container">
                  <img className="phase-image" src="/AppLayout/Phase.png" />
                  <p className="phase-message">
                    No phases available for this project
                  </p>
                </div>
              ) : (
                <>
                  <div className="table-container">
                    <table className="phase-table">
                      <thead>
                        <tr>
                          {hasPermission("projects", "delete") && (
                            <th style={{ width: "2px", textAlign: "center" }}>
                              <FaChevronDown />
                            </th>
                          )}
                          <th
                            style={{
                              color: "#616161",
                              fontWeight: "400",
                              color: "black",
                            }}
                          >
                            Phase No
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              fontWeight: "400",
                              color: "black",
                            }}
                          >
                            Phase Title
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              fontWeight: "400",
                              color: "black",
                            }}
                          >
                            Planned Hours
                          </th>
                          <th
                            style={{
                              color: "#616161",
                              fontWeight: "400",
                              color: "black",
                            }}
                          >
                            Invoice Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {project.phases.map((phase, index) => (
                          <tr key={phase.id}>
                            {hasPermission("projects", "delete") && (
                              <td>
                                <input
                                  type="checkbox"
                                  name={phase.name}
                                  checked={selectedPhaseId.includes(phase.id)}
                                  onChange={(e) =>
                                    handleDeletePhaseClick(e, phase.id)
                                  }
                                />
                              </td>
                            )}
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Link
                                  to={`/project/${projectId}/phase/${phase.id}`}
                                  className="phase-tile"
                                >
                                  {phase.phaseno}
                                </Link>
                                {hasPermission("projects", "update") && (
                                  <button
                                    onClick={() => {
                                      handleEditClick(phase);
                                    }}
                                    className="edit-icon-wrapper"
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      marginRight: "50px",
                                    }}
                                    title="Edit Phase"
                                  >
                                    <EditIcon
                                      style={{
                                        color: "blue",
                                        backgroundColor: "transparent",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </button>
                                )}
                              </div>
                            </td>
                            <td>{phase.title}</td>
                            <td>{phase.plannedhours}</td>
                            <td
                              style={{
                                backgroundColor:
                                  phase.invoice_status === "Claimed"
                                    ? "#a3d6a7"
                                    : "#d2b48c",
                                color: "white",
                                width: "140px",
                                textAlign: "center",
                                fontWeight: "550",
                              }}
                            >
                              {hasPermission("projects", "create") ? (
                                <select
                                  className="status-dropdown"
                                  value={phase.invoice_status}
                                  onChange={(e) =>
                                    updateClaimStatus(phase.id, e.target.value)
                                  }
                                  style={{
                                    border: "none",
                                    backgroundColor:
                                      phase.invoice_status === "Claimed"
                                        ? "#a3d6a7"
                                        : "#d2b48c",
                                    color: "white",
                                    paddingLeft: "38px",
                                    paddingRight: "28px",
                                    fontWeight: "550",
                                  }}
                                >
                                  <option
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                      fontWeight: "550",
                                    }}
                                    value="Unclaimed"
                                  >
                                    Unclaimed
                                  </option>
                                  <option
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                      fontWeight: "550",
                                    }}
                                    value="Claimed"
                                  >
                                    Claimed
                                  </option>
                                </select>
                              ) : (
                                phase.invoice_status
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="phase-hours-summary">
                    <h3 className="phase-details-text">
                      Phase Planned Hours Summary : {totalPlannedHours}
                    </h3>
                    <div className="phase-pagination">
                      <h3>Total : {project.phases.length}</h3>
                      <button
                        onClick={handleFirstPage}
                        disabled={currentPage === 1}
                        className="phase-arrow-btn"
                      >
                        <MdKeyboardDoubleArrowLeft />
                      </button>
                      <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="phase-arrow-btn"
                      >
                        {/* */}
                        <MdKeyboardArrowLeft />
                      </button>
                      <span
                        style={{
                          marginTop: "16px",
                          textWrap: "nowrap",
                        }}
                      >
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="phase-arrow-btn"
                      >
                        <MdKeyboardArrowRight />
                      </button>
                      <button
                        onClick={handleLastPage}
                        disabled={currentPage === totalPages}
                        className="phase-arrow-btn"
                      >
                        <MdKeyboardDoubleArrowRight />
                      </button>
                    </div>
                  </div>
                </>
              )}
              {/* Add Phase Dialog */}
              {phaseDialogOpen && (
                <div className="add-phase-dial">
                  <div className="dial-header">
                    <h3
                      className="add-phase-heading"
                      style={{ marginLeft: "25px", fontWeight: "bold" }}
                    >
                      New Phase
                    </h3>
                  </div>

                  <div className="phase-content-container">
                    <h3 className="add-phase-heading">Phase Name</h3>
                    <input
                      label="Phase Title"
                      className="phase-input"
                      value={newPhase.title}
                      type="text"
                      onChange={(e) =>
                        setNewPhase({ ...newPhase, title: e.target.value })
                      }
                    />
                    <h3 className="add-phase-heading">Phase Title</h3>
                    <input
                      label="Phase No"
                      className="phase-input"
                      value={newPhase.phaseno}
                      type="text"
                      onChange={(e) =>
                        setNewPhase({ ...newPhase, phaseno: e.target.value })
                      }
                    />
                    <h3 className="add-phase-heading">Planned Hours</h3>
                    <input
                      className="phase-input"
                      label="Planned Hours"
                      value={newPhase.plannedhours}
                      type="number"
                      onChange={(e) =>
                        setNewPhase({
                          ...newPhase,
                          plannedhours: parseInt(e.target.value, 10),
                        })
                      }
                    />
                  </div>
                  <div className="edit-phase-btn-container">
                    <button
                      onClick={handleAddPhase}
                      style={{ marginLeft: "26px", width: "50px" }}
                      className="add-phase-dial-btn"
                    >
                      Add
                    </button>
                    <button
                      onClick={handleClosePhaseDialog}
                      className="cancel-btn"
                      style={{ cursor: "pointer" }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {deleteDialogOpen && (
        <div className={`delete-phase-dial ${dialBoxopen ? "open" : "close"} `}>
          {" "}
          <div className="dial-d-header">
            <h3 style={{ backgroundColor: "transparent", marginLeft: "15px" }}>
              Delete Phase
            </h3>
          </div>
          <div style={{ backgroundColor: "white" }}>
            <p style={{ backgroundColor: "white" }}>
              Are you sure you want to delete this phase? This action cannot be
              undone.
            </p>
            <input
              type="checkbox"
              id="delete-phase"
              checked={isCheckboxChecked}
              onChange={() => setIsCheckboxChecked((prev) => !prev)}
            />
            <label htmlFor="delete-phase" style={{ backgroundColor: "white" }}>
              I understand this action cannot be undone.
            </label>
          </div>
          <div className="delete-dial-btn-con">
            <button
              onClick={handleDeleteConfirm}
              color="secondary"
              variant="contained"
              disabled={!isCheckboxChecked}
              className="add-btn"
              style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
            >
              Delete
            </button>
            <button onClick={handleCloseDial} className="cancel-btn">
              Cancel
            </button>
          </div>
        </div>
      )}
      {phaseEditDialogOpen && editingPhase && (
        <div className="edit-phase-details-dial">
          <div className="dial-header">
            <h3
              className="edit-phase-label"
              style={{ backgroundColor: "transparent" }}
            >
              Edit Phase Details
            </h3>
          </div>
          <div>
            <h3 className="edit-phase-label">Phase No</h3>
            <input
              type="text"
              className="phase-edit-input"
              name="phaseno"
              value={formValues.phaseno}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <h3 className="edit-phase-label">Phase Title</h3>
            <input
              type="text"
              className="phase-edit-input"
              name="title"
              value={formValues.title}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <h3 className="edit-phase-label">Planned Hours</h3>
            <input
              type="number"
              className="phase-edit-input"
              name="plannedhours"
              value={formValues.plannedhours}
              onChange={handleInputChange}
            />
          </div>
          <div className="edit-phase-btn-container">
            <button
              className="add-btn"
              style={{ marginLeft: "25px" }}
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              className="cancel-btn"
              style={{ marginLeft: "3px" }}
              onClick={() => setPhaseEditDialogOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectDetails;
