import React, { useState, useEffect } from "react";
import api from "../../api/axiosConfig";
import "../../styles/Settings/Settings.css";

const EmailSettings = () => {
  const [emailDetails, setEmailDetails] = useState({
    service: "gmail", // Default service
    user: "",
    password: "",
    host: "",
    port: "",
    secure: false,
    // testEmailRecipient: '',
  });

  useEffect(() => {
    // Fetch existing email settings from the database on component mount
    const fetchEmailSettings = async () => {
      try {
        const response = await api.get("/api/email-settings");
        const settings = response.data;
        setEmailDetails({ ...emailDetails, ...settings });
      } catch (error) {
        console.error("Error fetching email settings:", error);
      }
    };

    fetchEmailSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEmailDetails({
      ...emailDetails,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(emailDetails);
      await api.post("/api/email-settings", emailDetails);
      alert("Email settings saved successfully!");
    } catch (error) {
      console.error("Error saving email settings:", error);
      alert("Error saving email settings. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="email-settings-form">
      <h3>SMTP Settings</h3>
      <h3 className="email-service-header">Email Service:</h3>
      <select
        className="email-dropdown"
        name="service"
        value={emailDetails.service}
        onChange={handleChange}
      >
        <option value="gmail">Gmail</option>
        <option value="office365">Office 365</option>
        <option value="custom">Custom SMTP</option>
      </select>
      <h3 className="email-service-header">Email :</h3>
      <input
        type="email"
        name="user"
        placeholder="Your Email"
        value={emailDetails.user}
        onChange={handleChange}
        required
        className="email-input"
      />
      <h3 className="email-service-header">Password :</h3>
      <input
        type="password"
        name="password"
        placeholder="Password"
        value={emailDetails.password}
        onChange={handleChange}
        required
        className="email-input"
      />
      <h3 className="email-service-header">SMTP Host :</h3>
      <input
        type="text"
        name="host"
        placeholder="SMTP Host"
        value={emailDetails.host}
        onChange={handleChange}
        required
        className="email-input"
      />
      <h3 className="email-service-header">Port :</h3>
      <input
        type="number"
        name="port"
        placeholder="SMTP Port"
        value={emailDetails.port}
        onChange={handleChange}
        required
        className="email-input"
      />
      <h3>Use Secure (SSL/TLS):</h3>
      <input
        type="checkbox"
        name="secure"
        checked={emailDetails.secure}
        onChange={handleChange}
        className="email-checkbox"
      />

      <button
        type="submit"
        className="add-btn"
        style={{ width: "110px", textWrap: "nowrap", marginLeft: "156px" }}
      >
        Save Settings
      </button>
    </form>
  );
};

export default EmailSettings;
