import React, { useEffect, useState, useContext } from "react";
import { format } from "date-fns";
import { useAuth } from "../Authentication/AuthContext";
import { useParams } from "react-router-dom";
import api from "../../api/axiosConfig";
import { MdNumbers } from "react-icons/md";
import { utils, writeFile } from "xlsx";
import { IoCalendarOutline } from "react-icons/io5";
import { MdOutlineCategory } from "react-icons/md";
import { GrStatusGood } from "react-icons/gr";
import { MdOutlineCreateNewFolder } from "react-icons/md";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { IoTimerOutline } from "react-icons/io5";
import { GrUserExpert } from "react-icons/gr";
import { MdCreditScore } from "react-icons/md";
import { CgRename } from "react-icons/cg";
import { BiTask } from "react-icons/bi";
import { SiTyper } from "react-icons/si";
import { MdOutlineSubtitles } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { ProjectContext } from "../../App";
import FilterTimesheetOverview from "./FilterTimesheetOverview.js";
import "./TimeSheetTable.css";
import { Helmet } from "react-helmet";

const TimeSheetTable = () => {
  const { dialBoxopen, isNavBarOpen } = useContext(ProjectContext);
  const [timesheets, setTimesheets] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc"); // Default sort order for date grouping
  const [groupBy, setGroupBy] = useState("user"); // Global Group By state
  const { projectId } = useParams();
  const { userDetails } = useAuth();
  const userId = userDetails.id;
  const [filters, setFilters] = useState({
    user: "",
    userFilterType: "contains", // exact or contains
    logTitle: "",
    logTitleFilterType: "contains", // exact or contains
    logCategory: "",
    logCategoryFilterType: "exact", // exact or contains
    date: "",
    fromDate: "",
    toDate: "",
    dateFilterType: "specified", // specified or range
    billable: "",
    approvalstatus: "",
  });

  const formatTime = (duration) => {
    const [hours, minutes] = duration.split(":"); // Split the duration string into hours and minutes
    return `${hours}:${minutes}`; // Return only hours and minutes
  };
  const fetchTimesheets = async (currentFilters = filters) => {
    try {
      setLoading(true);
      const endpoint =
        userDetails.role_name === "Administrator"
          ? `/api/timesheets`
          : `/api/timesheets/${userId}`;

      const response = await api.get(endpoint);
      let timesheets = response.data;
      let ftimesheets = timesheets;
      // Apply user filter (exact or contains)
      if (currentFilters.user && currentFilters.user.value.length > 0) {
        const userFilterCondition = currentFilters.user.condition || "not";
        const userFilterValue = currentFilters.user.value; // No `toLowerCase` here, because we need to handle both string and array
        ftimesheets = ftimesheets.filter((ts) => {
          const user = ts.user_name ? ts.user_name.toLowerCase() : "";

          if (Array.isArray(userFilterValue)) {
            // If the filter value is an array
            if (userFilterCondition === "is") {
              // Return true if the user matches any value in the array (exact match)
              return userFilterValue.some(
                (filterUser) => user === filterUser.toLowerCase()
              );
            } else if (userFilterCondition === "not") {
              // Return true if the user does not match any value in the array
              return userFilterValue.every(
                (filterUser) => !user.includes(filterUser.toLowerCase())
              );
            }
          } else {
            // If the filter value is a string
            const userFilterValueLower = userFilterValue.toLowerCase();

            if (userFilterCondition === "is") {
              return user === userFilterValueLower; // Match exact user name
            } else if (userFilterCondition === "not") {
              return !user.includes(userFilterValueLower); // User name does not include the filter value
            }
          }
        });
      }

      if (
        currentFilters.logTitle &&
        currentFilters.logTitle.value.trim() !== ""
      ) {
        const filterLogTitle = currentFilters.logTitle.value
          .toLowerCase()
          .trim();
        const logTitleFilterType = currentFilters.logTitle.condition || "not";
        ftimesheets = ftimesheets.filter((ts) => {
          const logTitle = ts.logtitle ? ts.logtitle.toLowerCase() : "";
          if (logTitleFilterType === "is") {
            return logTitle === filterLogTitle;
          } else {
            return !logTitle.includes(filterLogTitle);
          }
        });
      }

      // Apply date filter
      if (
        currentFilters.date ||
        (currentFilters.fromDate && currentFilters.toDate)
      ) {
        if (
          currentFilters.dateFilterType === "range" &&
          currentFilters.fromDate &&
          currentFilters.toDate
        ) {
          const fromDate = new Date(currentFilters.fromDate).setHours(
            0,
            0,
            0,
            0
          );
          const toDate = new Date(currentFilters.toDate).setHours(
            23,
            59,
            59,
            999
          );
          ftimesheets = ftimesheets.filter((ts) => {
            const timesheetDate = new Date(ts.date).getTime();
            return timesheetDate >= fromDate && timesheetDate <= toDate;
          });
        }
      }

      // Apply billable filter
      if (currentFilters.billable && currentFilters.billable.value.length > 0) {
        const billableValues = currentFilters.billable.value; // Get the billable values as an array
        const billableCondition = currentFilters.billable.condition || "is"; // Default condition is "is"

        ftimesheets = ftimesheets.filter((ts) => {
          const billable = ts.billable.toString(); // Convert the billable value to string

          if (billableCondition === "is") {
            // Check if the current billable value is included in the array of billable values
            return billableValues.includes(billable);
          } else if (billableCondition === "not") {
            // Check if the current billable value is not included in the array of billable values
            return !billableValues.includes(billable);
          }
        });
      }

      // Apply approval status filter
      if (
        currentFilters.approvalstatus &&
        currentFilters.approvalstatus.value.length > 0 // Ensure value is an array and has entries
      ) {
        const approvalFilterValues = currentFilters.approvalstatus.value; // Get approval status values as an array
        const approvalFilterCondition =
          currentFilters.approvalstatus.condition || "is"; // Default to "is"

        ftimesheets = ftimesheets.filter((ts) => {
          const approvalStatus = ts.approvalstatus
            ? ts.approvalstatus.toLowerCase()
            : ""; // Convert approval status to lower case for comparison

          if (approvalFilterCondition === "is") {
            // Check if the current approval status is included in the filter values
            return approvalFilterValues.some(
              (filterValue) => filterValue.toLowerCase() === approvalStatus
            );
          } else if (approvalFilterCondition === "not") {
            // Check if the current approval status is NOT included in the filter values
            return !approvalFilterValues.some(
              (filterValue) => filterValue.toLowerCase() === approvalStatus
            );
          }
          return true; // Default case, keep the item
        });
      }

      // Apply admin filter
      if (
        currentFilters.selectedAdmin &&
        Array.isArray(currentFilters.selectedAdmin.value) &&
        currentFilters.selectedAdmin.value.length > 0
      ) {
        const adminFilterValues = currentFilters.selectedAdmin.value; // Array of admin IDs to filter by
        const adminFilterCondition =
          currentFilters.selectedAdmin.condition || "is"; // Default condition

        ftimesheets = ftimesheets.filter((ts) => {
          const approvedById = ts.approved_by; // Get the approved by ID

          // Ensure approvedById is a string for comparison
          const approvedByIdStr = approvedById ? approvedById.toString() : "";

          if (adminFilterCondition === "is") {
            const isIncluded = adminFilterValues.includes(
              Number(approvedByIdStr)
            ); // Check if approvedById is in the filter values
            return isIncluded; // Keep item if condition is met
          } else if (adminFilterCondition === "not") {
            const isNotIncluded = !adminFilterValues.includes(
              Number(approvedByIdStr)
            ); // Check if approvedById is NOT in the filter values

            return isNotIncluded; // Keep item if condition is met
          }

          return true; // Default case, keep the item
        });
      }
      // Update state with filtered timesheets
      setTimesheets(ftimesheets);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Function to export to Excel
  const exportToExcel = () => {
    const exportData = [];
    const columnWidths = {};
    // Helper function to update column widths
    const updateColumnWidths = (row) => {
      row.forEach((cell, index) => {
        if (cell && typeof cell === "string") {
          const length = cell.length;
          if (!columnWidths[index] || length > columnWidths[index]) {
            columnWidths[index] = length;
          }
        }
      });
    };

    // Loop over phases
    let rowOffset = 0;
    const merges = [];

    Object.keys(groupedByPhase).forEach((phaseTitle) => {
      const groupedTimesheets = getGroupedTimesheets(
        groupedByPhase[phaseTitle],
        groupBy
      );
      // Add Phase Title as a header (bold)
      exportData.push([{ v: phaseTitle, s: { font: { bold: true } } }]);
      updateColumnWidths([phaseTitle]);
      // Merge phase title
      merges.push({
        s: { r: rowOffset, c: 0 },
        e: { r: rowOffset, c: Object.keys(columnWidths).length - 1 },
      });
      rowOffset += 1;
      // Loop over groups (like user/date/category based on `groupBy`)
      Object.keys(groupedTimesheets).forEach((key) => {
        // Add group key as a subheader (bold)
        exportData.push([{ v: key, s: { font: { bold: true } } }]);
        updateColumnWidths([key]);
        // Merge group key
        merges.push({
          s: { r: rowOffset, c: 0 },
          e: { r: rowOffset, c: Object.keys(columnWidths).length - 1 },
        });
        rowOffset += 1;
        // Add table headers (customize these headers as needed)
        const headers = [
          "Log Title",
          ...(groupBy === "user" ? ["Date", "Category"] : []),
          ...(groupBy === "date" ? ["User", "Category"] : []),
          ...(groupBy === "category" ? ["User", "Date"] : []),
          "Project",
          "Task",
          "Duration",
          "Billable",
          "Status",
          "Created By",
          "Created At",
          "Approved By",
          "Approved At",
        ];
        exportData.push(headers);
        updateColumnWidths(headers);
        // Loop over timesheets in the group
        groupedTimesheets[key].forEach((timesheet) => {
          const row = [
            timesheet.logtitle || "Not Available",
            ...(groupBy === "user"
              ? [
                  format(timesheet.date, "dd-MM-yyyy"),
                  timesheet.variation_id
                    ? "Variation"
                    : timesheet.issue_id
                    ? "Issue"
                    : "General",
                ]
              : []),
            ...(groupBy === "date" ? [timesheet.user_name, "General"] : []),
            ...(groupBy === "category" ? [timesheet.user_name, "General"] : []),
            timesheet.project_name,
            timesheet.task_name,
            formatTime(timesheet.duration),
            timesheet.billable === 1 ? "Billable" : "Non Billable",
            timesheet.approvalstatus,
            timesheet.creator_name,
            timesheet.created_at ? timesheet.created_at : "Not Available",
            timesheet.approver_name || "Not Available",
            timesheet.approved_at && timesheet.approved_at !== "NULL"
              ? format(timesheet.approved_at, "dd-MM-yyyy")
              : "Not Approved",
          ];
          exportData.push(row);
          updateColumnWidths(row);
        });

        // Add an empty row between groups
        exportData.push([]);
        rowOffset += 1; // account for empty row
      });

      // Add an empty row between phases
      exportData.push([]);
      rowOffset += 1; // account for empty row
    });
    // Create a worksheet and workbook
    const worksheet = utils.aoa_to_sheet(exportData);

    // Set column widths based on maximum content length
    worksheet["!cols"] = Object.keys(columnWidths).map((index) => ({
      wch: columnWidths[index] + 2,
    }));

    // Apply cell merges
    worksheet["!merges"] = merges;

    // Add styling to the first column for headers and subheaders
    Object.keys(worksheet).forEach((key) => {
      if (
        key[0] !== "!" &&
        worksheet[key].v &&
        typeof worksheet[key].v === "string"
      ) {
        // Apply bold style for rows with only one column (headers or subheaders)
        if (
          worksheet[key].v === exportData[0][0].v ||
          worksheet[key].v === exportData[1][0].v
        ) {
          worksheet[key].s = { font: { bold: true } };
        }
      }
    });

    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Timesheet Data");

    // Write the file
    writeFile(
      workbook,
      `Timesheet_Data_${new Date().toLocaleDateString()}.xlsx`
    );
  };

  const handleFilterChange = (newFilters) => {
    const updatedFilters = { ...filters, ...newFilters };
    setFilters(updatedFilters);
    fetchTimesheets(updatedFilters); // Pass the updated filters to the fetch function
  };
  const handleClearFilters = () => {
    fetchTimesheets({});
    setFilters({
      user: { value: "", condition: "" },
      userFilterType: "contains",
      logTitle: "",
      logTitleFilterType: "contains",
      logCategory: "",
      logCategoryFilterType: "",
      date: "",
      fromDate: "",
      toDate: "",
      dateFilterType: "range",
      billable: "",
      approvalstatus: "",
      admin: { value: "", condition: "" },
    });

    // Use callback to ensure you're working with the updated state
    setIsFilterActive(false);
  };
  useEffect(() => {
    fetchTimesheets();
  }, [filters]);

  useEffect(() => {
    // Check each filter individually to determine its active status
    const isUserFilterActive = filters.user && filters.user.value;
    const isDateFilterActive = filters.date;
    const isLogTitleFilterActive = filters.logTitle;
    const isFromDateFilterActive = filters.fromDate;
    const isToDateFilterActive = filters.toDate;
    const isLogCategoryFilterActive = filters.logCategory;
    const isBillableFilterActive = filters.billable;
    const isApprovalStatusFilterActive = filters.approvalstatus;
    const isAdminFilterActive = filters.admin && filters.admin.value.length > 0;

    // Determine if any filter is active
    const hasActiveFilters =
      isUserFilterActive ||
      isDateFilterActive ||
      isLogTitleFilterActive ||
      isFromDateFilterActive ||
      isToDateFilterActive ||
      isLogCategoryFilterActive ||
      isBillableFilterActive ||
      isApprovalStatusFilterActive ||
      isAdminFilterActive;

    setIsFilterActive(hasActiveFilters);
  }, [filters]);

  const calculateSummary = (timesheets) => {
    return timesheets.reduce(
      (summary, timesheet) => {
        const durationMinutes = parseDuration(timesheet.duration);

        if (timesheet.billable === 1) {
          summary.billable += durationMinutes;
        } else {
          summary.nonBillable += durationMinutes;
        }

        if (timesheet.approvalstatus === "Approved") {
          summary.approved += durationMinutes;
        } else {
          summary.unapproved += durationMinutes;
        }

        return summary;
      },
      {
        billable: 0,
        nonBillable: 0,
        approved: 0,
        unapproved: 0,
      }
    );
  };

  // Grouping functions
  const groupByUser = (timesheets) => {
    return timesheets.reduce((groups, timesheet) => {
      const user = timesheet.user_name;
      if (!groups[user]) {
        groups[user] = [];
      }
      groups[user].push(timesheet);
      return groups;
    }, {});
  };

  const groupByDate = (timesheets) => {
    const grouped = timesheets.reduce((groups, timesheet) => {
      const date = new Date(timesheet.date).toLocaleDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(timesheet);
      return groups;
    }, {});

    const sortedDates = Object.keys(grouped).sort((a, b) => {
      return sortOrder === "asc"
        ? new Date(a) - new Date(b)
        : new Date(b) - new Date(a);
    });

    return sortedDates.reduce((sortedGroups, date) => {
      sortedGroups[date] = grouped[date];
      return sortedGroups;
    }, {});
  };

  const groupByCategory = (timesheets) => {
    return timesheets.reduce((groups, timesheet) => {
      const category = timesheet.variation_id
        ? "Variation"
        : timesheet.issue_id
        ? "Issue"
        : "General";
      if (!groups[category]) {
        groups[category] = [];
      }
      groups[category].push(timesheet);
      return groups;
    }, {});
  };

  const updateApprovalStatus = async (timesheetId, newStatus) => {
    try {
      await api.put(`/api/timesheettable/${timesheetId}/${userId}/approval`, {
        status: newStatus,
      });
      fetchTimesheets(); // Refresh the data after update
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  const getGroupedTimesheets = (timesheets, groupBy) => {
    switch (groupBy) {
      case "user":
        return groupByUser(timesheets);
      case "date":
        return groupByDate(timesheets);
      case "category":
        return groupByCategory(timesheets);
      default:
        return {};
    }
  };

  const parseDuration = (duration) => {
    const [hours, minutes] = duration.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
  };

  const groupedByPhase = timesheets.reduce((phases, timesheet) => {
    const phase = timesheet.phase_title;
    if (!phases[phase]) {
      phases[phase] = [];
    }
    phases[phase].push(timesheet);
    return phases;
  }, {});

  const overallSummary = Object.keys(groupedByPhase).reduce(
    (overall, phaseTitle) => {
      const phaseSummary = calculateSummary(groupedByPhase[phaseTitle]);
      overall.billable += phaseSummary.billable;
      overall.nonBillable += phaseSummary.nonBillable;
      overall.approved += phaseSummary.approved;
      overall.unapproved += phaseSummary.unapproved;
      return overall;
    },
    {
      billable: 0,
      nonBillable: 0,
      approved: 0,
      unapproved: 0,
    }
  );

  useEffect(() => {
    fetchTimesheets();
  }, [projectId, userDetails]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <Helmet>
        <title>Timesheets-Overview - MSES Timesheet</title>
      </Helmet>

      {!dialBoxopen && (
        <div
          className={
            isNavBarOpen
              ? "main-container overview-main-container"
              : "main-container-close overview-main-container"
          }
          // style={{ marginTop: "-82px" }}
        >
          <div className="filter-categories">
            <select
              className="select-group-by"
              value={groupBy}
              onChange={(e) => setGroupBy(e.target.value)}
            >
              <option value="user">Group By User</option>
              <option value="date"> Group By Date</option>
              <option value="category"> Group By Category</option>
            </select>
            {isFilterActive && (
              <button
                className="clear-filter-btn clear-btn-overview"
                onClick={handleClearFilters}
                title="Clear Filters"
              >
                Clear Filters
              </button>
            )}
            <div
              className={
                isNavBarOpen
                  ? "overview-add-filter"
                  : "overview-add-filter-close"
              }
            >
              <FilterTimesheetOverview refresh={handleFilterChange} />
            </div>
            <button
              onClick={exportToExcel}
              className="export-btn"
              style={{ marginRight: isNavBarOpen ? "161px" : "86px" }}
              title="Export to excel"
            >
              Export to Excel
            </button>
          </div>
          {timesheets.length === 0 && (
            <>
              <img className="timesheet-image" src="/AppLayout/Timesheet.png" />
              <p className="timesheet-message-t">No timesheets available.</p>
            </>
          )}
          <div
            className={
              isNavBarOpen ? "overall-summary" : "overall-summary-close"
            }
          >
            <h3
              className="over-all-summary-header"
              style={{ marginTop: "5px", backgroundColor: "transparent" }}
            >
              Overall Summary :
            </h3>
            <p
              style={{
                marginTop: "5px",
                backgroundColor: "transparent",
                fontWeight: "600px",
              }}
            >
              Billable :{" "}
              <span className="billable">
                {" "}
                {formatDuration(overallSummary.billable)}
              </span>
            </p>
            <p
              style={{
                marginTop: "5px",
                backgroundColor: "transparent",
                fontWeight: "600px",
              }}
            >
              Non-Billable :{" "}
              <span className="non-billable">
                {" "}
                {formatDuration(overallSummary.nonBillable)}
              </span>
            </p>
            <p
              style={{
                marginTop: "5px",
                backgroundColor: "transparent",
                fontWeight: "600px",
              }}
            >
              Approved :{" "}
              <span className="approved">
                {formatDuration(overallSummary.approved)}
              </span>
            </p>
            <p
              style={{
                marginTop: "5px",
                backgroundColor: "transparent",
                fontWeight: "600px",
              }}
            >
              Unapproved:{" "}
              <span className="un-approved">
                {formatDuration(overallSummary.unapproved)}
              </span>
            </p>
          </div>
          {Object.keys(groupedByPhase).map((phaseTitle) => {
            const groupedTimesheets = getGroupedTimesheets(
              groupedByPhase[phaseTitle],
              groupBy
            );
            const phaseSummary = calculateSummary(groupedByPhase[phaseTitle]);
            const isSinglePhase = Object.keys(groupedByPhase).length === 1;
            return (
              <div
                key={phaseTitle}
                style={{ width: isNavBarOpen ? "96%" : "99.85%" }}
                className={
                  isSinglePhase
                    ? "single-phase additional"
                    : "table-container-timesheet"
                }
              >
                {" "}
                <h3 className="timesheet-phase-header">
                  <span className="timesheet-phase-details">{phaseTitle}</span>
                </h3>
                <div
                  className={
                    isSinglePhase
                      ? "single-summary additional-summary"
                      : "phase-summary"
                  }
                >
                  <h3
                    className="category-summary"
                    style={{ marginTop: "2px", fontWeight: "500" }}
                  >
                    Phase Summary : {""}
                  </h3>
                  <p className="billable" style={{ marginTop: "2px" }}>
                    {formatDuration(phaseSummary.billable)}
                  </p>
                  <p className="non-billable" style={{ marginTop: "2px" }}>
                    {formatDuration(phaseSummary.nonBillable)}
                  </p>
                  <p className="approved" style={{ marginTop: "2px" }}>
                    {formatDuration(phaseSummary.approved)}
                  </p>
                  <p className="un-approved" style={{ marginTop: "2px" }}>
                    {formatDuration(phaseSummary.unapproved)}
                  </p>
                </div>
                {Object.keys(groupedTimesheets).map((key, index) => (
                  <div
                    key={key}
                    style={{ marginBottom: "-25px" }}
                    className="table-parent"
                  >
                    <table className="timesheet-phase-table">
                      <thead>
                        <tr>
                          <th style={{ minWidth: "60px" }}>
                            <span className="table-icons">
                              <MdNumbers />
                              <p className="table-header">S.No</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <MdOutlineSubtitles
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Log Title</p>
                            </span>
                          </th>
                          {groupBy === "user" && (
                            <th>
                              <span className="table-icons">
                                <IoCalendarOutline
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header"> Date</p>
                              </span>
                            </th>
                          )}
                          {groupBy === "user" && (
                            <th>
                              <span className="table-icons">
                                <MdOutlineCategory
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header">Category</p>
                              </span>
                            </th>
                          )}
                          {groupBy === "date" && (
                            <th>
                              <span className="table-icons">
                                <CgProfile
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header">User</p>
                              </span>
                            </th>
                          )}
                          {groupBy === "date" && (
                            <th>
                              <span className="table-icons">
                                <MdOutlineCategory
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header">Category</p>
                              </span>
                            </th>
                          )}
                          {groupBy === "category" && (
                            <th>
                              {" "}
                              <span className="table-icons">
                                <CgProfile
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header">User</p>
                              </span>
                            </th>
                          )}
                          {groupBy === "category" && (
                            <th>
                              <span className="table-icons">
                                <IoCalendarOutline
                                  style={{
                                    height: "14px",
                                    width: "30px",
                                  }}
                                />{" "}
                                <p className="table-header">Date</p>
                              </span>
                            </th>
                          )}
                          <th>
                            <span className="table-icons">
                              <CgRename
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Project</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <BiTask
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Task</p>
                            </span>
                          </th>
                          <th>
                            {" "}
                            <span className="table-icons">
                              <IoTimerOutline
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header"> Duration (hh:mm)</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <SiTyper
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Billable</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <GrStatusGood
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Status</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <MdOutlineCreateNewFolder
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header"> Created By</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <LiaBusinessTimeSolid
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header"> Created At</p>
                            </span>
                          </th>
                          <th>
                            <span className="table-icons">
                              <GrUserExpert
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header"> Approved By</p>
                            </span>{" "}
                          </th>
                          <th>
                            <span className="table-icons">
                              <MdCreditScore
                                style={{
                                  height: "14px",
                                  width: "30px",
                                }}
                              />{" "}
                              <p className="table-header">Approved At</p>
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "rgb(243 238 238 / 34%)",
                              width: "100vw",
                              padding: "5px 0px",
                            }}
                            colSpan={14}
                          >
                            <div className="category-details">
                              <h3 className="filter-by">{key}</h3>
                              <div
                                className="group-summary-each-table"
                                style={{ backgroundColor: "transparent" }}
                              >
                                <p className="billable">
                                  {formatDuration(
                                    calculateSummary(groupedTimesheets[key])
                                      .billable
                                  )}
                                </p>
                                <p className="non-billable">
                                  {formatDuration(
                                    calculateSummary(groupedTimesheets[key])
                                      .nonBillable
                                  )}
                                </p>
                                <p className="approved">
                                  {formatDuration(
                                    calculateSummary(groupedTimesheets[key])
                                      .approved
                                  )}
                                </p>
                                <p className="un-approved">
                                  {formatDuration(
                                    calculateSummary(groupedTimesheets[key])
                                      .unapproved
                                  )}
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {groupedTimesheets[key].length > 0 ? (
                          groupedTimesheets[key].map((timesheet, index) => (
                            <tr key={timesheet.id}>
                              <td>{index + 1}</td>
                              <td>
                                {timesheet.logtitle
                                  ? timesheet.logtitle
                                  : "Not Available"}
                              </td>
                              {groupBy === "user" && (
                                <>
                                  <td>
                                    {format(timesheet.date, "dd-MM-yyyy")}
                                  </td>
                                  <td>
                                    {timesheet.variation_id
                                      ? "Variation"
                                      : timesheet.issue_id
                                      ? "Issue"
                                      : "General"}
                                  </td>
                                </>
                              )}
                              {groupBy === "date" && (
                                <>
                                  <td>{timesheet.user_name}</td>
                                  <td>
                                    {timesheet.variation_id
                                      ? "Variation"
                                      : timesheet.issue_id
                                      ? "Issue"
                                      : "General"}
                                  </td>
                                </>
                              )}
                              {groupBy === "category" && (
                                <>
                                  <td>{timesheet.user_name}</td>
                                  <td>
                                    {format(timesheet.date, "dd-MM-yyyy")}
                                  </td>
                                </>
                              )}
                              <td>{timesheet.project_name}</td>
                              <td>{timesheet.task_name}</td>
                              <td
                                style={{
                                  color: timesheet.billable
                                    ? "#7ace4c"
                                    : "#ee9c29",
                                  fontWeight: "700",
                                }}
                              >
                                {formatTime(timesheet.duration)}
                              </td>
                              <td
                                style={{
                                  color: timesheet.billable
                                    ? "#7ace4c"
                                    : "#ee9c29",
                                  fontWeight: "550",
                                }}
                              >
                                {timesheet.billable === 1
                                  ? "Billable"
                                  : "Non Billable"}
                              </td>
                              <td
                                style={{
                                  backgroundColor:
                                    timesheet.approvalstatus === "Approved"
                                      ? "#7ace4c"
                                      : timesheet.approvalstatus === "Rejected"
                                      ? "#fa2929"
                                      : timesheet.approvalstatus === "Pending"
                                      ? "#78909c"
                                      : "#78909c",
                                  color:
                                    timesheet.approvalstatus === "Approved"
                                      ? "white"
                                      : timesheet.approvalstatus === "Rejected"
                                      ? "White"
                                      : timesheet.approvalstatus === "Pending"
                                      ? "White"
                                      : "white",
                                  minWidth: "200px",
                                }}
                              >
                                <select
                                  className="status-dropdown"
                                  value={timesheet.approvalstatus}
                                  onChange={(e) =>
                                    updateApprovalStatus(
                                      timesheet.id,
                                      e.target.value
                                    )
                                  }
                                  style={{
                                    backgroundColor:
                                      timesheet.approvalstatus === "Approved"
                                        ? "#7ace4c"
                                        : timesheet.approvalstatus ===
                                          "Rejected"
                                        ? "#fa2929"
                                        : timesheet.approvalstatus === "Pending"
                                        ? "#78909c"
                                        : "#78909c",
                                    color:
                                      timesheet.approvalstatus === "Approved"
                                        ? "white"
                                        : timesheet.approvalstatus ===
                                          "Rejected"
                                        ? "White"
                                        : timesheet.approvalstatus === "Pending"
                                        ? "White"
                                        : "white",
                                    cursor:
                                      userDetails.role !== "admin"
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                  disabled={userDetails.role !== "admin"}
                                >
                                  <option
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                    value="Pending"
                                  >
                                    Pending
                                  </option>
                                  <option
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                    value="Approved"
                                  >
                                    Approved
                                  </option>
                                  <option
                                    style={{
                                      backgroundColor: "white",
                                      color: "black",
                                    }}
                                    value="Rejected"
                                  >
                                    Rejected
                                  </option>
                                </select>
                              </td>
                              <td>{timesheet.creator_name}</td>
                              <td>
                                {timesheet.created_at
                                  ? format(timesheet.created_at, "dd-MM-yyyy")
                                  : "Not Available"}
                              </td>
                              <td>
                                {timesheet.approver_name
                                  ? timesheet.approver_name
                                  : "Not Avilable"}
                              </td>
                              <td>
                                {timesheet.approved_at &&
                                timesheet.approved_at !== "NULL"
                                  ? format(timesheet.approved_at, "dd-MM-yyyy")
                                  : "Not Approved"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="14">No timesheets available</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TimeSheetTable;
