import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { FaChevronDown } from "react-icons/fa";
import "./CustomerList.css";
import { useAuth } from "./Authentication/AuthContext";
import api from "../api/axiosConfig";
import { ProjectContext } from "../App";
import { useRolePermissions } from "../components/Settings/RoleContext.js";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const CustomerList = () => {
  const { customerId } = useParams();
  const { hasPermission } = useRolePermissions();
  const { isNavBarOpen } = useContext(ProjectContext);
  const [customers, setCustomers] = useState([]);
  const [contactsByCustomer, setContactsByCustomer] = useState({});
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [activeCustomerLink, setCustomerActiveLink] = useState(() => {
    return localStorage.getItem("activecustomers") || "";
  });
  const [editContactDialBox, setEditContactDialBox] = useState(false);
  const [editContactId, setEditContactId] = useState(null);
  const [editableContact, setEditableContact] = useState(null);
  const [deleteContactDialBox, setDeleteContactDialBox] = useState(false);
  const [contactToDelete, setContactToDelete] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const handleCustomerLinkClick = (link) => {
    setCustomerActiveLink(link); // Update the active link
    localStorage.setItem("activecustomers", link);
  };
  const [contacts, setContact] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userDetails } = useAuth();
  const userRole = userDetails.role;
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [contactForm, setContactForm] = useState({
    contact_name: "",
    contactno: "",
    email: "",
    designation: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUserssPerPage] = useState(13);
  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setUserssPerPage(9); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setUserssPerPage(13); // Medium screens (e.g., tablet)
    } else {
      setUserssPerPage(10); // Large screens (e.g., desktop)
    }
  };
  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint =
          userDetails.role_name === "Administrator"
            ? `/api/customers`
            : `/api/customers/${userDetails.id}`;
        const res = await api.get(endpoint);
        const customersData = res.data;
        setCustomers(customersData);

        const contactsPromises = customersData.map((customer) =>
          api.get(`/api/customers/${customer.id}/contacts`)
        );

        const contactsResponses = await Promise.all(contactsPromises);
        const contactsMap = {};
        contactsResponses.forEach((response, index) => {
          contactsMap[customersData[index].id] = response.data;
        });
        setContactsByCustomer(contactsMap);
      } catch (error) {
        setError(error);
        console.error(
          "Error fetching customer or contact data:",
          error.message || error
        );
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [userRole, userDetails.id, refreshTrigger]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `/api/customers/${customerId}/contacts`;
        const res = await api.get(endpoint);
        const contactData = res.data;
        setContact(contactData);
      } catch (error) {
        setError(error);
        console.error(
          "Error fetching customer or contact data:",
          error.message || error
        );
      } finally {
        setLoading(false);
      }
    };

    if (customerId) {
      fetchData();
    }
  }, [customerId, userRole, userDetails.id, refreshTrigger]);

  const closeContactDialog = () => {
    setOpenContactDialog(false);
    document.body.classList.remove("no-scroll");
  };

  const handleAddContact = async () => {
    if (selectedCustomerId === null) {
      alert("Please select a customer.");
      return;
    }

    try {
      const response = await api.post(
        `/api/customers/${selectedCustomerId}/contacts`,
        contactForm
      );
      if (response.status === 201) {
        alert("Contact added successfully!");
        setRefreshTrigger(!refreshTrigger);
        closeContactDialog();
        setContactForm("");
      }
    } catch (error) {
      console.error(
        "Error adding contact:",
        error.response ? error.response.data : error.message
      );
      alert("Failed to add contact");
    }
  };

  const handleOpenAddContactDialog = (customerId) => {
    setSelectedCustomerId(customerId);
    setOpenContactDialog(true);
    document.body.classList.add("no-scroll");
  };

  const handleContactInputChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContactEditClick = (contact) => {
    setEditContactDialBox(true); // Opens the dialog
    setEditableContact(contact);
    // Sets the customer to be edited
    setEditContactId(contact.contact_id); // Sets the customer ID
  };

  const handleEditContact = async () => {
    const contactId = editContactId;
    const updatedData = editableContact;
    try {
      const response = await api.put(`/api/contacts/${contactId}`, updatedData);
      setRefreshTrigger(!refreshTrigger);
      setEditContactDialBox(false);
    } catch (error) {
      console.error("Error updating contact:", error.message || error);
    }
  };

  const handleDeleteContact = async () => {
    try {
      await api.delete("/api/contacts", {
        data: {
          contactIds: contactToDelete.map((contact) => contact.contact_id),
        },
      });
      // Update the state to remove the contacts from the list
      setRefreshTrigger(!refreshTrigger); // This should trigger a refresh of the contacts
      setDeleteContactDialBox(false); // Close the dialog
      setIsCheckboxChecked(false); // Reset checkbox state
      setContactToDelete([]); // Clear the list of contacts to delete
    } catch (error) {
      console.error("Error deleting contacts:", error.message || error);
    }
  };

  const handleDeleteClick = (e, contact) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setContactToDelete((prev) => [...prev, contact]);
    } else {
      setContactToDelete((prev) =>
        prev.filter((c) => c.contact_id !== contact.contact_id)
      );
    }
  };

  const handleCloseDeleteCustomerDial = () => {
    setDeleteContactDialBox(false); // Close dialog on cancel
    setIsCheckboxChecked(false); // Reset checkbox state
  };
  const totalPages = Math.ceil(contacts.length / usersPerPage);

  // Get current projects to display
  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  const currentUsers = contacts.slice(indexOfFirstUsers, indexOfLastUsers);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <div
        className="project-nav-bar-details"
        style={{ left: isNavBarOpen ? "0px" : "-77px" }}
      >
        <Link to="/settings">
          <FaLongArrowAltLeft className="exit-arrow" />
        </Link>{" "}
        <h3>Contacts</h3>
        <div className="project-tab-details">
          <Link
            className={`proj-links ${
              activeCustomerLink === "activecustomers" ? "active" : ""
            }`}
            onClick={() => handleCustomerLinkClick("activecustomers")}
          >
            Active Contacts
          </Link>
          <Link
            className={`proj-links ${
              activeCustomerLink === "overallcustomers" ? "active" : ""
            }`}
            onClick={() => handleCustomerLinkClick("overallcustomers")}
          >
            Inactive Contacts
          </Link>
        </div>
        <hr className="proj-line" />
      </div>
      {hasPermission("customers", "delete") && contactToDelete.length > 0 && (
        <button
          className="add-proj-heading"
          style={{
            backgroundColor: "red",
            top: "14px",
            left: "87.5%",
            marginRight: "-60px",
          }}
          onClick={() => setDeleteContactDialBox(true)}
          title="Delete Contact"
        >
          Delete
        </button>
      )}
      {hasPermission("customers", "create") && (
        <button
          style={{ top: "14px", marginLeft: "15px" }}
          className="add-proj-heading"
          onClick={() => handleOpenAddContactDialog(customerId)}
          title="New Contact"
        >
          New Contact
        </button>
      )}
      <div
        className="project-container contact-table"
        style={{ left: isNavBarOpen ? "" : "0px" }}
      >
        {contacts.length > 0 && (
          <table
            style={{ width: "97%", top: "0px" }}
            className="project-content-table"
          >
            <thead>
              <tr>
                {hasPermission("customers", "delete") && (
                  <th style={{ width: "2px", textAlign: "center" }}>
                    <FaChevronDown />{" "}
                  </th>
                )}
                <th style={{ textAlign: "left" }}>Name</th>
                <th style={{ textAlign: "left" }}>Number</th>
                <th style={{ textAlign: "left" }}>Designation</th>
                <th style={{ textAlign: "left" }}>Email</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.map((contact, index) => (
                <tr key={contact.contact_id}>
                  {hasPermission("customers", "delete") && (
                    <td>
                      <input
                        type="checkbox"
                        name={contact.name}
                        checked={contactToDelete.some(
                          (c) => c.contact_id === contact.contact_id
                        )}
                        onChange={(e) => handleDeleteClick(e, contact)}
                      />
                    </td>
                  )}
                  <td>
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        border: "none",
                        justifyContent: "space-between",
                      }}
                    >
                      {contact.contact_name}
                      {hasPermission("customers", "update") && (
                        <EditIcon
                          style={{
                            backgroundColor: "transparent",
                            color: "blue",
                          }}
                          className="edit-icon-wrapper"
                          onClick={() => {
                            handleContactEditClick(contact); // Pass the customer object to handleEditClick
                          }}
                        />
                      )}
                    </div>
                  </td>
                  <td>{contact.contactno}</td>
                  <td>{contact.designation}</td>
                  <td>{contact.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {contacts.length === 0 && (
          <>
            <img className="timesheet-image" src="/AppLayout/Contact.png" />
            <p className="timesheet-message">
              No contacts available for this customer
            </p>
          </>
        )}
      </div>
      <div
        className="pagination-controls contact-pagination"
        // style={{ marginTop: "783px", marginLeft: "1660px" }}
      >
        <h3>Total : {currentUsers.length}</h3>
        <button
          onClick={handleFirstPage}
          disabled={currentPage === 1}
          className="arrow-project-btn"
        >
          <MdKeyboardDoubleArrowLeft title="First Page" />
        </button>
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="arrow-project-btn"
        >
          {/* */}
          <MdKeyboardArrowLeft title="Previous Page" />
        </button>
        <span
          style={{
            marginTop: "16px",
            textWrap: "nowrap",
          }}
        >
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="arrow-project-btn"
        >
          <MdKeyboardArrowRight title="Next Page" />
        </button>
        <button
          onClick={handleLastPage}
          disabled={currentPage === totalPages}
          className="arrow-project-btn"
        >
          <MdKeyboardDoubleArrowRight title="Last Page" />
        </button>
      </div>
      {/* Add contact dialog */}
      {openContactDialog && (
        <div className="add-contact-dial">
          <div className="add-project-content">
            <div
              className="add-project-user-header"
              style={{ marginLeft: "0px" }}
            >
              <h3
                className="add-project-user-heading"
                style={{ marginLeft: "8px", fontWeight: "550px" }}
              >
                Add New Contact
              </h3>
            </div>
            <div className="customer-form">
              <label className="customer-label">Contact Name:</label>
              <input
                className="customer-input"
                type="text"
                placeholder="Enter contact name"
                name="contact_name"
                value={contactForm.contact_name}
                onChange={handleContactInputChange}
              />
              <br />
              <label className="customer-label">Contact No:</label>
              <input
                className="customer-input"
                type="text"
                placeholder="Enter contact number"
                name="contactno"
                value={contactForm.contactno}
                onChange={handleContactInputChange}
              />
              <br />
              <label className="customer-label">Email:</label>
              <input
                className="customer-input"
                type="email"
                placeholder="Enter email address"
                name="email"
                value={contactForm.email}
                onChange={handleContactInputChange}
              />
              <br />
              <label className="customer-label">Designation:</label>
              <input
                className="customer-input"
                type="text"
                placeholder="Enter designation"
                name="designation"
                value={contactForm.designation}
                onChange={handleContactInputChange}
              />
              <br />
            </div>
            <div className="customer-btn-container">
              <button
                onClick={handleAddContact}
                style={{ marginLeft: "25px" }}
                className="add-btn"
              >
                Add Contact
              </button>
              <button onClick={closeContactDialog} className="cancel-btn">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {deleteContactDialBox && (
        <div className="delete-phase-dial" style={{ marginTop: "-11px" }}>
          <div className="delete-header">
            <h3 style={{ backgroundColor: "transparent", marginLeft: "13px" }}>
              Delete Contact
            </h3>
          </div>
          <div style={{ backgroundColor: "white" }}>
            <p style={{ backgroundColor: "white" }}>
              Are you sure you want to delete this contact? This action cannot
              be undone.
            </p>
            <input
              type="checkbox"
              id="delete-phase"
              checked={isCheckboxChecked}
              onChange={() => setIsCheckboxChecked((prev) => !prev)}
            />
            <label htmlFor="delete-phase" style={{ backgroundColor: "white" }}>
              I understand this action cannot be undone.
            </label>
          </div>
          <div className="delete-dial-btn-con">
            <button
              onClick={handleDeleteContact}
              disabled={!isCheckboxChecked}
              className="add-btn"
              style={{ backgroundColor: isCheckboxChecked ? "red" : "gray" }}
            >
              Delete
            </button>
            <button
              onClick={handleCloseDeleteCustomerDial}
              className="cancel-btn"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {editContactDialBox && (
        <div className="delete-customer-dial edit-contact-dial">
          <div className="add-project-content">
            <div
              className="add-project-user-header"
              style={{ marginLeft: "0px" }}
            >
              <h3
                className="add-project-user-heading"
                style={{ marginLeft: "15px", fontWeight: "550px" }}
              >
                Edit Contact
              </h3>
            </div>
            <div className="customer-form">
              <label className="customer-label">Contact Name:</label>
              <input
                type="text"
                className="customer-input"
                name="customerno"
                value={editableContact.contact_name}
                onChange={(e) =>
                  setEditableContact({
                    ...editableContact,
                    contact_name: e.target.value,
                  })
                }
              />
              <label className="customer-label">Contact Number:</label>
              <input
                type="text"
                className="customer-input"
                name="customerno"
                value={editableContact.contactno}
                onChange={(e) =>
                  setEditableContact({
                    ...editableContact,
                    contactno: e.target.value,
                  })
                }
              />
              <label className="customer-label">Contact Email:</label>
              <input
                type="text"
                className="customer-input"
                name="customerno"
                value={editableContact.email}
                onChange={(e) =>
                  setEditableContact({
                    ...editableContact,
                    email: e.target.value,
                  })
                }
              />
            </div>
            <div className="customer-btn-container">
              <button
                onClick={handleEditContact}
                style={{ marginLeft: "24px" }}
                className="add-btn"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setEditContactDialBox(false);
                }}
                className="cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerList;
