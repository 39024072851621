import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Authentication/AuthContext";
import api from "../../api/axiosConfig";
import "./SignIn1.css";

const SignIn = () => {
  const navigate = useNavigate();
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login, isAuthenticated } = useAuth();

  const handleIdentifierChange = (e) => setIdentifier(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const onhandleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting login with identifier:", identifier); // Check value of identifier
    console.log("Base URL:", api.defaults.baseURL);
    if (identifier.trim() === "" || password === "") {
      alert("Please fill in all fields");
      return;
    }

    try {
      const response = await api.post("/login", {
        identifier,
        password,
      });
      if (response.data.message === "Login successful") {
        const userDetails = response.data.userDetails;
        login(userDetails);
        navigate("/home");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Invalid identifier or password");
      } else {
        setError("Server error");
      }
    }
  };

  return (
    <>
      {isAuthenticated !== true && (
        <div className="main-container-signin">
          <div className="type-email-parent">
            <form onSubmit={onhandleSubmit}>
              <input
                className="type-email"
                placeholder="Email, Mobile, or Employee Code"
                type="text"
                value={identifier}
                onChange={handleIdentifierChange}
              />
              <input
                className="type-email"
                style={{ marginTop: "50px" }}
                placeholder="Enter Password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
              <button
                type="submit"
                className="button2"
                style={{
                  borderRadius: "0px",
                  width: "320px",
                  marginTop: "20px",
                }}
              >
                Sign In
              </button>
              <p
                className="forgot-password1 forgot"
                onClick={() => navigate("/forgot-password")}
              >
                {" "}
                {error && (
                  <span
                    style={{
                      color: "red",
                      marginLeft: "-193px",
                      marginRight: "19px",
                    }}
                  >
                    {error}
                  </span>
                )}{" "}
                Forgot Password?
              </p>

              <img
                className="task-bro-1-icon1"
                alt="man-logo"
                src="/taskbro-1@2x.jpg"
              />
              <img
                className="group-158-1"
                alt="MSES Group"
                src="/group-158-1@2x.png"
              />
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default SignIn;
