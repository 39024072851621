import React, { useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

const ProjectPieChart = ({
  projectName,
  phases,
  plannedHours,
  bookedHours,
}) => {
  const data = useMemo(
    () => ({
      labels: ["Total Planned Hours", "Booked Hours"],
      datasets: [
        {
          label: projectName,
          data: [plannedHours, bookedHours],
          backgroundColor: ["#6200ee", "#d900ee"],
          hoverBackgroundColor: ["#3700b3", "#aa00cc"], // Optional: different colors on hover
          hoverOffset: 10, // Increases the slice size when hovered
        },
      ],
    }),
    [plannedHours, bookedHours, projectName]
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        enabled: true,
      },
    },
    hover: {
      mode: "nearest",
      animationDuration: 200,
      shadowBlur: 10, // Adds a shadow effect for a lifted appearance
      shadowColor: "rgba(0, 0, 0, 0.3)", // Dark shadow color for a soft glow effect
    },
    animation: {
      duration: 500,
      easing: "easeOutCirc", // Circular easing for a smooth deceleration
      stagger: 500, // Staggers elements to animate one after another
    },
  };

  return (
    <div>
      <h3>Duration Summary - {projectName}</h3>
      <p>Phase Count : {phases.length}</p>
      {plannedHours === 0 && (
        <div className="duration-image-container">
          <img
            className="duration-image"
            src="/AppLayout/Duration-planned.png"
          />
          <p>Planned hours not available for this project</p>
        </div>
      )}
      <Pie data={data} options={options} className="piechart" />
    </div>
  );
};

export default ProjectPieChart;
