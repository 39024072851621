import React, { useState, useEffect, useContext } from "react";
import { useRolePermissions } from "../Settings/RoleContext";
import { ProjectContext } from "../../App";
import api from "../../api/axiosConfig";
import AddUser from "../DialBox/AddUser";
import "../../styles/Settings/Settings.css";
import { useAuth } from "../Authentication/AuthContext";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardDoubleArrowRight,
  MdKeyboardArrowRight,
} from "react-icons/md";

export const UserSettings = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userRoles, setUserRoles] = useState({});
  const { hasPermission } = useRolePermissions();
  const { openDialog, setOpenDialog } = useContext(ProjectContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userDetails } = useAuth();
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const userRole = userDetails.role;
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUserssPerPage] = useState(13);

  const fetchData = async (url, setData, setError, setLoading) => {
    try {
      const response = await api.get(url);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error);
    }
  };

  const updateProjectsPerPage = () => {
    if (window.innerWidth < 1919) {
      setUserssPerPage(9); // Small screens (e.g., mobile)
    } else if (window.innerWidth >= 1920) {
      setUserssPerPage(13); // Medium screens (e.g., tablet)
    } else {
      setUserssPerPage(10); // Large screens (e.g., desktop)
    }
  };
  useEffect(() => {
    // Set initial projects per page
    updateProjectsPerPage();

    // Update projects per page when the window is resized
    window.addEventListener("resize", updateProjectsPerPage);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateProjectsPerPage);
  }, []);
  const updateStatus = async (newStatus, userId) => {
    try {
      await api.put(`/api/settings/users/${userId}/status`, {
        status: newStatus,
      });
      // Refresh data
      fetchData(`/api/settings/users/`, (data) => setUsers(data), setError);
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  const handleRoleChange = async (userId, e) => {
    const newRole = e.target.value;
    setUserRoles((prevRoles) => ({
      ...prevRoles,
      [userId]: newRole,
    }));
    console.log("new role", newRole);
    try {
      const response = await api.put("/api/settings/users/role", {
        user_id: userId,
        role_name: newRole,
      });

      if (response.status === 200) {
        console.log("Role updated successfully");
      } else {
        console.error("Error updating role:", response.data.error);
      }
    } catch (error) {
      console.error("Request failed:", error.response?.data || error.message);
    }
  };

  useEffect(() => {
    const endpoint =
      userDetails.role_name === "Administrator"
        ? `/api/settings/users`
        : `/api/users/${userDetails.id}`;
    fetchData(endpoint, setUsers, setError, setLoading);
    const roleEndpoint = "/api/roles";
    fetchData(roleEndpoint, setRoles, setError, setLoading);
  }, [userRole, userDetails.id]);

  useEffect(() => {
    if (Array.isArray(users)) {
      const roles = users.reduce((acc, user) => {
        acc[user.id] = user.role_name; // Assuming role_name is available
        return acc;
      }, {});
      setUserRoles(roles);
    } else if (users.role_name) {
      setUserRoles({ [users.id]: users.role_name }); // Single user
    }
  }, [users]);

  const mapKeyToUserProperty = (heading) => {
    switch (heading) {
      case "User Name":
        return "name";
      case "Email ID":
        return "email";
      case "Role":
        return "role_name";
      case "User ID":
        return "employeecode";
      case "Active Status":
        return "status";
      case "Associated Groups":
        return "";
      case "Associated Projects":
        return "";
      default:
        return "";
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };
  const sortedUsers = [...users].sort((a, b) => {
    if (sortConfig.key === null) return 0;
    const key = sortConfig.key || "name";
    const direction = sortConfig.direction === "desc" ? -1 : 1;

    // If sorting by "employeecode", handle the numeric part of the string
    if (key === "employeecode") {
      const numA = parseInt(a[key].replace(/\D/g, ""), 10); // Extract numeric part from a[key]
      const numB = parseInt(b[key].replace(/\D/g, ""), 10); // Extract numeric part from b[key]
      return (numA - numB) * direction;
    }

    // Default string sorting for other columns
    if (a[key] < b[key]) return -1 * direction;
    if (a[key] > b[key]) return 1 * direction;
    return 0;
  });
  // const finalUsers = sortedUsers;
  const totalPages = Math.ceil(users.length / usersPerPage);

  // Get current projects to display
  const indexOfLastUsers = currentPage * usersPerPage;
  const indexOfFirstUsers = indexOfLastUsers - usersPerPage;
  const currentUsers = sortedUsers.slice(indexOfFirstUsers, indexOfLastUsers);

  // Handlers for pagination
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage((prev) => prev - 1);
  };
  const handleFirstPage = () => {
    if (currentPage > 1) setCurrentPage(1);
  };
  const handleLastPage = () => {
    if (currentPage < totalPages) setCurrentPage(totalPages);
  };
  if (loading) return <div>Loading...</div>;
  if (error) return;
  <div>Error loading data! {error}</div>;
  return (
    <div className="settings-container">
      {hasPermission("users", "create") && (
        <button
          onClick={() => setOpenDialog((prev) => !prev)}
          className="add-user-settings-btn add-btn"
          title="Add User"
        >
          Add User
        </button>
      )}
      {openDialog && <AddUser />}
      <table
        className="settings-content-table"
        style={{ width: "99%", top: "25px" }}
      >
        <thead style={{ position: "sticky", top: "-15px" }}>
          <tr>
            {[
              "User ID",
              "User Name",
              "Email ID",
              "Active Status",
              "Associated Groups",
              "Associated Projects",
              "Role",
            ].map((heading, index) => (
              <th
                key={index}
                onClick={
                  heading !== "S.No"
                    ? () => handleSort(mapKeyToUserProperty(heading))
                    : null
                }
                style={{
                  maxWidth: heading === "S.No" ? "10px" : "auto",
                  textAlign:
                    heading === "Associated Groups" &&
                    heading === "Associated Projects"
                      ? "center"
                      : "",
                }}
              >
                {heading}
                {heading !== "Associated Groups" &&
                  heading !== "Associated Projects" &&
                  sortConfig.key === mapKeyToUserProperty(heading) && (
                    <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                  )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.employeecode}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td
                style={{
                  backgroundColor:
                    user.status === "Active" ? "rgb(122, 206, 76)" : "#d2b48c",
                  color: "white",
                  width: "140px",
                }}
              >
                <select
                  className="status-dropdown"
                  value={user.status}
                  onChange={(e) => updateStatus(e.target.value, user.id)}
                  style={{
                    border: "none",
                    backgroundColor:
                      user.status === "Active"
                        ? "rgb(122, 206, 76)"
                        : "#d2b48c",
                    color: "white",
                    paddingLeft: "38px",
                    paddingRight: "28px",
                  }}
                  disabled={
                    !hasPermission("users", "update") ||
                    user.name === "Administrator"
                  }
                >
                  <option
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                    value="Inactive"
                  >
                    Inactive
                  </option>
                  <option
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                    value="Active"
                  >
                    Active
                  </option>
                </select>
              </td>
              <td style={{ width: "5px", textAlign: "center" }}>
                {user.groups.length}
              </td>
              <td style={{ width: "5px", textAlign: "center" }}>
                {user.projects.length}
              </td>
              {/* <td>{user.role_name}</td> */}
              <td>
                <select
                  value={userRoles[user.id] || user.role_name} // Use role for each user
                  onChange={(e) => handleRoleChange(user.id, e)}
                  disabled={
                    !hasPermission("users", "update") ||
                    user.name === "Administrator"
                  }
                  className="box-input"
                  style={{ marginBottom: "-8px" }}
                >
                  {roles.map((role) => (
                    <option key={role.id} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination-controls user-pagination">
        <h3>Total : {users.length}</h3>
        <button
          onClick={handleFirstPage}
          disabled={currentPage === 1}
          className="arrow-project-btn"
        >
          <MdKeyboardDoubleArrowLeft title="First Page" />
        </button>
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="arrow-project-btn"
        >
          {/* */}
          <MdKeyboardArrowLeft title="Previous Page" />
        </button>
        <span
          style={{
            marginTop: "16px",
            textWrap: "nowrap",
          }}
        >
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="arrow-project-btn"
        >
          <MdKeyboardArrowRight title="Next Page" />
        </button>
        <button
          onClick={handleLastPage}
          disabled={currentPage === totalPages}
          className="arrow-project-btn"
        >
          <MdKeyboardDoubleArrowRight title="Last Page" />
        </button>
      </div>
    </div>
  );
};
